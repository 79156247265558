import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import {
  ACTION_STATUSES,
  MIN_USERNAME_LENGTH,
  MIN_ZIP_CODE_LENGTH,
  SPREEDLY_CARD_ID,
  SPREEDLY_CARD_REF,
  SPREEDLY_CVV_ID,
  SPREEDLY_CVV_REF,
} from 'shared/consts';
import { useDispatch, useSelector } from 'react-redux';
import { getPaymentMethods, resetSavePaymentMethodStatus } from 'store/payment/actions';
import {
  initializeSpreedly,
  setCreditCardConfigSpreedly,
  setValidationSpreedly,
  tokenizeCreditCardSpreedly,
} from 'store/add-card/actions';
import { InputBorderStyles } from '../styles';
import { Country, SelectedItem, SpreedlyValidationInputProperties } from 'store/add-card/types';
import { formatExpirationDate } from 'utils/formatExpirationDate';
import { useCurrentUser } from 'hooks/use-current-user.hook';
import { selectBrandTheme } from 'store/theme/selectors';
import { selectSavePaymentMethodStatus } from 'store/payment/selectors';
import countries from 'assets/countries.json';
import { hqoTheme } from '@hqo/react-components-library';
import {
  selectSpreedlyCreditCardConfig,
  selectSpreedlyInitializeStatus,
  selectSpreedlyReady,
  selectSpreedlyReloadStatus,
  selectSpreedlyTokenizeCreditCardStatus,
  selectSpreedlyValidation,
} from 'store/add-card/selectors';
import { useIntl } from 'react-intl';
import { SpreedlySetState, UseAddCardProps, UseAddCardReturnValues } from '../types';

// eslint-disable-next-line max-lines-per-function
export const useAddCard = ({ spreedlyEnvKey, cartId }: UseAddCardProps): UseAddCardReturnValues => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [currentUser] = useCurrentUser();
  const brandTheme = useSelector(selectBrandTheme);
  const useSpreedlyState = (init: boolean) => useState<boolean>(init) as [boolean, SpreedlySetState];
  const savePaymentMethodStatus = useSelector(selectSavePaymentMethodStatus);

  const countryData: Array<Country> = countries;
  const defaultCountry: Country = {
    name: intl.formatMessage({ id: 'country_default' }),
    code: intl.formatMessage({ id: 'country_default_code' }),
  };

  const defaultFirstName = currentUser?.first_name ?? '';
  const defaultLastName = currentUser?.last_name ?? '';

  const spreedlyCardElementRef = useRef(null);
  const spreedlyCvvElementRef = useRef(null);

  const [firstName, setFirstName] = useState<string>(defaultFirstName);
  const [lastName, setLastName] = useState<string>(defaultLastName);
  const [month, setMonth] = useState<string>('');
  const [year, setYear] = useState<string>('');
  const [expDate, setExpDate] = useState<string>('');
  const [zipCode, setZipCode] = useState<string>('');
  const [country, setCountry] = useState<Country>({
    ...defaultCountry,
  });

  const [triggerErrorFirstName, setTriggerErrorFirstName] = useState<boolean>(false);
  const [triggerErrorLastName, setTriggerErrorLastName] = useState<boolean>(false);
  const [triggerErrorZipCode, setTriggerErrorZipCode] = useState<boolean>(false);
  const [triggerErrorExpDate, setTriggerErrorExpDate] = useState<boolean>(false);
  const [displayAddPaymentMethodErrorMessage, setDisplayAddPaymentMethodErrorMessage] = useState<boolean>(false);
  const [triggerSave, setTriggerSave] = useState<boolean>(false);
  const [dirtySpreedlyCardField, setDirtySpreedlyCardField] = useState<boolean>(false);
  const [dirtySpreedlyCvvField, setDirtySpreedlyCvvField] = useState<boolean>(false);
  const [isSpreedlyCardFocused, setIsSpreedlyCardFocused] = useSpreedlyState(false);
  const [isSpreedlyCvvFocused, setIsSpreedlyCvvFocused] = useSpreedlyState(false);
  const fonts = brandTheme?.font?.body?.font_family
    ? [`${brandTheme?.font?.body?.font_family}`, ...hqoTheme.fonts]
    : hqoTheme.fonts;
  const fontsString = fonts.join(',');
  const spreedlyFontColor = brandTheme?.primary_font_color ?? hqoTheme.colors.fontColor;
  const SpreedlyIFrameStyle = `width:100%;height:100%;font:14px/22px ${fontsString};color:${spreedlyFontColor};`;
  const spreedlyInitializeStatus = useSelector(selectSpreedlyInitializeStatus);
  const spreedlyReloadStatus = useSelector(selectSpreedlyReloadStatus);
  const spreedlyTokenizeCreditCardStatus = useSelector(selectSpreedlyTokenizeCreditCardStatus);
  const spreedlyCreditCardConfig = useSelector(selectSpreedlyCreditCardConfig);
  const spreedlyReady = useSelector(selectSpreedlyReady);
  const spreedlyValidation = useSelector(selectSpreedlyValidation);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    dispatch(
      initializeSpreedly.request({
        envKey: spreedlyEnvKey,
        numberEl: SPREEDLY_CARD_ID,
        cvvEl: SPREEDLY_CVV_ID,
      }),
    );
  }, []);

  useEffect(() => {
    setIsLoading(
      !spreedlyReady ||
        spreedlyInitializeStatus !== ACTION_STATUSES.FULFILLED ||
        spreedlyReloadStatus !== ACTION_STATUSES.FULFILLED ||
        spreedlyTokenizeCreditCardStatus === ACTION_STATUSES.PENDING ||
        savePaymentMethodStatus === ACTION_STATUSES.PENDING,
    );
  }, [spreedlyInitializeStatus, spreedlyReloadStatus, spreedlyTokenizeCreditCardStatus, savePaymentMethodStatus]);

  useEffect(() => {
    if (!isLoading && triggerSave && savePaymentMethodStatus === ACTION_STATUSES.REJECTED) {
      setDisplayAddPaymentMethodErrorMessage(true);
    }
  }, [isLoading, savePaymentMethodStatus, triggerSave]);

  const styleSpreedlyIFrameBorder = useCallback(
    (
      spreedlyElement: React.MutableRefObject<HTMLElement>,
      type: string,
      setIsFocused: SpreedlySetState,
      isValidInput: boolean,
    ) => {
      const spreedlyElementValid = spreedlyElement?.current?.style?.border != null;
      const currentElement = spreedlyElement;

      switch (type) {
        case 'input':
        case 'focus':
          if (spreedlyElementValid) {
            currentElement.current.style.border = isValidInput ? InputBorderStyles.focus : InputBorderStyles.error;
            setIsFocused(true);
          }
          break;
        case 'blur':
          if (spreedlyElementValid) {
            currentElement.current.style.border = isValidInput ? '' : InputBorderStyles.error;
            setIsFocused(false);
          }
          break;
        default:
          break;
      }
    },
    [spreedlyValidation.validCvv, spreedlyValidation.validNumber],
  );

  // eslint-disable-next-line max-lines-per-function
  useEffect(() => {
    if (
      (spreedlyReady && spreedlyInitializeStatus === ACTION_STATUSES.FULFILLED) ||
      spreedlyReloadStatus === ACTION_STATUSES.FULFILLED
    ) {
      window.Spreedly.setStyle(SPREEDLY_CARD_REF, SpreedlyIFrameStyle);
      window.Spreedly.setStyle(SPREEDLY_CVV_REF, SpreedlyIFrameStyle);
      window.Spreedly.setLabel(SPREEDLY_CARD_REF, intl.formatMessage({ id: 'card_number' }));
      window.Spreedly.setLabel(SPREEDLY_CVV_REF, intl.formatMessage({ id: 'cvv' }));
      window.Spreedly.setPlaceholder(SPREEDLY_CARD_REF, intl.formatMessage({ id: 'card_number_placeholder' }));
      window.Spreedly.setPlaceholder(SPREEDLY_CVV_REF, intl.formatMessage({ id: 'cvv_placeholder' }));

      window.Spreedly.on(
        'fieldEvent',
        // eslint-disable-next-line max-lines-per-function
        (name: string, eventType: string, _activeElement: string, inputData: SpreedlyValidationInputProperties) => {
          if (eventType === 'input') {
            dispatch(setValidationSpreedly(inputData));
          }
          switch (name) {
            case SPREEDLY_CARD_REF:
              if (!dirtySpreedlyCardField && eventType === 'input') {
                setDirtySpreedlyCardField(true);
              }
              styleSpreedlyIFrameBorder(
                spreedlyCardElementRef,
                eventType,
                setIsSpreedlyCardFocused,
                spreedlyValidation.validNumber,
              );
              break;
            case SPREEDLY_CVV_REF:
              if (!dirtySpreedlyCvvField && eventType === 'input') {
                setDirtySpreedlyCvvField(true);
              }
              styleSpreedlyIFrameBorder(
                spreedlyCvvElementRef,
                eventType,
                setIsSpreedlyCvvFocused,
                spreedlyValidation.validCvv,
              );
              break;
            default:
              break;
          }
        },
      );
    }
  }, [spreedlyReady, spreedlyInitializeStatus, spreedlyReloadStatus, spreedlyValidation]);

  useEffect(() => {
    if (!spreedlyValidation.validNumber && dirtySpreedlyCardField) {
      spreedlyCardElementRef.current.style.border = InputBorderStyles.error;
    } else if (spreedlyValidation.validNumber) {
      spreedlyCardElementRef.current.style.border = isSpreedlyCardFocused
        ? InputBorderStyles.focus
        : InputBorderStyles.default;
    }

    if (!spreedlyValidation.validCvv && dirtySpreedlyCvvField) {
      spreedlyCvvElementRef.current.style.border = InputBorderStyles.error;
    } else if (spreedlyValidation.validCvv) {
      spreedlyCvvElementRef.current.style.border = isSpreedlyCvvFocused
        ? InputBorderStyles.focus
        : InputBorderStyles.default;
    }
  }, [spreedlyValidation.validCvv, spreedlyValidation.validNumber, dirtySpreedlyCardField, dirtySpreedlyCvvField]);

  const setFormatExpDate = useCallback(
    (rawExpDate: string) => {
      const monthIndex = 0;
      const yearIndex = 1;
      const maxYearLength = 4;
      const formattedExpDate = formatExpirationDate(rawExpDate);
      const fields = formattedExpDate.split('/');
      const formattedMonth = fields[monthIndex];
      const formattedYear =
        fields[yearIndex] && fields[yearIndex].length < maxYearLength ? `20${fields[yearIndex]}` : fields[yearIndex];

      setExpDate(formattedExpDate);
      setMonth(formattedMonth);
      setYear(formattedYear);
    },
    [formatExpirationDate, setExpDate, setMonth, setYear],
  );

  const isUserNameValid = useCallback(
    (userName: string): boolean => {
      return userName.length >= MIN_USERNAME_LENGTH;
    },
    [MIN_USERNAME_LENGTH],
  );

  const isZipCodeValid = useCallback(
    (formattedZipCode: string): boolean => {
      return formattedZipCode.length >= MIN_ZIP_CODE_LENGTH;
    },
    [MIN_ZIP_CODE_LENGTH],
  );

  const handleOnChangeExpDate = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setTriggerErrorExpDate(false);
      setFormatExpDate(event.target.value);
    },
    [setTriggerErrorExpDate],
  );

  const handleOnChangeFirstName = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setTriggerErrorFirstName(false);
      setFirstName(event.target.value);
    },
    [setTriggerErrorFirstName, setFirstName],
  );

  const handleOnChangeLastName = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setTriggerErrorLastName(false);
      setLastName(event.target.value);
    },
    [setTriggerErrorLastName, setLastName],
  );

  const handleOnChangeZipCode = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setTriggerErrorZipCode(false);
      setZipCode(event.target.value);
    },
    [setTriggerErrorZipCode, setZipCode],
  );

  const handleOnClickSave = useCallback(() => {
    dispatch(resetSavePaymentMethodStatus());
    setDisplayAddPaymentMethodErrorMessage(false);
    dispatch(
      setCreditCardConfigSpreedly({
        first_name: firstName,
        last_name: lastName,
        month,
        year,
        country: country.name,
        zip: zipCode,
      }),
    );

    dispatch(tokenizeCreditCardSpreedly.request(spreedlyCreditCardConfig));
    setTriggerSave(true);
  }, [dispatch, spreedlyCreditCardConfig, firstName, lastName, month, year, country, zipCode]);

  useEffect(() => {
    if (savePaymentMethodStatus === ACTION_STATUSES.FULFILLED) {
      dispatch(getPaymentMethods.request(cartId));
    }
  }, [dispatch, cartId, savePaymentMethodStatus]);

  const handleSpreedlyCardOnMouseEnter = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const element = event.currentTarget;
      if (spreedlyValidation.validNumber) element.style.border = InputBorderStyles.hover;
    },
    [spreedlyValidation.validNumber],
  );

  const handleSpreedlyCvvOnMouseEnter = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const element = event.currentTarget;
      if (spreedlyValidation.validCvv) element.style.border = InputBorderStyles.hover;
    },
    [spreedlyValidation.validCvv],
  );

  const handleSpreedlyCardOnMouseLeave = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const element = event.currentTarget;
      if (spreedlyValidation.validNumber)
        element.style.border = isSpreedlyCardFocused ? InputBorderStyles.focus : InputBorderStyles.default;
    },
    [isSpreedlyCardFocused, spreedlyValidation.validNumber],
  );

  const handleSpreedlyCvvOnMouseLeave = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const element = event.currentTarget;
      if (spreedlyValidation.validCvv)
        element.style.border = isSpreedlyCvvFocused ? InputBorderStyles.focus : InputBorderStyles.default;
    },
    [isSpreedlyCvvFocused, spreedlyValidation.validCvv],
  );

  const handleKeyboardEnter = useCallback((event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      event.currentTarget.blur();
    }
  }, []);

  const handleFirstNameOnBlur = useCallback(() => {
    setTriggerErrorFirstName(true);
  }, [setTriggerErrorFirstName]);

  const handleLastNameOnBlur = useCallback(() => {
    setTriggerErrorLastName(true);
  }, [setTriggerErrorLastName]);

  const handleZipCodeOnBlur = useCallback(() => {
    setTriggerErrorZipCode(true);
  }, [setTriggerErrorZipCode]);

  const handleExpDateOnBlur = useCallback(() => {
    setTriggerErrorExpDate(true);
  }, [setTriggerErrorExpDate]);

  const handleOnChangeCountry = useCallback(
    (event: ChangeEvent<SelectedItem>) =>
      setCountry(
        countryData.find((countryEntry: Country) => countryEntry.code === event.target.value) || defaultCountry,
      ),
    [setCountry],
  );

  return {
    firstName,
    lastName,
    expDate,
    zipCode,
    country,
    triggerErrorFirstName,
    triggerErrorLastName,
    triggerErrorZipCode,
    triggerErrorExpDate,
    displayAddPaymentMethodErrorMessage,
    isUserNameValid,
    isZipCodeValid,
    handleOnChangeExpDate,
    handleOnChangeFirstName,
    handleOnChangeLastName,
    handleOnChangeZipCode,
    handleOnClickSave,
    handleSpreedlyCardOnMouseEnter,
    handleSpreedlyCvvOnMouseEnter,
    handleSpreedlyCardOnMouseLeave,
    handleSpreedlyCvvOnMouseLeave,
    handleKeyboardEnter,
    handleFirstNameOnBlur,
    handleLastNameOnBlur,
    handleZipCodeOnBlur,
    handleExpDateOnBlur,
    handleOnChangeCountry,
    defaultCountry,
    spreedlyCvvElementRef,
    spreedlyCardElementRef,
    isLoading,
    dirtySpreedlyCardField,
    dirtySpreedlyCvvField,
  };
};
