import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router-dom';
import { push } from 'connected-react-router';
import { isToday, isBetweenDatesTime } from 'utils';
import moment from 'moment-timezone';
import { useTimeZone } from 'hooks/use-timezone.hook';
import { useLocale } from 'hooks/use-locale.hook';
import {
  getAvailableServices,
  getServiceTypes,
  setDate,
  setSelectedAvailableService,
} from 'store/availableServices/actions';
import {
  selectAvailableServices,
  selectAvailableServicesListView,
  selectAvailableServicesStatus,
  selectServiceTypesStatus,
  serviceTypes,
} from 'store/availableServices/selectors';
import { CLASS_CUT_OFF_MONTH_NUMBER } from 'shared/consts';
import { ProviderParams } from 'shared/types';
import { selectCurrentServiceProvider } from 'store/serviceProviders/selectors';
import { useOwnerValues } from 'hooks/use-owner-values.hook';

export const useAvailableServicesSelector = () => {
  const availableServiceTypes = useSelector(serviceTypes);
  const availableServices = useSelector(selectAvailableServices);
  const servicesListView = useSelector(selectAvailableServicesListView);
  const availableServicesStatus = useSelector(selectAvailableServicesStatus);
  const serviceTypesStatus = useSelector(selectServiceTypesStatus);

  return {
    availableServiceTypes,
    availableServices,
    servicesListView,
    availableServicesStatus,
    serviceTypesStatus,
  };
};

export const useCommonHooksParams = () => {
  const dispatch = useDispatch();
  const { url } = useRouteMatch();
  const timeZone = useTimeZone();
  const { companyUuid } = useParams<ProviderParams>();
  const { ownerType, ownerUuid } = useOwnerValues();
  const currentServiceProvider = useSelector(selectCurrentServiceProvider);

  const defaultPayload = {
    ownerUuid,
    ownerType,
    companyUuid,
  };

  return { dispatch, url, timeZone, ownerUuid, ownerType, companyUuid, defaultPayload, currentServiceProvider };
};

const isSuitableDate = (selectedDate: Date, todayDateStartTime: Date, timeZone: string): boolean => {
  const classCutOffDate = moment.tz(timeZone).startOf('day').add(CLASS_CUT_OFF_MONTH_NUMBER, 'months').toDate();

  return isBetweenDatesTime(todayDateStartTime, classCutOffDate, selectedDate, timeZone);
};

export const useHandleRequestParams = () => {
  const { dispatch, timeZone, defaultPayload } = useCommonHooksParams();
  const locale = useLocale();
  const todayDateStartTime = moment.tz(timeZone).startOf('day').toDate();

  /**
   * If selectedDate is today, return the current time in the building timezone,
   * otherwise return the selected date with time 00:00
   * */

  const updatedDate = (selectedDate: Date) =>
    isToday(selectedDate, locale, timeZone)
      ? moment.tz(timeZone).utcOffset(0, true).startOf('hour').toDate()
      : moment(selectedDate).utcOffset(0).startOf('day');

  const fetchAvailableServices = (
    selectedDate: Date,
    setDisplayEmptyState: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    if (!isSuitableDate(selectedDate, todayDateStartTime, timeZone)) {
      setDisplayEmptyState(true);
    } else {
      setDisplayEmptyState(false);
      dispatch(setDate(selectedDate));
      dispatch(
        getAvailableServices.request({
          ...defaultPayload,
          availabilityStart: moment(updatedDate(selectedDate)).toISOString(),
          availabilityEnd: moment(updatedDate(selectedDate)).utcOffset(0).endOf('day').toISOString(), // availabilityEnd should be the selected date with time 23:59
        }),
      );
    }
  };

  return { todayDateStartTime, isSuitableDate, updatedDate, fetchAvailableServices };
};

export const useHandleRequest = () => {
  const { dispatch, defaultPayload } = useCommonHooksParams();
  const { todayDateStartTime, fetchAvailableServices } = useHandleRequestParams();
  const [selectedDate, setSelectedDate] = useState(todayDateStartTime);

  const handleServiceTypesRequest = () => {
    dispatch(getServiceTypes.request(defaultPayload));
  };

  const handleServicesRequest = (setDisplayEmptyState: React.Dispatch<React.SetStateAction<boolean>>) => {
    fetchAvailableServices(selectedDate, setDisplayEmptyState);
  };

  return { selectedDate, setSelectedDate, handleServiceTypesRequest, handleServicesRequest };
};

export const useSelectServiceType = () => {
  const { dispatch, ownerUuid, ownerType, companyUuid, defaultPayload } = useCommonHooksParams();
  const { availableServiceTypes } = useAvailableServicesSelector();

  return useCallback(
    (index: number) => {
      const path = `/providers/${companyUuid}/schedule/service-types/${availableServiceTypes?.serviceTypes[index].id}`;
      dispatch(push(path));
      dispatch(
        getAvailableServices.request({
          ...defaultPayload,
          serviceTypeId: availableServiceTypes?.serviceTypes[index].id.toString(),
        }),
      );
    },
    [availableServiceTypes, defaultPayload, ownerType, ownerUuid, companyUuid, dispatch],
  );
};

export const useSelectService = () => {
  const { dispatch, ownerUuid, ownerType, companyUuid } = useCommonHooksParams();
  const { availableServices } = useAvailableServicesSelector();
  return useCallback(
    (index: number) => {
      const path = `/providers/${companyUuid}/schedule/availability`;
      dispatch(push(path));
      dispatch(setSelectedAvailableService(availableServices?.services[index]));
    },
    [ownerType, ownerUuid, companyUuid, dispatch, availableServices?.services],
  );
};
