import { AppThemeProvider, defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';
import { HqoThemeProvider } from '@hqo/react-components-library/dist/hqo-theme-provider';
import { DeviceProvider } from '@hqo/react-components-library/dist/device';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';

import { App } from 'components/app';
import { BrowserRouter } from 'react-router-dom';
import { CHECKOUT_BREAK_POINT } from 'shared/consts';
import { ConnectedRouter } from 'connected-react-router';
import { IntlProvider } from 'components/intl-provider';
import LaunchDarklyInitializer from 'components/launchdarkly/LaunchDarklyInitializer';
import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { RootStyles } from 'components/root/root.styles';
import { history } from 'store/router/history';
import store from 'store';
import { MiniappSdkProvider } from 'components/miniapp-sdk-provider';

library.add(fab, fal, far, fas);

export const Root: React.FC = (): JSX.Element => (
  <ReduxProvider store={store}>
    <MiniappSdkProvider>
      <LaunchDarklyInitializer>
        <HqoThemeProvider>
          <DeviceProvider smallDeviceMaxWidth={CHECKOUT_BREAK_POINT}>
            <AppThemeProvider theme={defaultTheme}>
              <RootStyles />
              <BrowserRouter>
                <ConnectedRouter history={history}>
                  <IntlProvider>
                    <App />
                  </IntlProvider>
                </ConnectedRouter>
              </BrowserRouter>
            </AppThemeProvider>
          </DeviceProvider>
        </HqoThemeProvider>
      </LaunchDarklyInitializer>
    </MiniappSdkProvider>
  </ReduxProvider>
);
