import React, { useEffect, useState } from 'react';
import * as qrcode from 'qrcode';
import { QRContainer } from 'pages/gym-card/components/styles';

export interface QRCodeProps {
  clientId: string;
}

export const QRCode = ({ clientId }: QRCodeProps) => {
  const [qr, setQr] = useState<string>('');
  useEffect(() => {
    if (clientId) {
      qrcode.toString(clientId, { type: 'svg' }, (err, xurl) => {
        if (!err) {
          setQr(`data:image/svg+xml;utf8,${encodeURIComponent(xurl)}`);
        }
      });
    }
  });

  return <QRContainer>{qr && <img src={qr} alt="Gym Card QRCode" width="125%" data-testid="qr-code" />}</QRContainer>;
};
