import { DIMENSIONS, ModalWrapper } from '@hqo/react-components-library';
import { SMALL_DEVICE_MAX_WIDTH } from '@hqo/react-components-library/dist/device';
import styled from 'styled-components';

export const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 648px;
  overflow-y: hidden;

  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    width: auto;
    height: 100%;
    padding-top: 7px;
    align-items: flex-start;
  }
`;

export const StyledModalWrapper = styled(ModalWrapper)`
  width: 984px;

  @media only screen and (max-width: ${DIMENSIONS.LARGE}) {
    width: auto;
  }
`;
