export interface GetMemberships {
  ownerUuid: string;
  ownerType: string;
  companyUuid: string;
}

export interface Membership {
  key: number;
  id: string;
  user_membership_id?: string;
  name?: string;
  description?: string;
  agreement_terms?: string;
  requires_confirmation?: boolean;
  discount?: number;
  total?: number;
  subtotal?: number;
  tax?: number;
  status?: MembershipStatusEnum;
  payment_method_id?: string;
  card_id?: string;
}

export enum MembershipStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SUSPENDED = 'SUSPENDED',
}

export interface Memberships {
  data: { memberships: Array<Membership> };
  count: number;
  total: number;
  paging: Paging;
}

export interface Paging {
  previous: string;
  next: string;
}

export interface ServiceMembershipsState {
  memberships: Memberships;
  getMemberships: AsyncActionTypes;
  currentMembership: Membership;
}
