import styled from 'styled-components';
import { DEVICE_WIDTHS } from 'shared/consts';

export const Container = styled.div``;

export const UpcomingBookingsContainer = styled.div`
  background: ${({ theme }) => theme.colors.$white};
  margin-left: 60px;
  margin-top: 50px;

  @media only screen and (max-width: ${DEVICE_WIDTHS.SMALL}px) {
    margin-top: 0;
    margin-left: 0;
    margin-bottom: 70px;
  }
`;
