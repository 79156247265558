import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
`;

export const Title = styled.div`
  font: 400 14px/16px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => `${theme.colors.greys.sharedDarkGrey}`};
`;

export const DateTitle = styled.div`
  font: 500 14px/16px ${({ theme }) => theme.fonts.join()};
`;
