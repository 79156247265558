import React from 'react';
import { Container, DateTitle, Title } from './styles';
import { useIntl } from 'react-intl';
import { formatDateTitle } from 'utils';
import { useLocale } from 'hooks/use-locale.hook';
import { useTimeZone } from 'hooks/use-timezone.hook';

export const Form = () => {
  const { formatMessage } = useIntl();
  const todayDate = new Date();
  const locale = useLocale();
  const timeZone = useTimeZone();

  return (
    <Container>
      <Title>{formatMessage({ id: 'todays_date' })}</Title>
      <DateTitle>{formatDateTitle(todayDate, null, locale, timeZone)}</DateTitle>
    </Container>
  );
};
