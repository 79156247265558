import {
  DiscountAppliedBlockWrapper,
  AmountOffText,
  StyledError,
  StyledChip,
  DiscountAppliedContainer,
  StyledLabel,
} from './styles';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

interface DiscountAppliedBlockProps {
  promoCode: string;
  amountOffText: string;
  removePromoCode: (promoCode: string) => void;
  hasError: boolean;
  isCheckoutView: boolean;
}

export const DiscountAppliedBlock = ({
  promoCode,
  amountOffText,
  removePromoCode,
  hasError = false,
  isCheckoutView,
}: DiscountAppliedBlockProps): JSX.Element => {
  const intl = useIntl();

  const handleRemovePromoCode = useCallback(() => {
    removePromoCode(promoCode);
  }, [removePromoCode, promoCode]);

  const labelText = useMemo(() => {
    return isCheckoutView ? intl.formatMessage({ id: 'promo_code' }) : intl.formatMessage({ id: 'applied_promo' });
  }, [isCheckoutView]);

  return (
    <DiscountAppliedBlockWrapper>
      <StyledLabel>{labelText}</StyledLabel>
      <DiscountAppliedContainer>
        <StyledChip
          value={promoCode}
          label={promoCode}
          onCancel={handleRemovePromoCode}
          cancelEnabled={isCheckoutView}
        />
        <AmountOffText>{amountOffText}</AmountOffText>
      </DiscountAppliedContainer>
      {hasError && isCheckoutView && (
        <StyledError>{intl.formatMessage({ id: 'error_occurred_try_again' })}</StyledError>
      )}
    </DiscountAppliedBlockWrapper>
  );
};
