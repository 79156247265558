import * as cartActions from 'store/cart/actions';
import * as serviceRegistrationActions from 'store/serviceRegistration/actions';

import { concatMap, filter, map } from 'rxjs/operators';
import { showErrorNotification, showGenericErrorNotification } from './actions';

import { ERROR_PATH } from 'shared/consts';
import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootState } from 'store/reducer';
import { isActionOf } from 'typesafe-actions';
import { of } from 'rxjs';
import { replace } from 'connected-react-router';

export const handleFailedRequests: Epic<RootAction, RootAction, RootState> = action$ =>
  action$.pipe(
    // Add failure actions to trigger error modal here and in the test file
    filter(isActionOf([serviceRegistrationActions.registerUser.failure, cartActions.createCart.failure])),
    map(action =>
      showErrorNotification({
        action: action.type,
        error: action.payload.error,
        errorCode: action.payload.errorCode,
      }),
    ),
  );

export const handleGenericErrors: Epic<RootAction, RootAction, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf([showGenericErrorNotification])),
    concatMap(() => of(replace(ERROR_PATH))),
  );
