import {
  SavePaymentMethodRequest,
  SavePaymentMethodResponse,
  SavedPaymentMethod,
  UpdatePaymentMethodRequest,
  DeletePaymentMethodRequest,
} from './types';
import { createAction, createAsyncAction } from 'typesafe-actions';

import { FailureActionPayload } from 'store/errors/types';

export const getPaymentMethods = createAsyncAction(
  'GET_PAYMENT_METHODS_REQUEST',
  'GET_PAYMENT_METHODS_SUCCESS',
  'GET_PAYMENT_METHODS_FAILURE',
)<string, Array<SavedPaymentMethod>, FailureActionPayload>();

export const deletePaymentMethod = createAsyncAction(
  'DELETE_PAYMENT_METHOD_REQUEST',
  'DELETE_PAYMENT_METHOD_SUCCESS',
  'DELETE_PAYMENT_METHOD_FAILURE',
)<DeletePaymentMethodRequest, [undefined, undefined], FailureActionPayload>();

export const savePaymentMethod = createAsyncAction(
  'SAVE_PAYMENT_METHOD_REQUEST',
  'SAVE_PAYMENT_METHOD_SUCCESS',
  'SAVE_PAYMENT_METHOD_FAILURE',
)<SavePaymentMethodRequest, SavePaymentMethodResponse, FailureActionPayload>();

export const resetSavePaymentMethodStatus = createAction('RESET_SAVE_PAYMENT_METHOD_STATUS')<undefined>();

export const setCurrentPaymentMethodId = createAction('SET_PAYMENT_METHOD')<string>();

export const updatePaymentMethod = createAsyncAction(
  'UPDATE_PAYMENT_METHOD_REQUEST',
  'UPDATE_PAYMENT_METHOD_SUCCESS',
  'UPDATE_PAYMENT_METHOD_FAILURE',
)<UpdatePaymentMethodRequest, [undefined, undefined], FailureActionPayload>();

export const localUpdatePaymentMethod = createAction('LOCAL_UPDATE_PAYMENT_METHOD')<number>();
