import { useSelector } from 'react-redux';
import { selectCurrentAvailableService } from 'store/availableServices/selectors';
import { useIntl } from 'react-intl';
import { useLocale } from 'hooks/use-locale.hook';
import { selectCart } from 'store/cart/selectors';
import { selectBuildingTimeZone } from 'store/building/selectors';
import { selectTransaction, selectTransactionDisplay } from 'store/transactions/selectors';
import { BookingStatus, ORDER_TYPES, TRANSACTION_TYPES, TransactionDetailsServiceTypes } from 'shared/consts';
import React, { useMemo } from 'react';
import { BoldText } from 'hocs/bold-text';
import { getFormattedEventTimeRange } from 'utils/formatDate';
import { useBookingDetails } from './use-booking-details.hook';

interface UseTransactionOverviewReturnValues {
  title: string;
  subtitle: string | JSX.Element | Array<string | JSX.Element>;
}

export const useTransactionOverview = (): UseTransactionOverviewReturnValues => {
  const intl = useIntl();
  const locale = useLocale();
  const service = useSelector(selectCurrentAvailableService);
  const cart = useSelector(selectCart);
  const buildingTimeZone = useSelector(selectBuildingTimeZone);
  const transaction = useSelector(selectTransaction);
  const transactionDisplay = useSelector(selectTransactionDisplay);
  const { eventWeekDayLong, eventDateMonthLong, eventDateDay } = useBookingDetails();
  const serviceCtaLabel = service?.cta_label?.toLowerCase();

  const isTransactionCancelled = transaction?.details?.status === BookingStatus.CANCELLED;
  const isEventType =
    transaction?.details?.service?.type === TransactionDetailsServiceTypes.EVENT ||
    cart?.type === ORDER_TYPES.SERVICE_BOOKING_EVENT;

  const commonIntlValuesFromTransaction = useMemo(
    () => ({
      serviceName: <BoldText>{transactionDisplay.items[0].display_info.title}</BoldText>,
      serviceTime: <BoldText>{`${eventWeekDayLong}, ${eventDateMonthLong} ${eventDateDay}`}</BoldText>,
    }),
    [transactionDisplay, eventWeekDayLong, eventDateMonthLong, eventDateDay],
  );

  const eventSubtitleValues = useMemo(
    () => ({
      serviceName: <BoldText>{transaction?.details?.service?.name}</BoldText>,
      serviceDate: <BoldText>{`${eventWeekDayLong}, ${eventDateMonthLong} ${eventDateDay}`}</BoldText>,
      serviceTime: (
        <BoldText>
          {getFormattedEventTimeRange(
            transaction.details.start_at,
            transaction.details.end_at,
            locale,
            buildingTimeZone,
          )}
        </BoldText>
      ),
    }),
    [transaction, eventWeekDayLong, eventDateMonthLong, eventDateDay, buildingTimeZone, locale],
  );

  const getFormattedSubtitle = (id: string, values: Record<string, JSX.Element | string>) => {
    return intl.formatMessage({ id }, values);
  };

  const commonSubtitle = getFormattedSubtitle('timekit_confirmation_text', commonIntlValuesFromTransaction);

  const titleAndSubtitleForCancelled = useMemo(() => {
    if (isEventType && serviceCtaLabel) {
      return {
        title: `${serviceCtaLabel}_cancelled`,
        subtitle: getFormattedSubtitle(`event_${serviceCtaLabel}_cancelled_text`, eventSubtitleValues),
      };
    }

    return {
      title: 'booking_cancelled',
      subtitle: getFormattedSubtitle('timekit_cancelled_text', commonIntlValuesFromTransaction),
    };
  }, [isEventType, serviceCtaLabel, eventSubtitleValues]);

  const getTitleAndSubtitleForBooking = () => {
    switch (transaction.details.service.type) {
      case TransactionDetailsServiceTypes.APPOINTMENT:
        return { title: 'appointment_booked', subtitle: commonSubtitle };
      case TransactionDetailsServiceTypes.CLASS:
        return { title: 'class_booked', subtitle: commonSubtitle };
      case TransactionDetailsServiceTypes.EVENT:
        return {
          title: serviceCtaLabel ? `${serviceCtaLabel}_confirmed` : 'rsvp_confirmed',
          subtitle: getFormattedSubtitle(
            serviceCtaLabel ? `event_${serviceCtaLabel}_confirmation_text` : 'event_book_confirmation_text',
            eventSubtitleValues,
          ),
        };
      default:
        return { title: 'default_receipt', subtitle: null };
    }
  };

  const titleAndSubtitleForMembership = useMemo(
    () => ({
      title: 'membership_activated',
      subtitle: getFormattedSubtitle('you_are_member', { membership: transactionDisplay.vendor.name }),
    }),
    [transactionDisplay],
  );

  return useMemo(() => {
    if (isTransactionCancelled) {
      return titleAndSubtitleForCancelled;
    }

    switch (transaction.type) {
      case TRANSACTION_TYPES.SERVICES_BOOKING:
        return getTitleAndSubtitleForBooking();
      case TRANSACTION_TYPES.MEMBERSHIP:
        return titleAndSubtitleForMembership;
      default:
        return { title: 'default_receipt', subtitle: null };
    }
  }, [isTransactionCancelled, transaction, commonIntlValuesFromTransaction]);
};
