import {
  ACTION_STATUSES,
  CLASS_CUT_OFF_MONTH_NUMBER,
  SCHEDULE_TYPES,
  SCHEDULE_TYPES_PARAMS,
  TRACK_EVENTS,
  TRACK_EVENT_TYPES,
} from 'shared/consts';
import { AvailableServiceView, SERVICE_TYPE } from 'store/availableServices/types';
import { AppointmentsContentWrapper, Content, ScheduleListWrapper, ToggleWrapper } from './styles';
import { ItemType, OrderType } from 'store/cart/types';
import { ProviderParams } from 'shared/types';
import React, { useCallback, useEffect, useState } from 'react';
import {
  useAvailableServicesSelector,
  useHandleRequest,
  useSelectService,
  useSelectServiceType,
} from './schedule-content.hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router-dom';

import { AppointmentsContent } from '../appointments-content/appointments-content';
import { AvailableServicesDatePicker } from 'components/available-booking-date-picker';
import { ScheduleList } from 'components/schedule-list';
import { createCart } from 'store/cart/actions';
import moment from 'moment-timezone';
import { push, replace } from 'connected-react-router';
import { selectCurrentServiceProvider } from 'store/serviceProviders/selectors';
import { useIntl } from 'react-intl';
import { useTimeZone } from 'hooks/use-timezone.hook';
import { track } from '@hqo/web-tracking';
import { useBackButtonVisibility } from 'hooks/use-back-button-visibility.hook';
import { configSelector } from 'store/config/selectors';
import { ToggleBlock } from '@hqo/react-components-library/dist/molecules/toggle-block';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Loader } from 'components/loader/loader';

export interface ScheduleContentProps {
  isSupportsServiceTypes?: boolean;
}

export const ScheduleContent = ({ isSupportsServiceTypes }: ScheduleContentProps) => {
  const timeZone = useTimeZone();
  const { url } = useRouteMatch();
  const classCutOffDate = moment.tz(timeZone).startOf('day').add(CLASS_CUT_OFF_MONTH_NUMBER, 'months').toDate();
  const { buildingUuid } = useSelector(configSelector);
  const { scheduleType } = useParams<ProviderParams>();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { serviceBookingLocalTimeUpdate: inLocalTimeLD } = useFlags();

  const { servicesListView, availableServicesStatus, serviceTypesStatus } = useAvailableServicesSelector();
  const providerDetails = useSelector(selectCurrentServiceProvider);
  const [displayEmptyState, setDisplayEmptyState] = useState(false);
  const isBackButtonVisible = useBackButtonVisibility();
  const [isClassToggleActive, setIsClassToggleActive] = useState<boolean>(
    scheduleType === SCHEDULE_TYPES_PARAMS.CLASSES,
  );
  const updatedUrl = url.split(`/${scheduleType}`)[0];

  const { selectedDate, setSelectedDate, handleServicesRequest, handleServiceTypesRequest } = useHandleRequest();

  useEffect(() => {
    if (isClassToggleActive) {
      track(
        TRACK_EVENTS.SCHEDULE_IMPRESSION,
        {
          type: TRACK_EVENT_TYPES.IMPRESSION,
          schedule_type: SCHEDULE_TYPES.CLASS,
        },
        { sendToHqoTracking: true },
      );
    }
  }, [isClassToggleActive]);

  useEffect(() => {
    if (isSupportsServiceTypes && !isClassToggleActive) {
      handleServiceTypesRequest();
    } else {
      handleServicesRequest(setDisplayEmptyState);
    }
  }, [selectedDate, dispatch, isClassToggleActive, isSupportsServiceTypes, timeZone]);

  const selectServiceType = useSelectServiceType();
  const selectService = useSelectService();

  const handleClassClick = useCallback(
    (service: AvailableServiceView) => {
      dispatch(
        createCart.request({
          type: OrderType.SERVICE_BOOKING_CLASS,
          owner_type: providerDetails?.owner_type,
          owner_id: providerDetails?.owner_uuid,
          building_uuid: buildingUuid,
          app_instance_config_uuid: providerDetails?.app_instance_config_uuid,
          items: [
            {
              id: service?.id.toString(),
              type: ItemType.SERVICE_BOOKING_CLASS,
              quantity: 1,
              price: service?.price,
              class_id: service?.class_id,
              display_info: {
                title: service?.adminName,
                description1: service?.resourceName,
                description2: inLocalTimeLD
                  ? moment(service?.startTime).tz(timeZone, true).toISOString()
                  : service?.startTime,
              },
              service_booking: {
                resource_uuid: service?.resourceUuid,
                start_at: inLocalTimeLD
                  ? moment(service?.startTime).tz(timeZone, true).toISOString()
                  : service?.startTime,
                end_at: inLocalTimeLD ? moment(service?.endTime).tz(timeZone, true).toISOString() : service?.endTime,
                waitlisted: !!(service?.seatAvailable <= 0 && service?.waitlistAvailable),
              },
            },
          ],
          booking_type: SERVICE_TYPE.GROUP,
        }),
      );
    },
    [dispatch, providerDetails, buildingUuid],
  );

  const pushToServiceClass = useCallback(
    (service: AvailableServiceView) => {
      dispatch(push(url.replace(/schedule.*$/, `class-details/${service.id}`)));
    },
    [dispatch, url],
  );

  const isLoading = () => {
    if (!isClassToggleActive) {
      return isSupportsServiceTypes
        ? serviceTypesStatus === ACTION_STATUSES.PENDING
        : availableServicesStatus === ACTION_STATUSES.PENDING;
    }
    return availableServicesStatus === ACTION_STATUSES.PENDING;
  };

  const handleOnClassClick = useCallback(() => {
    dispatch(replace(`${updatedUrl}/${SCHEDULE_TYPES_PARAMS.CLASSES}`));
    setIsClassToggleActive(true);
  }, [dispatch, updatedUrl]);

  const handleOnAppointmentClick = useCallback(() => {
    dispatch(replace(`${updatedUrl}/${SCHEDULE_TYPES_PARAMS.APPOINTMENTS}`));
    setIsClassToggleActive(false);
  }, [dispatch, updatedUrl]);

  return (
    <Content data-testid="schedule-content">
      <ToggleWrapper>
        <ToggleBlock
          leftLabel={intl.formatMessage({ id: 'classes' })}
          rightLabel={intl.formatMessage({ id: 'appointments' })}
          isLeftEnabled={providerDetails?.config.group}
          isRightEnabled={providerDetails?.config.appointment}
          onLeftLabelClick={handleOnClassClick}
          onRightLabelClick={handleOnAppointmentClick}
          isLeftActive={isClassToggleActive}
        />
      </ToggleWrapper>
      {isClassToggleActive && providerDetails.config.group && (
        <AvailableServicesDatePicker
          cutOffFutureDate={classCutOffDate}
          handleDateChange={setSelectedDate}
          selectedDate={selectedDate}
        />
      )}
      {isLoading() && <Loader hasNotificationText={isClassToggleActive} />}
      {!isLoading() && !isClassToggleActive && (
        <AppointmentsContentWrapper isBackButtonVisible={isBackButtonVisible}>
          <AppointmentsContent
            isServices={!isSupportsServiceTypes}
            isSupportsServiceTypes={isSupportsServiceTypes}
            selectItem={isSupportsServiceTypes ? selectServiceType : selectService}
          />
        </AppointmentsContentWrapper>
      )}
      {(displayEmptyState || availableServicesStatus === ACTION_STATUSES.FULFILLED) &&
        isClassToggleActive &&
        providerDetails.config.group && (
          <ScheduleListWrapper isBackButtonVisible={isBackButtonVisible}>
            <ScheduleList
              availableServices={servicesListView as AvailableServiceView[]}
              onActionButtonClick={handleClassClick}
              onClick={pushToServiceClass}
              isEmpty={displayEmptyState || !servicesListView?.length}
            />
          </ScheduleListWrapper>
        )}
    </Content>
  );
};
