import { EmptyWrapper, StyledEmptyState } from './styles';

import React from 'react';
import { useIntl } from 'react-intl';

export const EmptyProvidersPlaceholder = () => {
  const intl = useIntl();

  return (
    <EmptyWrapper data-testid="empty-providers">
      <StyledEmptyState
        icon="empty-set"
        title={intl.formatMessage({ id: 'no_service_providers' })}
        descriptions={[intl.formatMessage({ id: 'no_service_providers_building' })]}
      />
    </EmptyWrapper>
  );
};
