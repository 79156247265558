import { OrderTotal } from 'store/cart/types';

export const formatSummaryValues = (summary: OrderTotal) => {
  const currencyType = summary?.currency_type;
  const subtotal = summary?.subtotal && summary.subtotal / 100;
  const discountApplied = summary?.discount_applied && summary.discount_applied / 100;
  const total = summary?.total && summary.total / 100;
  const taxSummary = summary?.tax_summary.taxes_total && summary.tax_summary.taxes_total / 100;

  return { currencyType, subtotal, discountApplied, total, taxSummary };
};
