import styled from 'styled-components';
import { TwoColumnModal } from '@hqo/react-components-library/dist/molecules/modals/twoColumnModal';
import { PAYMENT_CONTENT_WIDTH } from 'shared/consts';
import { ModalWrapper } from '@hqo/react-components-library';

export const ReceiptContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const StyledTwoColumnModal = styled(TwoColumnModal)`
  @media only screen and (max-width: ${PAYMENT_CONTENT_WIDTH}px) {
    .right-pane {
      padding: 0 16px;
    }
  }
`;

export const StyledModalWrapper = styled(ModalWrapper)`
  .modal-background {
    backdrop-filter: none;
    background-color: ${({ theme }) => `${theme.colorsRgba.black_5}`};
  }

  .modal-wrapper {
    width: 983px;
    height: 586px;
  }

  .modal-wrapper-content {
    padding: 56px 24px 24px 24px;
    height: initial;
  }

  .back-button-container {
    padding: 17px 0 0 17px;
  }
`;

export const DoublePane = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 20px;
  flex-grow: 1;
`;

export const RightPane = styled.div<{ hasFooter: boolean }>`
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 8px;
    background: ${({ theme }) => theme.colors.$transparent};
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.$sharedGreyLight};
    border-radius: 4px;
  }

  @media only screen and (max-width: ${PAYMENT_CONTENT_WIDTH}px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    ${({ hasFooter }) => hasFooter && 'height: calc(100% - 100px);'}
  }
`;

export const LeftPane = styled.div``;
