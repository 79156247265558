import { MembershipSelectionPage } from 'pages/membership-selection';
import React from 'react';
import { OrderType } from 'store/cart/types';
import { ServiceProviderV3 } from 'store/serviceProviders/types';
import { AgreementPage } from 'pages/agreement-page/agreement-page';
import { RegisterUserPage } from 'pages/register-user';
import { PAGES } from 'shared/consts/consts';

export interface RegisterContractAgreementContentProps {
  currentServiceProvider: ServiceProviderV3;
  setCurrentOrderType: React.Dispatch<React.SetStateAction<OrderType>>;
  currentPage: string;
  setIsModalWithoutSlideAnimation?: React.Dispatch<React.SetStateAction<boolean>>;
}
export const RegisterContractAgreementContent = ({
  currentServiceProvider,
  setCurrentOrderType,
  currentPage,
  setIsModalWithoutSlideAnimation,
}: RegisterContractAgreementContentProps): JSX.Element => {
  return (
    <>
      {currentPage === PAGES.REGISTRATION && <RegisterUserPage />}
      {currentPage === PAGES.CONTRACT_LIST && (
        <MembershipSelectionPage
          currentServiceProvider={currentServiceProvider}
          setIsModalWithoutSlideAnimation={setIsModalWithoutSlideAnimation}
        />
      )}
      {currentPage === PAGES.AGREEMENT && <AgreementPage setCurrentOrderType={setCurrentOrderType} />}
    </>
  );
};
