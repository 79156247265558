import { ModalContainer } from './styles';
import { Spinner } from '@hqo/react-components-library/dist/atoms/spinner';
import { defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';

import { ACTION_STATUSES } from 'shared/consts/consts';
import { Container } from 'hocs/shared-styles';
import { DoublePaneWrapper } from 'components/double-pane';
import React from 'react';

interface RegisterUserProps {
  contentStatus: 'PENDING' | 'FULFILLED' | 'REJECTED';
  Button: React.ReactNode;
  LeftPaneContent: React.ReactNode;
  RightPaneContent: React.ReactNode;
}

export const DoublePaneModal = ({ contentStatus, Button, LeftPaneContent, RightPaneContent }: RegisterUserProps) => {
  return (
    <ModalContainer>
      {contentStatus === ACTION_STATUSES.PENDING ? (
        <Container isLoading>
          <Spinner color={defaultTheme.colors.$greyLight} size="10em" />
        </Container>
      ) : (
        contentStatus === ACTION_STATUSES.FULFILLED && (
          <DoublePaneWrapper
            LeftPaneContent={LeftPaneContent}
            isButtonVisible
            Button={Button}
            RightPaneContent={RightPaneContent}
          />
        )
      )}
    </ModalContainer>
  );
};
