import React from 'react';
import { Cart } from 'components/cart';
import { useLocale } from 'hooks/use-locale.hook';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ORDER_TYPES, SupportedPaymentMethods, TRANSACTION_TYPES, TransactionDetailsServiceTypes } from 'shared/consts';
import { selectBuildingLocale, selectBuildingTimeZone } from 'store/building/selectors';
import { selectCart } from 'store/cart/selectors';
import { selectPaymentMethods } from 'store/payment/selectors';
import { selectTransaction, selectTransactionDisplay } from 'store/transactions/selectors';
import { formatCurrency } from 'utils/formatCurrency';
import { formatSavedPaymentMethodLabel } from 'utils/formatSavedPaymentMethodLabel';
import { formatSavedPaymentMethodLabelFromTransaction } from 'utils/formatSavedPaymentMethodLabelFromTransaction';
import { getReceiptInfo } from 'utils/getReceiptInfo';
import { getReceiptInfoFromTransaction } from 'utils/getReceiptInfoFromTransaction';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';
import { ReceiptPaymentMethod } from './receipt-payment-method';
import {
  EventPaymentDetails,
  PaymentMethodContainer,
  StyledItemQuantityBlock,
  StyledSummarySubtitle,
  TotalPriceBlock,
} from './styles';
import { formatItemPrice } from 'utils/formatItemPrice';
import { formatDate } from 'utils/formatDate';

const DEFAULT_POINTS = 0;
const POINTS_CONVERSION_FACTOR = 100;

export const PaymentMethodSection = () => {
  const intl = useIntl();
  const isMobileDevice = useIsSmallViewportWidth();
  const cart = useSelector(selectCart);
  const paymentMethods = useSelector(selectPaymentMethods);
  const paymentMethod = paymentMethods?.find(method => method.default);
  const locale = useLocale();
  const buildingLocale = useSelector(selectBuildingLocale) || 'en-US';
  const buildingTimeZone = useSelector(selectBuildingTimeZone);
  const transaction = useSelector(selectTransaction);
  const { transactionUuid } = useSearchParams();
  const transactionDisplay = useSelector(selectTransactionDisplay);

  const paymentMethodLabel = transactionUuid
    ? formatSavedPaymentMethodLabelFromTransaction(transaction?.payment_method)
    : formatSavedPaymentMethodLabel(paymentMethod);

  const { capitalizedBrand, formattedExpMonth, formattedExpYear } =
    transaction?.payment_method?.type?.toUpperCase() === SupportedPaymentMethods.CREDIT_CARD && paymentMethodLabel;

  const isServiceBookingMemberType = transactionUuid
    ? transaction?.type === TRANSACTION_TYPES.MEMBERSHIP
    : cart?.type === ORDER_TYPES.SERVICE_BOOKING_MEMBERSHIP;

  const shouldShowSummarySubtitle = isServiceBookingMemberType && !isMobileDevice;

  const subtitle = transactionUuid
    ? getReceiptInfoFromTransaction(transaction, locale as string, buildingTimeZone).paymentBlockTitle
    : getReceiptInfo(cart, locale as string, buildingTimeZone).title;

  const creditsAmount = formatCurrency(
    (transaction?.payment_method?.remaining_points || DEFAULT_POINTS) / POINTS_CONVERSION_FACTOR,
    transaction?.currency_type,
    buildingLocale,
  );

  const bookingStart = transactionUuid ? transaction?.details?.start_at : cart?.items[0]?.service_booking?.start_at;

  const { eventDateMonth, eventDateDay, eventDateYear } = formatDate(bookingStart, locale as string, buildingTimeZone);

  return (
    <>
      {shouldShowSummarySubtitle && (
        <StyledSummarySubtitle>
          {intl.formatMessage({
            id: subtitle,
          })}
        </StyledSummarySubtitle>
      )}
      {transactionDisplay.details.service?.type !== TransactionDetailsServiceTypes.EVENT && (
        <Cart cart={cart} isCheckoutView={false} />
      )}
      {transactionDisplay.details.service?.type === TransactionDetailsServiceTypes.EVENT && (
        <>
          <EventPaymentDetails>
            {intl.formatMessage({
              id: 'payment_details',
            })}
          </EventPaymentDetails>
          {transactionDisplay.items?.map(item => (
            <>
              <StyledItemQuantityBlock
                key={item.id}
                title={item.display_info.title}
                subtitle={`${eventDateMonth} ${eventDateDay}, ${eventDateYear} • ${getReceiptInfoFromTransaction(transaction, locale as string, buildingTimeZone).eventBlockSubtitle}`}
                price={formatItemPrice(item.price, { currencyType: transaction?.currency_type, buildingLocale }, intl)}
              />
              <TotalPriceBlock
                title={intl.formatMessage({ id: 'total' })}
                price={formatItemPrice(item.price, { currencyType: transaction?.currency_type, buildingLocale }, intl)}
              />
            </>
          ))}
        </>
      )}
      <PaymentMethodContainer>
        <ReceiptPaymentMethod
          title={intl.formatMessage(
            {
              id: 'saved_payment_method_option',
            },
            {
              cardBrand: capitalizedBrand,
              cardLastFour: transaction?.payment_method?.last_four,
              cardExpMonth: formattedExpMonth,
              cardExpYear: formattedExpYear,
            },
          )}
          paymentMethod={transaction?.payment_method.type?.toUpperCase()}
          creditsAmount={creditsAmount}
          total={transactionUuid ? transactionDisplay?.total_summary?.total : cart?.total_summary?.total}
        />
      </PaymentMethodContainer>
    </>
  );
};
