import { OwnerType } from 'shared/consts';
import * as actions from './actions';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { isActionOf } from 'typesafe-actions';
import { selectFeatureFlags } from 'store/featureFlags/selectors';
import { selectCurrentServiceProvider } from 'store/serviceProviders/selectors';

// eslint-disable-next-line max-lines-per-function
export const getAvailableServicesEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getAvailableServices.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]): Observable<RootAction> => {
      const { ownerType, ownerUuid, companyUuid, availabilityStart, availabilityEnd, serviceTypeId } = payload;
      const { serviceBookingUsingV3Endpoints } = selectFeatureFlags(state);
      const { owner_uuid: buildingCompanyOwnerUuid } = selectCurrentServiceProvider(state);
      let ajaxResponse;
      if (serviceBookingUsingV3Endpoints) {
        ajaxResponse = apiClient(state).getAvailableServicesV3(
          ownerType,
          ownerUuid,
          companyUuid,
          availabilityStart,
          availabilityEnd,
          serviceTypeId,
        );
      } else {
        ajaxResponse = apiClient(state).getAvailableServices(
          OwnerType.BUILDING_COMPANY,
          buildingCompanyOwnerUuid,
          availabilityStart,
          availabilityEnd,
          serviceTypeId,
        );
      }
      return ajaxResponse.pipe(
        map(xhrPayload => {
          return actions.getAvailableServices.success(xhrPayload.response.data);
        }),
        catchError(error => of(actions.getAvailableServices.failure({ error }))),
      );
    }),
  );

// eslint-disable-next-line max-lines-per-function
export const getCurrentAvailableServiceEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getCurrentAvailableService.request)),
    withLatestFrom(state$),
    // eslint-disable-next-line max-lines-per-function
    switchMap(([{ payload }, state]): Observable<RootAction> => {
      const { serviceBookingUsingV3Endpoints } = selectFeatureFlags(state);
      const { ownerType, ownerUuid, companyUuid, serviceUuid, availabilityStart, availabilityEnd, serviceTypeId } =
        payload;
      const { owner_uuid: buildingCompanyOwnerUuid } = selectCurrentServiceProvider(state) || {};
      let ajaxResponse;
      if (serviceBookingUsingV3Endpoints) {
        ajaxResponse = apiClient(state).getCurrentAvailableServiceV3(
          ownerType,
          ownerUuid,
          companyUuid,
          serviceUuid,
          availabilityStart,
          availabilityEnd,
          serviceTypeId,
        );
      } else {
        ajaxResponse = apiClient(state).getCurrentAvailableService(
          OwnerType.BUILDING_COMPANY,
          buildingCompanyOwnerUuid,
          serviceUuid,
          availabilityStart,
          availabilityEnd,
          serviceTypeId,
        );
      }
      return ajaxResponse.pipe(
        map(xhrPayload => {
          return actions.getCurrentAvailableService.success(xhrPayload.response.data.service);
        }),
        catchError(error => of(actions.getCurrentAvailableService.failure({ error }))),
      );
    }),
  );

export const getServiceTypesEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getServiceTypes.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]): Observable<RootAction> => {
      const { serviceBookingUsingV3Endpoints } = selectFeatureFlags(state);
      const { ownerType, ownerUuid, companyUuid, serviceType } = payload;
      const { owner_uuid: buildingCompanyOwnerUuid } = selectCurrentServiceProvider(state);
      let ajaxResponse;
      if (serviceBookingUsingV3Endpoints) {
        ajaxResponse = apiClient(state).getServiceTypesV3(ownerType, ownerUuid, companyUuid);
      } else {
        ajaxResponse = apiClient(state).getServiceTypes(
          OwnerType.BUILDING_COMPANY,
          buildingCompanyOwnerUuid,
          serviceType,
        );
      }
      return ajaxResponse.pipe(
        map(xhrPayload => {
          return actions.getServiceTypes.success(xhrPayload.response.data);
        }),
        catchError(error => of(actions.getServiceTypes.failure({ error }))),
      );
    }),
  );
