import * as actions from './actions';

import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { isActionOf } from 'typesafe-actions';
import { of } from 'rxjs';
import { selectFeatureFlags } from 'store/featureFlags/selectors';
import { selectCurrentServiceProvider } from 'store/serviceProviders/selectors';
import { OwnerType } from 'shared/consts';

export const getMembershipsEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getMemberships.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      const { ownerType, ownerUuid, companyUuid } = payload;
      const { serviceBookingUsingV3Endpoints } = selectFeatureFlags(state);
      const { owner_uuid: buildingCompanyOwnerUuid } = selectCurrentServiceProvider(state);
      let ajaxResponse;
      if (serviceBookingUsingV3Endpoints) {
        ajaxResponse = apiClient(state).getMembershipsV3(ownerType, ownerUuid, companyUuid);
      } else {
        ajaxResponse = apiClient(state).getMemberships(OwnerType.BUILDING_COMPANY, buildingCompanyOwnerUuid);
      }
      return ajaxResponse.pipe(
        map(data => {
          return actions.getMemberships.success(data.response);
        }),
        catchError((error: Error) => of(actions.getMemberships.failure({ error }))),
      );
    }),
  );
