import { LAUNCH_DARKLY_ANONYMOUS_EMAIL } from 'shared/consts';
import React, { useState, useEffect, useCallback } from 'react';
import { currentUser } from 'store/user/selectors';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';
import { User } from 'store/user/types';
import { LDUser } from 'launchdarkly-js-client-sdk';

interface Props {
  children: React.ReactElement;
}

const initialLDUser: LDUser = {
  key: LAUNCH_DARKLY_ANONYMOUS_EMAIL,
  anonymous: true,
};

const getLaunchDarklyUser = (user: User): LDUser => {
  const launchDarklyUser: LDUser = initialLDUser;

  if (user?.id) {
    launchDarklyUser.anonymous = false;
    launchDarklyUser.key = user.email;
    launchDarklyUser.email = user.email;
  }

  return launchDarklyUser;
};

const LaunchDarklyInitializer = ({ children }: Props): JSX.Element => {
  const [launchDarkly, setLaunchDarkly] = useState(null);
  const selectCurrentUser = useSelector(currentUser);

  const init = useCallback(
    async (user: LDUser) => {
      const ldProvider = await asyncWithLDProvider({
        clientSideID: process.env.REACT_APP_TENANT_LAUNCH_DARKLY_CLIENT_ID,
        user,
      });
      setLaunchDarkly({ Provider: ldProvider });
    },
    [setLaunchDarkly],
  );

  useEffect(() => {
    if (!launchDarkly) {
      const launchDarklyUser = getLaunchDarklyUser(selectCurrentUser);
      init(launchDarklyUser);
    }
  }, [launchDarkly, init, selectCurrentUser]);

  useEffect(() => {
    if (!selectCurrentUser) return;
    const launchDarklyUser = getLaunchDarklyUser(selectCurrentUser);
    init(launchDarklyUser);
  }, [selectCurrentUser, init]);

  return launchDarkly ? <launchDarkly.Provider>{children}</launchDarkly.Provider> : null;
};

export default LaunchDarklyInitializer;
