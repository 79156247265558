import { SERVICE_PROVIDER_ADAPTERS } from 'shared/consts';
import { ServiceProviderV3 } from 'store/serviceProviders/types';

export const resolveMembershipPermission = (providerDetails: ServiceProviderV3, canAccess: boolean): boolean => {
  if (providerDetails.config?.adapter !== SERVICE_PROVIDER_ADAPTERS.MINDBODY) return false;
  if (providerDetails.config?.adapter === SERVICE_PROVIDER_ADAPTERS.MINDBODY && !providerDetails.is_user_registered)
    return true;
  if (canAccess) return false;

  return true;
};
