import styled from 'styled-components';
import { DEVICE_WIDTHS } from 'shared/consts';
import { CarouselContentProps } from '../upcoming-bookings.interface';

interface ContentProps {
  orientation?: 'horizontal' | 'vertical';
}

export const Wrapper = styled.div<ContentProps>`
  display: flex;
  flex-direction: ${({ orientation }) => `${orientation === 'vertical' ? 'column' : 'row'}`};
  max-width: 240px;
  margin-left: 60px;
  justify-content: space-between;
  margin-top: 50px;

  @media only screen and (max-width: ${DEVICE_WIDTHS.SMALL}px) {
    margin-top: 0;
    margin-left: 0;
  }
`;

export const Block = styled.div<ContentProps>`
  margin-bottom: ${({ orientation }) => `${orientation === 'vertical' ? '20px' : 0}`};
  margin-right: ${({ orientation }) => `${orientation === 'vertical' ? 0 : '11px'}`};
`;

export const Container = styled.div``;

export const UpcomingBookingContainer = styled.div<CarouselContentProps>`
  margin-bottom: ${({ orientation }) => `${orientation === 'vertical' ? '20px' : 0}`};
  margin-right: ${({ orientation }) => `${orientation === 'vertical' ? 0 : '11px'}`};
  border-radius: 4px;
  padding: 12px 18px 12px 18px;
  background-color: ${({ theme }) => theme.colors.$greyLight};
`;

export const Title = styled.div`
  font: 500 18px/24px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const Subtitle = styled.div`
  font: 400 14px/24px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.$sharedGreyDark};
  margin-bottom: 12px;
`;
