import { ACTION_STATUSES } from 'shared/consts';
import React, { useMemo, useState } from 'react';
import { SelectPlan, SelectPlanButton } from './components';
import { selectMemberships, selectMembershipsLoadingStatus } from 'store/serviceMemberships/selectors';
import { useSelector } from 'react-redux';

import { ServiceProviderV3 } from 'store/serviceProviders/types';
import { selectCreateCartStatus } from 'store/cart/selectors';
import { SinglePaneModal } from 'components/modals/singlePaneModal/singlePaneModal';
import { useIntl } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';

export interface MembershipSelectionProps {
  currentServiceProvider: ServiceProviderV3;
  setIsModalWithoutSlideAnimation?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MembershipSelectionPage = ({
  currentServiceProvider,
  setIsModalWithoutSlideAnimation,
}: MembershipSelectionProps) => {
  const { url } = useRouteMatch();

  const getMembershipsData = useSelector(selectMemberships);
  const getMembershipsLoadingStatus = useSelector(selectMembershipsLoadingStatus);
  const createCartStatus = useSelector(selectCreateCartStatus);
  const [isButtonVisible, setIsButtonVisible] = useState(false);

  const intl = useIntl();

  const selectPlanButton = useMemo(() => {
    return isButtonVisible ? (
      <SelectPlanButton
        text={intl.formatMessage({ id: 'continue' })}
        isDisabled={createCartStatus === ACTION_STATUSES.PENDING}
        isLoading={createCartStatus === ACTION_STATUSES.PENDING}
        url={url}
        setIsModalWithoutSlideAnimation={setIsModalWithoutSlideAnimation}
      />
    ) : null;
  }, [isButtonVisible, createCartStatus, url, setIsModalWithoutSlideAnimation, setIsButtonVisible]);

  return (
    <SinglePaneModal
      contentStatus={getMembershipsLoadingStatus}
      Button={selectPlanButton}
      PaneContent={
        <SelectPlan
          memberships={getMembershipsData?.data?.memberships}
          setIsButtonVisible={setIsButtonVisible}
          currentServiceProvider={currentServiceProvider}
        />
      }
    />
  );
};
