import { SMALL_DEVICE_MAX_WIDTH } from '@hqo/react-components-library/dist/device';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 550px;
  margin-right: 300px;
  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    width: 100%;
    margin: 0;
  }
`;

export const ModalTitle = styled.div`
  font: 700 28px/32px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
  margin-bottom: 19px;
`;
