export interface PaymentAsyncActionTypes {
  status: import('shared/consts').ACTION_STATUSES;
  error: PaymentError;
}

export interface PaymentError extends Error {
  request?: Request;
  response?: PaymentErrorResponse;
  responseType?: string;
  status?: number;
}

export interface PaymentErrorResponse {
  message?: PaymentErrorMessage;
  path?: string;
  statusCode?: string;
  timestamp?: string;
}

export interface PaymentErrorMessage {
  message?: string;
  key?: string;
}

export enum PaymentErrorsEnum {
  CART_SUBMISSION_ERROR = 'CART_SUBMISSION_ERROR',
  PAYMENT_PROCESSING_ERROR = 'PAYMENT_PROCESSING_ERROR',
  CARD_DECLINED_ERROR = 'CARD_DECLINED_ERROR',
  BOOKING_UNAVAILABLE_ERROR = 'BOOKING_UNAVAILABLE_ERROR',
}
