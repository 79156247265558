import { Button, DIMENSIONS } from '@hqo/react-components-library';
import styled from 'styled-components';

export const CancelButton = styled(Button)`
  height: 48px;
  margin-top: 0;
  padding: 4px 23px;
  width: 215px;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;

export const ErrorText = styled.span`
  font: italic 12px/22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.admin.pink};
`;
