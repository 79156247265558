import { useIsMobileDevice } from '@hqo/react-components-library/dist/device/device.context.hooks';
import { LinkItem } from '@hqo/react-components-library/dist/molecules/iconBlock';
import { useMemo } from 'react';
import { Service } from 'store/availableServices/types';

interface UseCalendarLinksProps {
  isShowingLinks: boolean;
  service: Service;
}

interface UseCalendarLinksResponse {
  links: Array<LinkItem>;
}

export const useCalendarLinks = ({ isShowingLinks, service }: UseCalendarLinksProps): UseCalendarLinksResponse => {
  const { google_url: googleUrl, outlook_url: outlookUrl } = service?.calendar_options || {};
  const isMobileDevice = useIsMobileDevice();

  const links = useMemo(() => {
    return [
      googleUrl && isShowingLinks && { linkText: 'Gmail', linkRef: googleUrl, shouldOpenInNewTab: true },
      outlookUrl &&
        isShowingLinks &&
        !isMobileDevice && { linkText: 'Outlook', linkRef: outlookUrl, shouldOpenInNewTab: true },
    ].filter(Boolean);
  }, [googleUrl, outlookUrl, isShowingLinks]);

  return { links };
};
