import React from 'react';
import { CancelEventContent } from './components/cancel-event-content';
import { StyledCancelEventModal } from './styles';

interface CancelEventProps {
  ctaLabel: string;
  isFreeService: boolean;
}

export const CancelEvent = ({ ctaLabel, isFreeService }: CancelEventProps) => {
  return (
    <StyledCancelEventModal
      isFreeService={isFreeService}
      reverseAnimation={false}
      content={<CancelEventContent ctaLabel={ctaLabel} />}
      visible
    />
  );
};
