import { DIMENSIONS } from '@hqo/react-components-library';
import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const BackLinkWrapper = styled.div`
  padding-left: 20px;
  @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    padding-left: 0;
  }
`;

export const SchedulePageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  box-sizing: border-box;

  @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    width: 100%;
    height: 100%;
  }
`;
