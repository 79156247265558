import React, { FunctionComponent } from 'react';

import { CloseIcon } from '../styles';

interface Props {
  onClose: VoidFunction;
}

export const CloseButton: FunctionComponent<Props> = ({ onClose }: Props) => {
  return <CloseIcon onClick={onClose} data-testid="modal-wrapper-exit-button" role="button" tabIndex={0} />;
};
