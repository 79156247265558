import { BookingStatus } from 'shared/consts';
import { Availability, Service } from 'store/availableServices/types';
import { TransactionDetails } from 'store/transactions/types';

export const isConfirmedBooking = (
  service: Availability,
  currentAvailableService: Service,
  transactions: Array<TransactionDetails>,
): boolean => {
  const bookingForServiceTime = transactions?.find(
    transaction =>
      transaction.details.service_id === currentAvailableService?.id?.toString() &&
      transaction.details.start_at === service?.start_at &&
      transaction.details.end_at === service?.end_at,
  );

  return (
    bookingForServiceTime?.details.status === BookingStatus.CONFIRMED ||
    bookingForServiceTime?.details.status === BookingStatus.BOOKED
  );
};
