import { DiscountTypes } from 'store/cart/types';

export const formatDiscount = (
  discountAmount: number,
  discountType: string,
  currencyType: string,
  locale: string,
): string => {
  if (discountType === DiscountTypes.PERCENT) {
    return `${discountAmount}%`;
  }

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyType,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(discountAmount / 100);
};
