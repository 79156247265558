import styled from 'styled-components';
import { DIMENSIONS, PaymentMethodsManager } from '@hqo/react-components-library';

export const Container = styled.div<{ isSwipeModalContent: boolean; showResourceBookingAddOns: boolean }>`
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    ${({ showResourceBookingAddOns }) => showResourceBookingAddOns && 'margin-top: 16px'}
  }
`;

export const StyledPaymentMethodsManager = styled(PaymentMethodsManager)`
  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    padding: 0;
  }
`;
