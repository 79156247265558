import React, { useCallback } from 'react';

import { replace } from 'connected-react-router';
import { useSearchParams } from 'hooks/use-search-params.hook';
import qs from 'qs';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { ACTION_STATUSES } from 'shared/consts';
import { selectGetTransactionStatus } from 'store/transactions/selectors';
import { TransactionDetails } from 'store/transactions/types';

import { ReceiptContainer, StyledModalWrapper, StyledTwoColumnModal } from '../styles';
import { CancelBookingContent } from './cancel-booking-content';
import { ReceiptOverview } from './receipt-overview';
import { ReceiptSummary } from './receipt-summary';

interface ReceiptContentProps {
  transaction: TransactionDetails;
  onCloseClick: VoidFunction;
}

export const ReceiptContent = ({ onCloseClick, transaction }: ReceiptContentProps) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const getTransactionStatus = useSelector(selectGetTransactionStatus);
  const { confirmCancel, ...restQueryParams } = useSearchParams();

  const handlePressBack = useCallback(() => {
    const queryString = qs.stringify({ ...restQueryParams });

    dispatch(replace(`${pathname}?${queryString}`));
  }, [dispatch, pathname, restQueryParams]);

  return confirmCancel ? (
    <StyledModalWrapper
      withBackButton
      withExitButton
      visible
      onClose={onCloseClick}
      onPressBack={handlePressBack}
      backButtonVariant="chevron"
      backButtonText=""
      content={<CancelBookingContent />}
    />
  ) : (
    <ReceiptContainer data-testid="receipt-page">
      {transaction && (
        <StyledTwoColumnModal
          isContentLoading={getTransactionStatus === ACTION_STATUSES.PENDING}
          isVisible
          onClose={onCloseClick}
          LeftPaneContent={<ReceiptOverview />}
          RightPaneContent={<ReceiptSummary onCloseClick={onCloseClick} />}
        />
      )}
    </ReceiptContainer>
  );
};
