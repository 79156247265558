import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Container = styled.div`
  display: flex;
  gap: 8px;
  margin: 30px 0;
  align-items: center;
`;
export const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  margin-right: 3px;
  color: ${({ theme }) => theme.colors.fontColor};
`;
export const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.secondary};
`;
