import { Title, TitleContainer, TitleWrapper } from './styles';

import React from 'react';
import { useIntl } from 'react-intl';

export const ScheduleTitle = () => {
  const intl = useIntl();

  return (
    <TitleWrapper>
      <TitleContainer>
        <Title>{intl.formatMessage({ id: 'schedule' })}</Title>
      </TitleContainer>
    </TitleWrapper>
  );
};
