import {
  AvailableServices,
  AvailableServicesRequest,
  AvailableServiceTypes,
  CurrentAvailableServiceRequest,
  Service,
  ServiceTypesRequest,
} from './types';
import { createAction, createAsyncAction } from 'typesafe-actions';

import { FailureActionPayload } from 'store/errors/types';

export const getAvailableServices = createAsyncAction(
  'GET_AVAILABLE_SERVICES_REQUEST',
  'GET_AVAILABLE_SERVICES_SUCCESS',
  'GET_AVAILABLE_SERVICES_FAILURE',
)<AvailableServicesRequest, AvailableServices, FailureActionPayload>();

export const getCurrentAvailableService = createAsyncAction(
  'GET_CURRENT_AVAILABLE_SERVICE_REQUEST',
  'GET_CURRENT_AVAILABLE_SERVICE_SUCCESS',
  'GET_CURRENT_AVAILABLE_SERVICE_FAILURE',
)<CurrentAvailableServiceRequest, Service, FailureActionPayload>();

export const setCurrentAvailableService = createAction('SET_CURRENT_AVAILABLE_SERVICE')<Service>();

export const setSelectedAvailableService = createAction('SET_SELECTED_AVAILABLE_SERVICE')<Service>();

export const getServiceTypes = createAsyncAction(
  'GET_SERVICE_TYPES_REQUEST',
  'GET_SERVICE_TYPES_SUCCESS',
  'GET_SERVICE_TYPES_FAILURE',
)<ServiceTypesRequest, AvailableServiceTypes, FailureActionPayload>();

export const setDate = createAction('SET_SELECTED_DATE')<Date>();

export const imageIsLoaded = createAction('IMAGE_IS_LOADED')<boolean>();

export const setBookingFromClassPage = createAction('BOOKING_FROM_CLASS_PAGE')<boolean>();
