import { SavedPaymentMethod } from 'store/payment/types';
import { INVOICE } from 'shared/consts/payment-method-types';

export const findCurrentPaymentMethod = (
  paymentMethods: SavedPaymentMethod[],
  currentPaymentMethodId: string,
): SavedPaymentMethod =>
  paymentMethods?.find(
    paymentMethod =>
      paymentMethod?.id?.toString() === currentPaymentMethodId || paymentMethod?.payment_method_type === INVOICE,
  );
