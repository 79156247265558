import { CheckboxField, CheckboxWrapper } from '@hqo/react-components-library';
import { CheckboxLink, CheckboxText, StyledFieldHint } from './styles';

import React from 'react';
import { selectRegistrationFields } from 'store/serviceRegistration/selectors';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

interface Props {
  handleAgreeTerms: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isTermAccepted: boolean;
  hasErrors?: boolean;
}

export const TermsAgreement = ({ handleAgreeTerms, isTermAccepted, hasErrors = false }: Props) => {
  const intl = useIntl();
  const registrationFields = useSelector(selectRegistrationFields);
  const showError = hasErrors && !isTermAccepted;

  return (
    <CheckboxText>
      <CheckboxWrapper
        title={intl.formatMessage(
          { id: 'i_read_and_accept' },
          {
            terms_of_service: (
              <CheckboxLink href={registrationFields?.waiver_url ?? ''} target="_blank">
                {intl.formatMessage({ id: 'terms_of_service' })}
              </CheckboxLink>
            ) as unknown as string,
          },
        )}
      >
        <CheckboxField onChange={handleAgreeTerms} checked={isTermAccepted} />
      </CheckboxWrapper>
      {showError && (
        <StyledFieldHint variant="error">{intl.formatMessage({ id: 'accept_agreement_to_continue' })}</StyledFieldHint>
      )}
    </CheckboxText>
  );
};
