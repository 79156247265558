import styled from 'styled-components';
import { Button } from '@hqo/react-components-library';
import { DEVICE_WIDTHS } from 'shared/consts';
import { SMALL_DEVICE_MAX_WIDTH } from '@hqo/react-components-library/dist/device';

export const StyledButton = styled(Button)`
  min-width: 113px;
  height: 48px;
  padding: 13px 24px;
  margin-right: 110px;
  font: 500 16px/22px ${({ theme }) => theme.fonts.join()};
  border-color: ${({ theme }) => theme.colors.greys.darkGrey6};
  background-color: ${({ theme, disabled }) => (disabled ? theme.colors.greys.sharedDarkGrey : theme.colors.$primary)};
  &:hover {
    background-color: ${({ theme, disabled }) =>
      disabled ? theme.colors.greys.sharedDarkGrey : theme.colors.$primary};
  }
  @media only screen and (max-width: ${DEVICE_WIDTHS.MEDIUM}px) {
    margin-top: 0;
    margin-right: 0;
    height: 46px;
  }

  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    width: 100%;
    margin-top: 0;
    margin-right: 0;
    height: 50px;
  }
`;
