import React from 'react';
import { TitleBlock } from '@hqo/react-components-library/dist/molecules/titleBlock';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';

import { useIntl } from 'react-intl';

export const ContactInformation = () => {
  const intl = useIntl();
  const isSmallViewportWidth = useIsSmallViewportWidth();

  return isSmallViewportWidth ? null : (
    <TitleBlock
      title={intl.formatMessage({ id: 'contact_information' })}
      subtitle={intl.formatMessage({ id: 'contact_information_description' })}
    />
  );
};
