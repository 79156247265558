import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { imageIsLoaded } from 'store/availableServices/actions';
import { IMAGE_PLACEHOLDER_HEIGHT } from 'pages/event-details/constants';

interface UseImageLoading {
  isLoading: boolean;
  ref: React.MutableRefObject<HTMLImageElement>;
  setImageHeight: React.Dispatch<React.SetStateAction<number>>;
}

export const useImageLoading = ({ isLoading, setImageHeight, ref }: UseImageLoading) => {
  const dispatch = useDispatch();
  const image = document.getElementById('event-image');

  useEffect(() => {
    if (ref.current?.height && !isLoading && setImageHeight && image?.clientHeight !== IMAGE_PLACEHOLDER_HEIGHT) {
      setImageHeight(ref.current?.height);
      dispatch(imageIsLoaded(true));
    }
  }, [ref, isLoading, setImageHeight, dispatch, image?.clientHeight]);
};
