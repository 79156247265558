import { GetTransactionsResponse, PatchTransactionDto, RedirectTransactionDetails, TransactionDetails } from './types';

import { FailureActionPayload } from '../errors/types';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const getTransactions = createAsyncAction(
  'GET_TRANSACTIONS_REQUEST',
  'GET_TRANSACTIONS_SUCCESS',
  'GET_TRANSACTIONS_FAILURE',
)<void, GetTransactionsResponse, FailureActionPayload>();

export const getTransaction = createAsyncAction(
  'GET_TRANSACTION_REQUEST',
  'GET_TRANSACTION_SUCCESS',
  'GET_TRANSACTION_FAILURE',
)<string, TransactionDetails, FailureActionPayload>();

export const patchTransaction = createAsyncAction(
  'PATCH_TRANSACTION_REQUEST',
  'PATCH_TRANSACTION_SUCCESS',
  'PATCH_TRANSACTION_FAILURE',
)<PatchTransactionDto, void, FailureActionPayload>();

export const getRedirectTransaction = createAsyncAction(
  'GET_REDIRECT_TRANSACTION_REQUEST',
  'GET_REDIRECT_TRANSACTION_SUCCESS',
  'GET_REDIRECT_TRANSACTION_FAILURE',
)<string, RedirectTransactionDetails, FailureActionPayload>();

export const resetPatchTransaction = createAction('RESET_PATCH_TRANSACTION')<void>();

export const resetRedirectTransaction = createAction('RESET_REDIRECT_TRANSACTION')<void>();

export const resetTransaction = createAction('RESET_TRANSACTION')<void>();
