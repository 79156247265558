import { useCallback } from 'react';
import { formatCurrency } from 'utils';
import { useIntl } from 'react-intl';

export const useServicePrice = () => {
  const intl = useIntl();
  return useCallback((price, currency) => {
    return price ? formatCurrency(price / 100, currency) : intl.formatMessage({ id: 'free' });
  }, []);
};
