/* eslint-disable max-lines-per-function */
import { Availability, AvailableServiceView, AvailableServicesState, Service } from 'store/availableServices/types';

import { RootState } from '../reducer';
import { createSelector } from 'reselect';

export const availableServicesStateSelector = (state: RootState): AvailableServicesState => state.availableServices;

export const selectAvailableServices = createSelector(
  availableServicesStateSelector,
  availableServicesState => availableServicesState.availableServices,
);

export const imageIsLoadedSelector = createSelector(
  [availableServicesStateSelector],
  availableServicesState => availableServicesState.imageIsLoaded,
);

export const selectServices = createSelector(selectAvailableServices, availableServices => availableServices?.services);

export const selectAvailableServicesStatus = createSelector(
  availableServicesStateSelector,
  availableServicesState => availableServicesState.getAvailableServices.status,
);

export const isWaitlistAvailable = (service: Service, availability: Availability) => {
  if (availability && service.price === 0) {
    return (
      availability.seats_available <= 0 &&
      !availability.user_booked &&
      availability.waitlist?.is_available &&
      !availability.waitlist?.is_user_waitlisted
    );
  }
  return false;
};

export const selectAvailableServicesListView = createSelector(selectServices, services => {
  const filteredServices = services?.length ? services.filter(service => service?.availabilities?.[0]) : [];
  if (filteredServices.length) {
    const availableServices: AvailableServiceView[] = [];
    filteredServices.forEach(service => {
      service.availabilities.forEach(availability => {
        const waitlistAvailable = service && isWaitlistAvailable(service, availability);
        const availableService = {
          id: Number(service?.id),
          uuid: service?.uuid,
          adminName: service?.admin_name,
          bookingWindow: availability.booking_window,
          currency: service?.currency,
          duration: service?.duration,
          price: service?.price,
          requiresMembership: service?.requires_membership,
          resourceName: availability.resource?.name,
          resourceUuid: availability.resource_uuid,
          seatAvailable: availability.seats_available,
          startTime: availability.start_at,
          endTime: availability.end_at,
          title: service?.name,
          userBooked: availability.user_booked,
          userWaitlisted: !!availability.waitlist?.is_user_waitlisted,
          waitlistCount: availability.waitlist?.count ?? 0,
          waitlistAvailable,
          canAccess: service?.can_access,
          serviceTypeId: service?.service_type_id,
          class_id: service?.class_id,
        };
        availableServices.push(availableService);
      });
    });
    return availableServices.sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime());
  }
  return filteredServices;
});

export const selectCurrentAvailableService = createSelector(
  availableServicesStateSelector,
  availableServicesState => availableServicesState.currentAvailableService,
);

export const selectSelectedAvailableService = createSelector(
  availableServicesStateSelector,
  availableServicesState => availableServicesState.selectedAvailableService,
);

export const selectCurrentAvailableServiceStatus = createSelector(
  availableServicesStateSelector,
  availableServicesState => availableServicesState.getCurrentAvailableService.status,
);

export const serviceTypes = createSelector(availableServicesStateSelector, types => types.serviceTypes);

export const selectServiceTypesStatus = createSelector(
  availableServicesStateSelector,
  types => types.getServiceTypes.status,
);

export const selectDate = createSelector(
  availableServicesStateSelector,
  availableServicesState => availableServicesState.selectedDate,
);

export const selectIsBookingFromClassPage = createSelector(
  availableServicesStateSelector,
  availableServicesState => availableServicesState.isBookingFromClassPage,
);
