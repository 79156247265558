import { RootState } from '../reducer';
import { User } from '../user/types';

const SENSITIVE_INFO_PLACEHOLDER = '*****';

export const sentryStateTransformer = (state: RootState): RootState => ({
  ...state,
  user: {
    ...state.user,
    token: SENSITIVE_INFO_PLACEHOLDER,
    user: {
      ...state.user.user,
      first_name: SENSITIVE_INFO_PLACEHOLDER,
      last_name: SENSITIVE_INFO_PLACEHOLDER,
      email: SENSITIVE_INFO_PLACEHOLDER,
      slug: SENSITIVE_INFO_PLACEHOLDER,
    } as User,
  },
});
