import React from 'react';
import { Container, SinglePane, ButtonContainer, Pane } from './styles';

export interface SinglePaneWrapperProps {
  isButtonVisible: boolean;
  Button: React.ReactNode;
  Content: React.ReactNode;
}

export const SinglePaneWrapper = ({ isButtonVisible, Button, Content }: SinglePaneWrapperProps) => {
  return (
    <Container>
      <SinglePane>
        <Pane>{Content}</Pane>
      </SinglePane>
      {isButtonVisible && <ButtonContainer>{Button}</ButtonContainer>}
    </Container>
  );
};
