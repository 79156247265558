import React from 'react';
import { Container, FlexContainer, ServiceImage } from './styles';
import {
  ServiceTitleSubtitle,
  ServiceHost,
  ServiceSchedule,
  ServiceDescription,
} from 'components/service-skeleton/components';

export const ServiceSkeleton = (): JSX.Element => {
  return (
    <Container data-testid="service-skeleton">
      <FlexContainer gap="38" isWidth isColumnDirection className="desktop">
        <ServiceImage />
        <ServiceTitleSubtitle />
        <ServiceSchedule />
        <ServiceHost />
        <ServiceDescription />
      </FlexContainer>
      <FlexContainer gap="38" isWidth isColumnDirection className="mobile">
        <ServiceImage />
        <ServiceTitleSubtitle />
        <ServiceSchedule />
        <ServiceHost />
        <ServiceDescription />
      </FlexContainer>
    </Container>
  );
};
