import { externalLogin } from 'store/user/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useSearchParams } from './use-search-params.hook';
import { currentUser } from 'store/user/selectors';
import { USER_AGENT } from 'shared/consts';

// eslint-disable-next-line max-lines-per-function
export const useExternalLogin = (): boolean => {
  const dispatch = useDispatch();
  const {
    authToken: authTokenEncoded,
    apiUrl: apiUrlEncoded,
    appBrand: appBrandEncoded,
    buildingUuid: buildingUuidEncoded,
  } = useSearchParams();
  const user = useSelector(currentUser);
  const isDesktopApp =
    navigator.userAgent.toLowerCase().includes(USER_AGENT.CHROME) ||
    navigator.userAgent.toLowerCase().includes(USER_AGENT.SAFARI);
  const shouldWaitForLogin = !!authTokenEncoded || !!apiUrlEncoded;

  useEffect(() => {
    let getUserTimeout: NodeJS.Timeout;
    if (!isDesktopApp) {
      if (!user && shouldWaitForLogin) {
        dispatch(externalLogin.request());
      }
    } else {
      getUserTimeout = setTimeout(() => {
        if (!user && shouldWaitForLogin) {
          dispatch(externalLogin.request());
        }
      }, 1000);
    }

    return () => {
      clearTimeout(getUserTimeout);
    };
  }, [
    dispatch,
    authTokenEncoded,
    apiUrlEncoded,
    appBrandEncoded,
    buildingUuidEncoded,
    shouldWaitForLogin,
    user,
    isDesktopApp,
  ]);

  return shouldWaitForLogin;
};
