/** Defined in https://docs.spreedly.com/guides/3dsecure/#callbacks */
export enum TRANSACTION_STATES {
  /** The transaction has succeeded and funds have been received. */
  SUCCESS = 'succeeded',
  /** The transaction needs further processing which typically involves redirecting the customer to a redirect_url to allow them to specify a payment method. */
  PENDING = 'pending',
  /** The transaction has been accepted. Funds have not yet been received. */
  PROCESSING = 'processing',
  /** The transaction failed because the gateway declined the charge for some reason. */
  GATEWAY_PROCESSING_FAILED = 'gateway_processing_failed',
  /** We had difficulty communicating with the service and we’re unsure what the result of the operation was. (timeouts, connection errors, etc) */
  GATEWAY_PROCESSING_RESULT_UNKNOWN = 'gateway_processing_result_unknown',
  /** The transaction failed because the attempt to setup the transaction on the offsite gateway failed. */
  GATEWAY_SETUP_FAILED = 'gateway_setup_failed',
  /** The transaction failed. This could be caused by a number of things such as the payment method not being valid, the payment method being redacted, etc. */
  FAILED = 'failed',
}
