import { DATATYPE_REGISTRATION_FIELD, RegistrationField } from 'store/serviceRegistration/types';
import { INPUT_LABEL, INPUT_PLACEHOLDER } from 'shared/consts';
import React, { useCallback } from 'react';

import { RadioForm, RadioOption } from '@hqo/react-components-library/dist/molecules/radioForm';
import { StyledInput } from './styles';
import { formatBirthdayDate } from 'utils/dateFormatting';
import { useIntl } from 'react-intl';
import { validatePhoneNumber } from 'utils/phoneFormatting';

interface Props {
  registrationField: RegistrationField;
  isOtherField?: boolean;
  onInputChange: (name: string, value: string) => void;
  hasErrors?: boolean;
}

export const DynamicInput = ({ registrationField, isOtherField = false, onInputChange, hasErrors = false }: Props) => {
  const intl = useIntl();
  const radioOptions = [
    { label: intl.formatMessage({ id: 'yes' }), value: 'true' },
    { label: intl.formatMessage({ id: 'no' }), value: 'false' },
  ];
  const hasValue = !!registrationField.value;
  const displayError = registrationField.required && !hasValue && hasErrors;

  const isPhone =
    registrationField.name === INPUT_LABEL.EMERGENCY_CONTACT_INFO_PHONE ||
    registrationField.name === INPUT_LABEL.HOME_PHONE ||
    registrationField.name === INPUT_LABEL.MOBILE_PHONE ||
    registrationField.name === INPUT_LABEL.WORK_PHONE;

  const handleChangeInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (registrationField.name === INPUT_LABEL.BIRTH_DATE) {
        return onInputChange(registrationField.name, formatBirthdayDate(event.target?.value));
      }

      if (isPhone) {
        return onInputChange(registrationField.name, validatePhoneNumber(event.target?.value));
      }

      return onInputChange(registrationField.name, event.target?.value);
    },
    [registrationField, onInputChange],
  );

  const filterKeyPress = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    // Prevent user input '/' for birthdays
    if (event.key === '/') event.preventDefault();
  }, []);

  const getMaxLengthInput = (inputLabel: string) => {
    switch (inputLabel) {
      case INPUT_LABEL.BIRTH_DATE:
        return 10;
      case INPUT_LABEL.HOME_PHONE:
      case INPUT_LABEL.WORK_PHONE:
      case INPUT_LABEL.MOBILE_PHONE:
      case INPUT_LABEL.EMERGENCY_CONTACT_INFO_PHONE:
      default:
        return undefined;
    }
  };

  switch (registrationField.datatype) {
    case DATATYPE_REGISTRATION_FIELD.STRING:
      return (
        <StyledInput
          required={registrationField.required}
          value={registrationField.value}
          type="text"
          onKeyPress={filterKeyPress}
          label={isOtherField ? registrationField.name : intl.formatMessage({ id: registrationField.name })}
          placeholder={
            isOtherField
              ? intl.formatMessage({ id: 'enter_response' })
              : intl.formatMessage({ id: INPUT_PLACEHOLDER[registrationField.name] })
          }
          onChange={handleChangeInput}
          maxLength={getMaxLengthInput(registrationField.name)}
          errorText={displayError && intl.formatMessage({ id: 'please_enter_valid_input' })}
        />
      );
    case DATATYPE_REGISTRATION_FIELD.NUMBER:
      return (
        <StyledInput
          required={registrationField.required}
          value={registrationField.value}
          type="number"
          label={isOtherField ? registrationField.name : intl.formatMessage({ id: registrationField.name })}
          placeholder={
            isOtherField
              ? intl.formatMessage({ id: 'enter_response' })
              : intl.formatMessage({ id: INPUT_PLACEHOLDER[registrationField.name] })
          }
          onChange={handleChangeInput}
          errorText={displayError && intl.formatMessage({ id: 'please_enter_valid_input' })}
        />
      );
    case DATATYPE_REGISTRATION_FIELD.BOOLEAN:
      return (
        <RadioForm
          title={registrationField.name}
          selected={registrationField.value}
          required
          options={radioOptions as RadioOption[]}
          onPress={handleChangeInput}
        />
      );
    default:
      return null;
  }
};
