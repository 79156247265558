import { ACTION_STATUSES, SCHEDULE_TYPES_PARAMS } from 'shared/consts/consts';
import React from 'react';
import { AppointmentsWrapper, ContentContainer } from './styles';
import { AppointmentsList } from './appointments-list';
import { useAvailableServicesSelector } from '../schedule-content/schedule-content.hooks';
import { useParams } from 'react-router-dom';
import { ProviderParams } from 'shared/types';

export interface AppointmentSelectionProps {
  isServices?: boolean;
  selectItem: (index: number) => void;
  isSupportsServiceTypes?: boolean;
}

export const AppointmentsContent = ({ isServices, selectItem, isSupportsServiceTypes }: AppointmentSelectionProps) => {
  const { availableServices, availableServiceTypes, availableServicesStatus, serviceTypesStatus } =
    useAvailableServicesSelector();
  const { scheduleType } = useParams<ProviderParams>();

  const appointmentsItems =
    scheduleType === SCHEDULE_TYPES_PARAMS.APPOINTMENTS && isSupportsServiceTypes
      ? availableServiceTypes?.serviceTypes
      : availableServices?.services;

  return (
    <AppointmentsWrapper data-testid={isServices ? 'services-appointment' : 'types-appointment'}>
      <ContentContainer>
        {(availableServicesStatus === ACTION_STATUSES.FULFILLED ||
          (isSupportsServiceTypes && serviceTypesStatus === ACTION_STATUSES.FULFILLED)) && (
          <AppointmentsList items={appointmentsItems} selectItem={selectItem} isServices={isServices} />
        )}
      </ContentContainer>
    </AppointmentsWrapper>
  );
};
