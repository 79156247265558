import { useCallback } from 'react';
import { SavedPaymentMethod } from 'store/payment/types';
import { formatSavedPaymentMethodLabel } from 'utils/formatSavedPaymentMethodLabel';
import { getCartErrorMessage } from 'utils/getCartErrorMessage';
import { INVOICE } from 'shared/consts/payment-method-types';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectSubmitCartError } from 'store/cart/selectors';
import { UsePaymentLabelsProps, UsePaymentLabelsReturnValues } from '../types';

// eslint-disable-next-line max-lines-per-function
export const usePaymentLabels = ({
  paymentMethods,
  errorPaymentId,
  total,
}: UsePaymentLabelsProps): UsePaymentLabelsReturnValues => {
  const intl = useIntl();
  const cartError = useSelector(selectSubmitCartError);

  const buildSavedPaymentLabel = useCallback(
    (paymentMethod: SavedPaymentMethod) => {
      const { capitalizedBrand, formattedExpMonth, formattedExpYear } = formatSavedPaymentMethodLabel(paymentMethod);

      return intl.formatMessage(
        {
          id: 'saved_payment_method_option',
        },
        {
          cardBrand: capitalizedBrand,
          cardLastFour: paymentMethod.card_last_four,
          cardExpMonth: formattedExpMonth,
          cardExpYear: formattedExpYear,
        },
      );
    },
    [intl],
  );

  const buildSavedPaymentLabels = useCallback(() => {
    return paymentMethods?.map(paymentMethod => {
      const errorMessage = intl.formatMessage({ id: getCartErrorMessage(cartError) });
      const notEnoughCredits = paymentMethod?.points_balance < total;
      let label;
      let subtitle;
      if (paymentMethod?.payment_method_type === INVOICE) {
        label = intl.formatMessage({ id: 'invoice' });
        subtitle = intl.formatMessage({ id: 'invoice_text' });
      } else {
        label = buildSavedPaymentLabel(paymentMethod);
        subtitle = null;
      }

      return {
        label,
        subtitle,
        value: paymentMethod?.id.toString(),
        canRemoveOption: paymentMethod?.payment_method_type !== INVOICE,
        errorMessage: paymentMethod?.id === errorPaymentId ? errorMessage : undefined,
        disabled: notEnoughCredits,
      };
    });
  }, [paymentMethods, buildSavedPaymentLabel, errorPaymentId, intl]);

  return {
    buildSavedPaymentLabels,
  };
};
