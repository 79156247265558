import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { selectGetCartStatus, selectCart } from 'store/cart/selectors';
import { getCart } from 'store/cart/actions';
import { ACTION_STATUSES } from 'shared/consts';
import { Order } from 'store/cart/types';

export const useCart = (cartId: string): { isCartLoading: boolean; isCartFulfilled: boolean; cart: Order } => {
  const dispatch = useDispatch();
  const getCartStatus = useSelector(selectGetCartStatus);

  useEffect(() => {
    if (getCartStatus !== ACTION_STATUSES.PENDING && getCartStatus !== ACTION_STATUSES.FULFILLED && cartId) {
      dispatch(getCart.request(cartId));
    }
  }, [dispatch, getCartStatus]);

  const cart = useSelector(selectCart);

  const isCartLoading = getCartStatus === ACTION_STATUSES.PENDING;
  const isCartFulfilled = getCartStatus === ACTION_STATUSES.FULFILLED;
  return { isCartLoading, isCartFulfilled, cart };
};
