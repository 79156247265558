import React from 'react';
import { StyledCheckoutButton } from './styles';

interface Props {
  text: string;
  onClick: VoidFunction;
  isDisabled: boolean;
  isLoading: boolean;
}

export const CheckoutButton = ({ isDisabled, onClick, text, isLoading }: Props): JSX.Element => {
  return (
    <StyledCheckoutButton
      data-testid="checkout-button"
      disabled={isDisabled}
      onClick={onClick}
      text={text}
      loading={isLoading}
    />
  );
};
