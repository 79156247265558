export enum PAYMENT_MINIAPP_POST_MESSAGES {
  PAYMENT_MINIAPP_CLOSE = 'payment-miniapp-close',
  PAYMENT_MINIAPP_GO_BACK = 'payment-miniapp-goback-button',
  PAYMENT_MINIAPP_CHECKOUT_DESKTOP = 'payment-miniapp-checkout-desktop',
  PAYMENT_MINIAPP_RECEIPT = 'payment-miniapp-receipt',
  PAYMENT_MINIAPP_QUICK_CHECKOUT = 'payment-miniapp-quick-checkout',
  PAYMENT_MINIAPP_QUICK_CHECKOUT_3DS_CHALLENGE = 'payment-miniapp-quick-checkout-3ds-challenge',
  PAYMENT_MINIAPP_CHECKOUT_MOBILE = 'payment-miniapp-checkout-mobile',
  PAYMENT_MINIAPP_3DS_SUCCEEDED = 'payment-miniapp-3ds-succeeded',
  PAYMENT_MINIAPP_3DS_STATUS_UPDATE = 'payment-miniapp-3ds-status-update',
  PAYMENT_MINIAPP_FULL_CHECKOUT_SUBMIT_CLICK = 'payment-miniapp-full-checkout-sumbit-click',
  PAYMENT_MINIAPP_EDIT_BUTTON_CLICK = 'payment-miniapp-edit-button-click',
  PAYMENT_MINIAPP_DONE_BUTTON_CLICK = 'payment-miniapp-done-button-click',
  PAYMENT_MINIAPP_3DS_REDIRECT_ERROR = 'payment-miniapp-3ds-redirect-error',
}
