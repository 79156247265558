import { BookingTile } from '@hqo/react-components-library/dist/molecules/tiles/bookingTile';
import { DIMENSIONS } from '@hqo/react-components-library';
import styled from 'styled-components';

export const StyledBookingTile = styled(BookingTile)`
  width: 638px;
  margin: 8px 0;

  .status-text {
    white-space: nowrap;
    color: ${({ theme, disabled }) => (disabled ? theme.colors.greys.sharedMediumGrey : theme.colors.fontColor)};
  }

  @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    width: 95%;
  }
`;
