import { HeadPortal } from 'components/head-portal';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectBrandTheme } from 'store/theme/selectors';

export const CustomFonts = (): JSX.Element | null => {
  const brandTheme = useSelector(selectBrandTheme);

  if (!brandTheme?.font || Object.keys(brandTheme.font).length === 0) {
    return null;
  }

  const renderLinks = () => {
    return Object.entries(brandTheme.font).map(
      ([location, fontObject]) => fontObject.url && <link rel="stylesheet" key={location} href={fontObject.url} />,
    );
  };

  return <HeadPortal>{renderLinks()}</HeadPortal>;
};
