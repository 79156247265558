import { useMiniappSdk } from 'hooks/use-miniapp-sdk.hook';
import { useEffect } from 'react';

export const useToggleNativeHeader = () => {
  const client = useMiniappSdk();

  useEffect(() => {
    client?.header.hideHeader();
    return () => client?.header.showHeader();
  }, [client]);
};
