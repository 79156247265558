export enum TRANSACTION_TYPES {
  SERVICES_BOOKING = 'services_booking',
  RESOURCES_BOOKING = 'resources_booking',
  EVENT_POSTS = 'event_posts',
  ORDERING = 'ordering',
  MEMBERSHIP = 'membership',
}

export enum SupportedPaymentMethods {
  INVOICE = 'INVOICE',
  CREDIT_CARD = 'CREDIT_CARD',
  APPLE_PAY = 'APPLE_PAY',
  CREDIT_POINTS = 'CREDIT_POINTS',
}

export enum BookingStatus {
  PENDING = 'pending',
  CANCELLED = 'cancelled',
  CANCELLED_PAYMENT_CHALLENGE = 'cancelled_payment_challenge',
  CONFIRMED = 'confirmed',
  WAITLISTED = 'waitlisted',
  PENDING_PAYMENT = 'pending_payment',
  BOOKED = 'Booked',
}

export enum TransactionDetailsServiceTypes {
  APPOINTMENT = 'APPOINTMENT',
  CLASS = 'CLASS',
  EVENT = 'EVENT',
}

export enum PATCH_STATUSES_3DS {
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export enum PATCH_STATUSES {
  CANCELLED = 'cancelled',
}
