import { useDispatch, useSelector } from 'react-redux';
import { TransactionDetails } from 'store/transactions/types';
import { useCallback, useEffect, useMemo } from 'react';
import { selectTransaction, selectTransactions } from 'store/transactions/selectors';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';
import { useMiniappSdk } from 'hooks/use-miniapp-sdk.hook';
import { useSearchParams } from 'hooks/use-search-params.hook';
import qs from 'qs';
import { track } from '@hqo/web-tracking';
import { TRACK_EVENT_TYPES, TRACK_EVENTS } from 'shared/consts';
import { replace } from 'connected-react-router';
import { useLocation } from 'react-router-dom';

interface UseReceiptReturnValues {
  transaction: TransactionDetails;
  isSwipeModalContent: boolean;
  handleCancelClick: VoidFunction;
}

// eslint-disable-next-line max-lines-per-function
export const useReceipt = (transactionId?: number | string): UseReceiptReturnValues => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const transactions = useSelector(selectTransactions);
  const selectedTransaction = useSelector(selectTransaction);
  const { showMiniappPaymentsNavigation } = useFlags();
  const isMobileDevice = useIsSmallViewportWidth();
  const client = useMiniappSdk();
  const isSwipeModalContent: boolean = showMiniappPaymentsNavigation && isMobileDevice;
  const transaction = useMemo<TransactionDetails>(
    () =>
      (transactionId
        ? transactions?.find(({ details }) => details.id === transactionId)
        : transactions?.find(({ id }) => id === selectedTransaction?.id)) ?? selectedTransaction,
    [transactionId, transactions, selectedTransaction],
  );
  const params = useSearchParams();

  const handleCancelClick = useCallback(() => {
    const queryParams = {
      ...params,
      confirmCancel: true,
    };
    const queryString = qs.stringify(queryParams);

    track(
      TRACK_EVENTS.SERVICE_BOOKING_CANCEL_CLICK,
      {
        type: TRACK_EVENT_TYPES.ACTION,
      },
      { sendToHqoTracking: true },
    );

    dispatch(replace(`${pathname}?${queryString}`));
  }, [dispatch, pathname, params]);

  useEffect(() => {
    if (isSwipeModalContent) {
      client?.header.hideHeader();
      client?.swipe.disableBackSwipe();
    }

    return () => {
      client?.header.showHeader();
      client?.swipe.enableBackSwipe();
    };
  }, [client, isSwipeModalContent]);

  return {
    isSwipeModalContent,
    transaction,
    handleCancelClick,
  };
};
