import { StyledPaymentMethod } from 'components/payment/quick-checkout/components/styles';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectBuildingLocale } from 'store/building/selectors';
import { formatCurrency } from 'utils/formatCurrency';
import { PaymentMethodTypes } from '@hqo/react-components-library';
import { AppliedCreditsMethodProps } from '../types';

export const AppliedCreditsMethod = ({ cart }: AppliedCreditsMethodProps): JSX.Element => {
  const intl = useIntl();
  const buildingLocale = useSelector(selectBuildingLocale) || 'en-US';

  const creditTitle = cart.total_summary.total === 0 ? 'credits_covers_booking_cost' : 'credits_will_used';

  if (cart.total_summary.discount_applied) {
    return (
      <StyledPaymentMethod
        methodText={intl.formatMessage(
          {
            id: creditTitle,
          },
          {
            creditsAmount: formatCurrency(
              cart.total_summary.discount_applied / 100,
              cart.total_summary.currency_type,
              buildingLocale,
            ),
          },
        )}
        paymentMethod={PaymentMethodTypes.CREDIT_POINTS}
      />
    );
  }

  return null;
};
