import * as actions from './actions';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { isActionOf } from 'typesafe-actions';
import { mapServiceProviders } from 'store/serviceProviders/mappers';
import { ServiceProviderV3 } from 'store/serviceProviders/types';
import { selectFeatureFlags } from 'store/featureFlags/selectors';

export const getServiceProvidersEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getServiceProviders.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]): Observable<RootAction> => {
      const { ownerType, ownerUuid } = payload;
      const { serviceBookingUsingV3Endpoints } = selectFeatureFlags(state);
      let ajaxResponse;
      if (serviceBookingUsingV3Endpoints) {
        ajaxResponse = apiClient(state).getServiceProvidersV3(ownerType, ownerUuid);
      } else {
        ajaxResponse = apiClient(state).getServiceProviders(ownerType, ownerUuid);
      }
      return ajaxResponse.pipe(
        map(xhrPayload => {
          const massagedServiceProviders = mapServiceProviders(xhrPayload.response.data.service_providers);
          return actions.getServiceProviders.success(massagedServiceProviders);
        }),
        catchError(error => of(actions.getServiceProviders.failure({ error }))),
      );
    }),
  );

export const getCurrentServiceProviderEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getCurrentServiceProvider.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]): Observable<RootAction> => {
      const { ownerType, ownerUuid, companyUuid } = payload;
      return apiClient(state)
        .getCurrentServiceProviderV3(ownerType, ownerUuid, companyUuid)
        .pipe(
          map(xhrPayload => {
            return actions.getCurrentServiceProvider.success(
              xhrPayload.response.data.service_provider as ServiceProviderV3,
            );
          }),
          catchError(error => of(actions.getCurrentServiceProvider.failure({ error }))),
        );
    }),
  );
