import React, { useEffect } from 'react';

import { IframeContainer } from 'components/modals/3dsModal/styles';
import { Spinner } from '@hqo/react-components-library/dist/atoms/spinner';
import { defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';
import { use3DSModal } from 'hooks/use-3ds-modal.hook';

interface ThreeDSModalProps {
  setPaymentError: (value: boolean) => void;
  setIs3DSVisible: (value: boolean) => void;
  transactionToken: string;
  spreedlyToken: string;
  isQuickCheckout?: boolean;
  currentOffsetHeight?: number;
}

export const ThreeDSModal = ({
  setPaymentError,
  setIs3DSVisible,
  transactionToken,
  spreedlyToken,
  isQuickCheckout,
  currentOffsetHeight,
}: ThreeDSModalProps) => {
  const { isFingerprintPending, show3DSModal } = use3DSModal({
    setPaymentError,
    transactionToken,
    spreedlyToken,
    isQuickCheckout,
    currentOffsetHeight,
  });

  useEffect(() => {
    setIs3DSVisible(show3DSModal);
  }, [setIs3DSVisible, show3DSModal]);

  return (
    show3DSModal && (
      <IframeContainer data-test-id="iframe">
        {isFingerprintPending && <Spinner size="10em" color={defaultTheme.colors.$greyLight} />}
        <div id="device-fingerprint" data-test-id="iframe-device-fingerprint" className="hidden" />
        <div id="challenge-modal" data-test-id="iframe-challenge-modal" className="hidden">
          <div id="challenge" />
        </div>
      </IframeContainer>
    )
  );
};
