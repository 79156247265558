/* eslint-disable max-lines-per-function */
import {
  getEventDuration,
  getFormattedEventDuration,
  getFormattedEventTime,
  getFormattedEventTimeRange,
} from './formatDate';

import { ORDER_TYPES } from 'shared/consts';
import { Order } from 'store/cart/types';

export const getReceiptInfo = (cart: Order, locale: string, timezone?: string) => {
  switch (cart?.type) {
    case ORDER_TYPES.SERVICE_BOOKING_MEMBERSHIP:
      return { title: 'summary' };
    case ORDER_TYPES.SERVICE_BOOKING_CLASS:
      return {
        title: 'class_details',
        eventBlockSubtitle: getFormattedEventDuration(
          cart?.items[0]?.service_booking?.start_at,
          cart?.items[0]?.service_booking?.end_at,
          locale as string,
          timezone,
        ),
      };
    case ORDER_TYPES.SERVICE_BOOKING_EVENT: {
      return {
        title: 'event_details',
        eventBlockSubtitle: getFormattedEventTimeRange(
          cart.items[0].service_booking?.start_at,
          cart.items[0].service_booking?.end_at,
          locale,
          timezone,
        ),
      };
    }
    case ORDER_TYPES.SERVICE_BOOKING_APPOINTMENT:
      return {
        title: 'appointment_details',
        eventBlockSubtitle: `${getFormattedEventTime(
          cart.items[0].service_booking?.start_at,
          locale,
          timezone,
        )} / ${getEventDuration(
          cart?.items[0]?.service_booking?.start_at,
          cart?.items[0]?.service_booking?.end_at,
        )} min`,
      };
    default:
      return { title: 'default_details' };
  }
};
