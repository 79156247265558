import * as actions from './actions';

import { ActionType, createReducer } from 'typesafe-actions';

import { ACTION_STATUSES } from 'shared/consts';
import { ServiceMembershipsState } from './types';

export const initialState: ServiceMembershipsState = {
  memberships: null,
  getMemberships: {
    status: null,
    error: null,
  },
  currentMembership: null,
};

const getMembershipsRequestHandler = (state: ServiceMembershipsState): ServiceMembershipsState => ({
  ...state,
  getMemberships: {
    status: ACTION_STATUSES.PENDING,
    error: null,
  },
});

const getMembershipsSuccessHandler = (
  state: ServiceMembershipsState,
  { payload }: ActionType<typeof actions.getMemberships.success>,
): ServiceMembershipsState => ({
  ...state,
  memberships: payload,
  getMemberships: {
    status: ACTION_STATUSES.FULFILLED,
    error: null,
  },
});

const getMembershipsFailureHandler = (
  state: ServiceMembershipsState,
  { payload: error }: ActionType<typeof actions.getMemberships.failure>,
): ServiceMembershipsState => ({
  ...state,
  getMemberships: {
    status: ACTION_STATUSES.REJECTED,
    error: error.error,
  },
});

const setCurrentMemership = (
  state: ServiceMembershipsState,
  { payload }: ActionType<typeof actions.setCurrentMembership>,
): ServiceMembershipsState => ({
  ...state,
  currentMembership: payload,
});

const serviceMembershipsReducer = createReducer(initialState)
  .handleAction(actions.getMemberships.request, getMembershipsRequestHandler)
  .handleAction(actions.getMemberships.success, getMembershipsSuccessHandler)
  .handleAction(actions.getMemberships.failure, getMembershipsFailureHandler)
  .handleAction(actions.setCurrentMembership, setCurrentMemership);

export default serviceMembershipsReducer;
