import {
  CurrentServiceProviderRequest,
  ServiceProviderV3,
  ServiceProvidersRequest,
} from 'store/serviceProviders/types';

import { FailureActionPayload } from 'store/errors/types';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const getServiceProviders = createAsyncAction(
  'GET_SERVICE_PROVIDERS_REQUEST',
  'GET_SERVICE_PROVIDERS_SUCCESS',
  'GET_SERVICE_PROVIDERS_FAILURE',
)<ServiceProvidersRequest, Array<ServiceProviderV3>, FailureActionPayload>();

export const getCurrentServiceProvider = createAsyncAction(
  'GET_CURRENT_SERVICE_PROVIDER_REQUEST',
  'GET_CURRENT_SERVICE_PROVIDER_SUCCESS',
  'GET_CURRENT_SERVICE_PROVIDER_FAILURE',
)<CurrentServiceProviderRequest, ServiceProviderV3, FailureActionPayload>();

export const resetCurrentServiceProviderStatus = createAction('RESET_CURRENT_SERVICE_PROVIDER_STATUS')<undefined>();
