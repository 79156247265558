import { Button, Image } from '@hqo/react-components-library';
import styled from 'styled-components';
import { SMALL_DEVICE_MAX_WIDTH } from '@hqo/react-components-library/dist/device';

export const Page = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    height: calc(100vh - 70px);
  }
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex: 1;
  padding: 34px 52px;
  overflow-y: auto;

  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0 20px 0 20px;
  }
`;

export const ClassDetailsContainer = styled.div`
  width: min(840px, 100%);
  padding-bottom: 80px;
  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    padding-bottom: 30px;
  }

  &:last-child {
    margin-bottom: 50px;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px 0px 45px;

  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    padding: 10px 0px 10px;
  }
`;

export const BackLinkWrapper = styled.div`
  margin: 0px 0px 34px -25px;

  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    margin: 11px -10px;
  }
`;

export const MainContent = styled.div`
  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    margin: 0px 15px;
  }
`;

export const StyledImage = styled(Image)`
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: auto;
  border-radius: 4px;

  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    width: 100vw;
    margin-left: 50%;
    transform: translateX(-50%);
  }
`;

export const DescriptionWrapper = styled.div`
  margin-bottom: 30px;
`;

export const DescriptionHeader = styled.div`
  display: block;
  font: 700 16px/24px ${({ theme }) => theme.fonts.join()};
  margin-bottom: 13px;
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const DescriptionContent = styled.div`
  color: ${({ theme }) => theme.colors.fontColor};
  font: 400 16px/19px ${({ theme }) => theme.fonts.join()};
`;

export const Title = styled.div`
  margin: 30px 0 8px 0;
  font: 700 32px/40px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};

  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    font: 700 24px/24px ${({ theme }) => theme.fonts.join()};
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const Subtitle = styled.div`
  font: 400 16px/24px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => `${theme.colors.greys.sharedDarkGrey}`};
`;

export const IconsContainer = styled.div`
  display: flex;
  gap: 37px;
  flex-direction: column;
  margin: 33px 0px 43px;
  .icon-block-link {
    color: ${({ theme }) => `${theme.colors.secondary}`};
  }

  .icon-block-title {
    font-weight: bold;
  }

  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    margin: 24px 0px 30px;
  }
`;

export const FooterWrapper = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  margin: 0 auto;
  height: 80px;
`;

export const MembershipRequiredTitle = styled.div`
  font: 400 14px/16px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 80px;
  width: 100%;
  height: 36px;
  background-color: white;
  box-shadow: ${({ theme }) => `0px -2px 10px ${theme.colors.greys.adminBlack}15`};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 80px;
  background-color: white;
  box-shadow: 0px -1px 1px rgba(45, 51, 56, 0.1);
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  right: 0;
  bottom: 0;
  width: 100%;
  margin: 16px;
  max-width: 840px;
`;

export const StyledPrice = styled.div`
  display: block;
  font: 700 16px/18px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const StyledPriceBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledBookButton = styled(Button)`
  padding: 13px 24px;
  -webkit-tap-highlight-color: transparent;
  font: 500 16px/16px ${({ theme }) => theme.fonts.join()};
  background-color: ${({ theme, disabled }) => (disabled ? theme.colors.greys.sharedDarkGrey : theme.colors.$primary)};

  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    width: 50%;
    display: flex;
    text-align: center;
    align-items: center;
    padding: 13px 0;
  }
`;
