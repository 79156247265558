import { Background, Container, Content, CloseButtonContainer } from './styles';
import React, { FunctionComponent, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BackButton } from './components/back-button';
import { CloseButton } from './components/close-button';

export interface WrapperProps {
  visible: boolean;
  content?: JSX.Element;
  onClose: VoidFunction;
  backgroundColor?: string;
  className?: string;
  onPressBack?: VoidFunction;
  withBackButton?: boolean;
}

export const CheckoutDesktopModalWrapper: FunctionComponent<WrapperProps> = ({
  visible,
  content,
  onClose,
  backgroundColor,
  className,
  onPressBack,
  withBackButton,
}: WrapperProps) => {
  useEffect(() => {
    document.body.style.overflow = visible ? 'hidden' : 'initial';
  }, [visible]);

  const modal = (
    <div className={className}>
      <Background />
      <Container backgroundColor={backgroundColor} data-testid="modal-wrapper">
        {withBackButton && <BackButton onPressBack={onPressBack} />}
        <CloseButtonContainer>
          <CloseButton onClose={onClose} />
        </CloseButtonContainer>
        <Content>{content}</Content>
      </Container>
    </div>
  );

  return visible ? ReactDOM.createPortal(modal, document.body) : null;
};
