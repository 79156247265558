/* eslint-disable max-lines-per-function */
import React, { useCallback, useEffect } from 'react';
import { StyledForm, StyledInput } from './styles';

import { DynamicInput } from 'components/dynamic-input';
import { INPUT_LABEL } from 'shared/consts/input-labels';
import { RegistrationField } from 'store/serviceRegistration/types';
import { TermsAgreement } from 'components/terms-agreement';
import { currentUser } from 'store/user/selectors';
import { getName } from 'utils/formatUserInfo';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

interface Props {
  registrationFields: Array<RegistrationField>;
  setRegistrationInfo: React.Dispatch<React.SetStateAction<RegistrationField[]>>;
  hasWaiver: boolean;
  handleAgreeTerms?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isTermAccepted?: boolean;
  hasErrors?: boolean;
}

export const UserInformation = ({
  registrationFields,
  setRegistrationInfo,
  hasWaiver = true,
  handleAgreeTerms,
  isTermAccepted,
  hasErrors,
}: Props) => {
  const intl = useIntl();
  const user = useSelector(currentUser);

  // Populate disabled e-mail field
  useEffect(() => {
    const copy = [...(registrationFields || [])];
    const emailField = copy.find(item => item.name === INPUT_LABEL.EMAIL);
    if (emailField) {
      emailField.value = user.email;
      setRegistrationInfo(copy);
    }
  }, []);

  const onInputChangeHandler = useCallback(
    (question, text) => {
      const copy = [...registrationFields];
      const found = copy.find(item => item.name === question);
      found.value = text;
      setRegistrationInfo(copy);
    },
    [setRegistrationInfo],
  );

  return (
    <StyledForm>
      <StyledInput type="text" label={intl.formatMessage({ id: 'full_name' })} placeholder={getName(user)} disabled />
      <StyledInput type="text" label={intl.formatMessage({ id: 'email' })} placeholder={user.email} disabled />
      {/* Remove any duplicate email field */}
      {registrationFields &&
        registrationFields.reduce((filteredFields, field) => {
          if (field.name !== INPUT_LABEL.EMAIL) {
            filteredFields.push(
              <DynamicInput
                registrationField={field}
                key={field.name}
                isOtherField={!!field.id}
                onInputChange={onInputChangeHandler}
                hasErrors={hasErrors}
              />,
            );
          }
          return filteredFields;
        }, [])}
      {hasWaiver && (
        <TermsAgreement handleAgreeTerms={handleAgreeTerms} isTermAccepted={isTermAccepted} hasErrors={hasErrors} />
      )}
    </StyledForm>
  );
};
