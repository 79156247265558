import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { goBack } from 'connected-react-router';
import { useIntl } from 'react-intl';
import {
  ServiceTypeWrapper,
  HeaderWrapper,
  BackLinkWrapper,
  Title,
  TitleContainer,
  LoadingContainer,
  AppointmentsContentWrapper,
} from './styles';
import { useBackButtonVisibility } from 'hooks/use-back-button-visibility.hook';
import { NavBackLink } from '@hqo/react-components-library/dist/molecules/navBackLink';
import { AppointmentsContent } from 'pages/schedule/appointments-content/appointments-content';
import { useHandleRequest, useSelectService } from 'pages/schedule/schedule-content/schedule-content.hooks';
import { ProviderParams } from 'shared/types';
import { useParams } from 'react-router-dom';
import {
  selectAvailableServicesStatus,
  selectServiceTypesStatus,
  serviceTypes,
} from 'store/availableServices/selectors';
import { ACTION_STATUSES } from 'shared/consts';
import { Spinner } from '@hqo/react-components-library/dist/atoms/spinner';
import { defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';

export const ServiceTypePage = (): JSX.Element => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const isBackButtonVisible = useBackButtonVisibility();
  const selectService = useSelectService();
  const { serviceTypeId } = useParams<ProviderParams>();
  const availableServiceTypes = useSelector(serviceTypes);
  const serviceTypesStatus = useSelector(selectServiceTypesStatus);
  const availableServicesStatus = useSelector(selectAvailableServicesStatus);
  const { handleServiceTypesRequest } = useHandleRequest();

  const onPressBack = useCallback(() => {
    dispatch(goBack());
  }, [dispatch]);

  useEffect(() => {
    if (serviceTypesStatus === null) {
      handleServiceTypesRequest();
    }
  }, [serviceTypesStatus, handleServiceTypesRequest]);

  const serviceType = availableServiceTypes.serviceTypes.find(type => {
    return type.id.toString() === serviceTypeId;
  });

  const loadingComponent = (
    <LoadingContainer>
      <Spinner color={defaultTheme.colors.$greyLight} size="10em" />
    </LoadingContainer>
  );

  const isLoading = availableServicesStatus === null || availableServicesStatus === ACTION_STATUSES.PENDING;

  return (
    <ServiceTypeWrapper data-testid="service-type-wrapper">
      <HeaderWrapper data-testid="service-type-header">
        {isBackButtonVisible && (
          <BackLinkWrapper>
            <NavBackLink
              dataTestId="service-type-back-link"
              onClick={onPressBack}
              backLinkText={intl.formatMessage({ id: 'back' })}
            />
          </BackLinkWrapper>
        )}
        <TitleContainer>
          <Title> {serviceType.name}</Title>
        </TitleContainer>
      </HeaderWrapper>
      <AppointmentsContentWrapper data-testid="appointments-content-wrapper" isBackButtonVisible={isBackButtonVisible}>
        {isLoading && loadingComponent}
        {!isLoading && <AppointmentsContent isServices selectItem={selectService} />}
      </AppointmentsContentWrapper>
    </ServiceTypeWrapper>
  );
};
