import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCart, selectCartId, selectCreateCartStatus, selectSubmitCartStatus } from 'store/cart/selectors';
import { createCart, resetCreateCartStatus, resetSubmitCartStatus, submitCart } from 'store/cart/actions';
import { ACTION_STATUSES, OwnerType } from 'shared/consts';
import { ItemType, OrderType } from 'store/cart/types';
import { Service, SERVICE_TYPE } from 'store/availableServices/types';
import { useLocale } from 'hooks/use-locale.hook';
import { selectBuilding } from 'store/building/selectors';
import { getTransaction } from 'store/transactions/actions';
import { selectGetTransactionStatus, selectTransaction, selectTransactions } from 'store/transactions/selectors';
import { useLocation } from 'react-router';
import { useIntl } from 'react-intl';
import { getTranslationValueforCTALabel } from 'components/payment/quick-checkout/components/utils/getTranslationValueForCTALabel';
import { VariantTypes } from '@hqo/react-components-library/dist/atoms/button';
import { push, replace } from 'connected-react-router';
import { useSearchParams } from 'hooks/use-search-params.hook';
import qs from 'qs';

interface UseCtaButtonReturnValues {
  handleClick: VoidFunction;
  isButtonLoadingAndDisabled: boolean;
  processedCtaLabel: string;
  ctaVariant: VariantTypes;
}

// eslint-disable-next-line max-lines-per-function
export const useCtaButton = (service: Service, ctaLabel: string): UseCtaButtonReturnValues => {
  const dispatch = useDispatch();
  const locale = useLocale();
  const building = useSelector(selectBuilding);
  const cartId = useSelector(selectCartId);
  const createCartStatus = useSelector(selectCreateCartStatus);
  const submitCartStatus = useSelector(selectSubmitCartStatus);
  const getTransactionStatus = useSelector(selectGetTransactionStatus);
  const cart = useSelector(selectCart);
  const { pathname, search } = useLocation();
  const intl = useIntl();
  const { ...restQueryParams } = useSearchParams();
  const transaction = useSelector(selectTransaction);
  const transactions = useSelector(selectTransactions);

  const isUserBooked =
    transaction?.details?.service_id === service?.id?.toString() ||
    transactions?.some(findTransaction => findTransaction.details?.service_id === service?.id?.toString());

  const isButtonLoadingAndDisabled = useMemo<boolean>(
    () =>
      createCartStatus === ACTION_STATUSES.PENDING ||
      submitCartStatus === ACTION_STATUSES.PENDING ||
      getTransactionStatus === ACTION_STATUSES.PENDING,
    [createCartStatus, submitCartStatus, getTransactionStatus],
  );

  const createCartPayload = useMemo(
    () => ({
      type: OrderType.SERVICE_BOOKING_EVENT,
      owner_type: OwnerType.BUILDING,
      owner_id: building.uuid,
      building_uuid: building.uuid,
      items: [
        {
          id: service.uuid,
          type: ItemType.SERVICE_BOOKING_EVENT,
          quantity: 1,
          price: service.price,
          display_order: 1,
          display_info: {
            lang: locale,
            title: service.title,
            subtitle: service.subtitle,
            description1: service.description,
          },
          service_booking: {
            start_at: service.start_at,
            end_at: service.end_at,
          },
        },
      ],
      booking_type: SERVICE_TYPE.GROUP,
    }),
    [service, locale, building],
  );

  useEffect(() => {
    if (cartId && createCartStatus === ACTION_STATUSES.FULFILLED && service.price === 0) {
      dispatch(submitCart.request({ cart_id: cartId }));
      dispatch(resetCreateCartStatus());
    }
  }, [cartId, dispatch, createCartStatus, service.price]);

  useEffect(() => {
    if (cart && submitCartStatus === ACTION_STATUSES.FULFILLED) {
      dispatch(getTransaction.request(cart.transaction_uuid));
      dispatch(resetSubmitCartStatus());
    }
  }, [cart, dispatch, submitCartStatus]);

  useEffect(() => {
    if (cartId && createCartStatus === ACTION_STATUSES.FULFILLED && service.price > 0) {
      dispatch(push(`${pathname}/quick-checkout${search}&cartId=${cartId}`));
    }
  }, [cartId, dispatch, createCartStatus, service.price]);

  const handleClick = useCallback(() => {
    if (isUserBooked) {
      const queryString = qs.stringify({ ...restQueryParams, confirmCancel: true });

      dispatch(replace(`${pathname}?${queryString}`));
    } else {
      dispatch(createCart.request(createCartPayload));
    }
  }, [dispatch, createCartPayload, restQueryParams, pathname, isUserBooked]);

  const { processedCtaLabel, ctaVariant } = useMemo(() => {
    if (isUserBooked) {
      return {
        processedCtaLabel: intl.formatMessage(
          { id: 'cancel_rsvp_book' },
          { actionVerb: getTranslationValueforCTALabel(locale, service?.translations) },
        ),
        ctaVariant: 'Destructive',
      };
    }

    return { processedCtaLabel: ctaLabel, ctaVariant: 'Primary' };
  }, [isUserBooked]);

  return {
    handleClick,
    isButtonLoadingAndDisabled,
    processedCtaLabel,
    ctaVariant: ctaVariant as VariantTypes,
  };
};
