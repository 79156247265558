import { ClientInfo, ClientInfoLabel } from 'pages/gym-card/components/styles';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export interface ClientDetailsProps {
  clientId: string;
  userMemberships: string[];
}

export const ClientDetails = ({ clientId, userMemberships }: ClientDetailsProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const memberships = userMemberships?.join(', ');

  return (
    <>
      <ClientInfoLabel data-testid="id-label">
        <FormattedMessage id="id" />
      </ClientInfoLabel>
      <ClientInfo data-testid="client-id">{clientId}</ClientInfo>
      {!!userMemberships?.length && (
        <ClientInfoLabel data-testid="membership-label">
          {formatMessage({ id: 'membership' }, { count: 3 })}
        </ClientInfoLabel>
      )}
      {!!userMemberships?.length && (
        <ClientInfo key={0} data-testid="membership-list">
          {memberships}
        </ClientInfo>
      )}
    </>
  );
};
