import { useCallback } from 'react';
import { useBooleanState } from '@hqo/react-components-library';
import { UseModalNavigationReturnValues } from '../types';

// eslint-disable-next-line max-lines-per-function
export const useModalNavigation = (onCloseCheckout: VoidFunction): UseModalNavigationReturnValues => {
  const { value: showConfirmCloseModal, toggle: toggleConfirmCloseModal } = useBooleanState(false);
  const { value: reverseAnimation, toggle: toggleReverseAnimation } = useBooleanState(false);

  const onStayClick = useCallback(() => {
    toggleReverseAnimation();
    setTimeout(() => {
      toggleConfirmCloseModal();
      toggleReverseAnimation();
    }, 300);
  }, [toggleConfirmCloseModal]);

  const onLeaveClick = useCallback(() => {
    toggleConfirmCloseModal();
    onCloseCheckout();
  }, [onCloseCheckout, toggleConfirmCloseModal]);

  const onBackClick = useCallback(() => {
    onCloseCheckout();
  }, [onCloseCheckout]);

  const onCloseClick = useCallback(() => {
    toggleConfirmCloseModal();
  }, [toggleConfirmCloseModal]);

  return {
    showConfirmCloseModal,
    reverseAnimation,
    onStayClick,
    onLeaveClick,
    onBackClick,
    onCloseClick,
  };
};
