import styled from 'styled-components';
import { Button } from '@hqo/react-components-library';
import { DEVICE_WIDTHS } from 'shared/consts';
import { SMALL_DEVICE_MAX_WIDTH } from '@hqo/react-components-library/dist/device';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface SelectContentProps {
  isSelected: boolean;
}

export const SelectButton = styled(Button)`
  min-width: 113px;
  height: 48px;
  padding: 13px 24px;
  margin-right: 110px;
  font: 500 16px/22px ${({ theme }) => theme.fonts.join()};
  border-color: ${({ theme }) => theme.colors.greys.darkGrey6};
  background-color: ${({ theme, disabled }) => (disabled ? theme.colors.greys.sharedDarkGrey : theme.colors.$primary)};
  &:hover {
    background-color: ${({ theme, disabled }) =>
      disabled ? theme.colors.greys.sharedDarkGrey : theme.colors.$primary};
  }

  @media only screen and (max-width: ${DEVICE_WIDTHS.MEDIUM}px) {
    margin-top: 0;
    margin-right: 0;
    height: 46px;
  }

  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    width: 100%;
    margin-top: 0;
    margin-right: 0;
    height: 50px;
  }
`;

export const ModalTitle = styled.div`
  font: 700 28px/32px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
  margin-bottom: 19px;
`;

export const ModalSubtitle = styled.div`
  font: 400 16px/20px ${({ theme }) => theme.fonts.join()};
`;

export const Container = styled.div`
  height: 55px;
  width: 100%;
  box-shadow: 0px -2px 13px rgba(0, 0, 0, 0.1);
`;

export const ContentContainer = styled.div``;

export const DetailsContainer = styled.div<SelectContentProps>`
  visibility: ${props => (props.isSelected ? 'visible' : 'hidden')};
`;

export const DetailsContent = styled.div`
  margin-top: 10px;
`;

export const TermsContent = DetailsContent;

export const DetailsHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const RightPane = styled.span`
  display: flex;
  flex-direction: column;
`;

export const LeftPane = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DetailsText = styled.div`
  font: 400 16px/18.75px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const TermsText = DetailsText;

export const Title = styled.div`
  font: 700 18px/21px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const TermsTitle = styled.div`
  margin-bottom: 10px;
  font: 700 16px/19px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const MembershipName = styled.div`
  font: normal 14px/16px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const CloseIcon = styled(FontAwesomeIcon)`
  width: 16px;
  margin: 0 17px 0 0;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const ListContainer = styled.div`
  max-height: 374px;
  margin: 50px 50px 0 50px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 5px 5px 0px;

  @media only screen and (max-width: ${DEVICE_WIDTHS.MEDIUM}px) {
    max-height: none;
    margin: 0;
    overflow-y: hidden;
`;
