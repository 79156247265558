import { CHECKOUT_PATH, QUICK_CHECKOUT_PATH, RECEIPT_PATH } from 'shared/consts';

import { configSelector } from 'store/config/selectors';
import { getMicroFrontendParams } from 'utils';
import { useSelector } from 'react-redux';
import { userState } from 'store/user/selectors';

// eslint-disable-next-line max-lines-per-function
export const useIframeParams = (
  cartId: string,
  isQuickCheckout: boolean,
  showMiniappPaymentsNavigation?: boolean,
  isQuickCheckoutIframe?: boolean,
  transactionUuid?: string,
  error?: boolean,
  paymentId?: string,
): string => {
  const stateUser = useSelector(userState);
  const config = useSelector(configSelector);
  const forPayment: boolean = !isQuickCheckoutIframe && showMiniappPaymentsNavigation;

  const { buildingUuid } = config;
  const params = getMicroFrontendParams(
    stateUser.user,
    config.apiUrl,
    stateUser.token,
    buildingUuid,
    cartId,
    config.locale,
    forPayment,
    error,
    paymentId,
  );

  const checkoutPath = isQuickCheckout ? QUICK_CHECKOUT_PATH : CHECKOUT_PATH;
  const path = transactionUuid
    ? `${RECEIPT_PATH}?transactionUuid=${transactionUuid}&${params}`
    : `${checkoutPath}/${cartId}?${params}`;

  return `${process.env.REACT_APP_PAYMENTS_MINIAPP_URL}${path}`;
};
