import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const PaymentMethodContainer = styled.div`
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 22px;
`;

export const Method = styled.div`
  font: 400 14px/16.41px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
  flex: 1 1 auto;
`;

export const InvoiceText = styled.div`
  font: 400 14px/22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
  align-self: flex-start;
`;

export const Icon = styled(FontAwesomeIcon)`
  flex: 0 1 auto;
  color: ${({ theme }) => theme.colors.fontColor};
  margin-right: 22px;
`;

export const Title = InvoiceText;

export const Subtitle = styled.div`
  font: 400 14px/16px ${({ theme }) => theme.fonts.join()};
  font-style: italic;
  color: ${({ theme }) => theme.colors.greys.sharedDarkGrey};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GroupedPaymentMethods = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
`;
