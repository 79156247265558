import { PriceBlock } from './styles';
import React from 'react';
import { formatCurrency } from 'utils/formatCurrency';
import { COINS_PER_BILL } from './constants';

interface PriceBlockProps {
  locale: string;
  price?: number;
  currency?: string;
}

export const Price = ({ locale, price, currency }: PriceBlockProps): JSX.Element => {
  return (
    <PriceBlock data-testid="price-block">{formatCurrency(price / COINS_PER_BILL, currency, locale, false)}</PriceBlock>
  );
};
