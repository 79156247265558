import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectInitialRoute } from 'store/routes/selectors';
import { LoadingSkeleton } from '@hqo/react-components-library/dist/molecules/loading-skeleton';
import { EmptyContentTile } from '@hqo/react-components-library/dist/molecules/tiles/empty-content-tile';
import { Wrapper } from './styles';
import { ROOT_PATH } from 'shared/consts';

export const Skeleton = () => {
  const route = useSelector(selectInitialRoute);
  const isServiceProvidersInitialPage = useMemo<boolean>(() => route === ROOT_PATH, [route]);

  return isServiceProvidersInitialPage ? (
    <Wrapper data-testid="service-providers-skeleton">
      <EmptyContentTile />
      <EmptyContentTile />
      <EmptyContentTile />
    </Wrapper>
  ) : (
    <LoadingSkeleton />
  );
};
