export interface VisitorAnalytics extends pendo.Metadata {
  id: string;
  current_user_roles: Array<string>;
  tenant_company_id: string;
  app_brand: string;
  current_building_name: string;
  account: string;
  current_portfolio_id: string;
  test_user: boolean;
  os_name: string;
  os_version: string;
}

export interface AccountAnalytics extends pendo.Metadata {
  id: string;
  current_building_name: string;
  current_building_uuid: string;
  current_portfolio_id: string;
}

export interface PendoAnalytics {
  visitor: VisitorAnalytics;
  account: AccountAnalytics;
}

export const initializePendo = (
  pendo: Pick<pendo.Pendo, 'getAccountId' | 'initialize' | 'identify'>,
  analytics: PendoAnalytics,
): void => {
  if (!pendo.getAccountId?.()) {
    pendo.initialize?.(analytics);
  } else {
    pendo.identify?.(analytics);
  }
};
