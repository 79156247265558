import { DIMENSIONS } from '@hqo/react-components-library';
import { EmptyStateTw } from '@hqo/react-components-library/dist/molecules/empty-state/emptyState';
import styled from 'styled-components';

export const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 150px 0 0;

  @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    padding: 100px 0 0;
  }
`;

export const StyledEmptyState = styled(EmptyStateTw)``;
