import React from 'react';
import { SERVICE_SEATS_AVAILABLE_WARNING_THRESHOLD } from 'shared/consts';
import { useIntl } from 'react-intl';
import { SpotsCounter } from './styles';
import { Availability } from 'store/availableServices/types';

interface SpotsCounterBlockProps {
  availability: Availability;
}

export const SpotsCounterBlock = ({ availability }: SpotsCounterBlockProps): JSX.Element => {
  const intl = useIntl();

  return (
    !isNaN(availability.seats_available) &&
    availability.seats_available <= SERVICE_SEATS_AVAILABLE_WARNING_THRESHOLD && (
      <SpotsCounter>
        {intl.formatMessage(
          { id: 'spots_counter' },
          {
            spotQuantity: availability.seats_available,
          },
        )}
      </SpotsCounter>
    )
  );
};
