import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FontLocation } from 'shared/consts';
import { selectThemeFont } from 'store/theme/selectors';

export interface ThemeLocationFont {
  fontFamily?: string;
  url?: string;
}

export const useThemeFont = (location: FontLocation): ThemeLocationFont | null => {
  const themeFont = useSelector(selectThemeFont);
  const font = themeFont?.[location];

  return useMemo(() => {
    if (font) {
      return {
        fontFamily: font.font_family,
        url: font.url,
      };
    }
    return null;
  }, [font]);
};
