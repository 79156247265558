import { DIMENSIONS } from '@hqo/react-components-library';
import styled from 'styled-components';

export const ListWrapper = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  align-items: center;

  @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    padding: 15px;
  }
`;

export const StyledEmptyContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
