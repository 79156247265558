import React, { useMemo } from 'react';
import { BoldText } from 'hocs/bold-text';
import { getFormattedEventTimeRange } from 'utils/formatDate';
import { useBookingDetails } from './use-booking-details.hook';
import { useIntl } from 'react-intl';
import { useLocale } from 'hooks/use-locale.hook';
import { useSelector } from 'react-redux';
import { selectCurrentAvailableService } from 'store/availableServices/selectors';
import { selectCart } from 'store/cart/selectors';
import { selectBuildingTimeZone } from 'store/building/selectors';
import { selectTransactionDisplay } from 'store/transactions/selectors';
import { ORDER_TYPES } from 'shared/consts';

interface UseCartOverviewReturnValues {
  title: string;
  subtitle: string | JSX.Element | Array<string | JSX.Element>;
}

export const useCartOverview = (): UseCartOverviewReturnValues => {
  const intl = useIntl();
  const locale = useLocale();
  const service = useSelector(selectCurrentAvailableService);
  const cart = useSelector(selectCart);
  const buildingTimeZone = useSelector(selectBuildingTimeZone);
  const transactionDisplay = useSelector(selectTransactionDisplay);
  const { eventWeekDayLong, eventDateMonthLong, eventDateDay } = useBookingDetails();
  const { type, items, vendor } = cart || {};
  const serviceCtaLabel = service?.cta_label?.toLowerCase();

  const commonIntlValuesFromCart = useMemo(
    () => ({
      serviceName: <BoldText>{cart?.items?.[0].display_info.title}</BoldText>,
      serviceTime: <BoldText>{`${eventWeekDayLong}, ${eventDateMonthLong} ${eventDateDay}`}</BoldText>,
    }),
    [transactionDisplay],
  );

  const commonSubtitle = intl.formatMessage({ id: 'timekit_confirmation_text' }, commonIntlValuesFromCart);

  const eventDetails = useMemo(
    () => ({
      serviceName: <BoldText>{items?.[0]?.display_info.title}</BoldText>,
      serviceDate: <BoldText>{`${eventWeekDayLong}, ${eventDateMonthLong} ${eventDateDay}`}</BoldText>,
      serviceTime: (
        <BoldText>
          {getFormattedEventTimeRange(
            items?.[0]?.service_booking?.start_at,
            items?.[0]?.service_booking?.end_at,
            locale,
            buildingTimeZone,
          )}
        </BoldText>
      ),
    }),
    [items, eventWeekDayLong, eventDateMonthLong, eventDateDay, locale, buildingTimeZone],
  );

  return useMemo(() => {
    switch (type) {
      case ORDER_TYPES.SERVICE_BOOKING_EVENT:
        return {
          title: serviceCtaLabel ? `${serviceCtaLabel}_confirmed` : 'book_confirmed',
          subtitle: intl.formatMessage(
            {
              id: serviceCtaLabel ? `event_${serviceCtaLabel}_confirmation_text` : 'event_book_confirmation_text',
            },
            eventDetails,
          ),
        };
      case ORDER_TYPES.SERVICE_BOOKING_MEMBERSHIP:
        return {
          title: 'membership_activated',
          subtitle: intl.formatMessage({ id: 'you_are_member' }, { membership: vendor?.name }),
        };
      case ORDER_TYPES.SERVICE_BOOKING_CLASS:
        return {
          title: 'class_booked',
          subtitle: commonSubtitle,
        };
      case ORDER_TYPES.SERVICE_BOOKING_APPOINTMENT:
        return {
          title: 'appointment_booked',
          subtitle: commonSubtitle,
        };
      default:
        return {
          title: 'default_receipt',
          subtitle: null,
        };
    }
  }, [cart, intl, service, commonIntlValuesFromCart, locale, buildingTimeZone]);
};
