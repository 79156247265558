export enum INPUT_LABEL {
  FIRST_NAME = 'first_name',
  MIDDLE_NAME = 'middle_name',
  LAST_NAME = 'last_name',
  ADDRESS_LINE_1 = 'address_line_1',
  ADDRESS_LINE_2 = 'address_line_2',
  APPT_GENDER_PREF_MALE = 'appt_gender_pref_male',
  BIRTH_DATE = 'birth_date',
  CITY = 'city',
  COUNTRY = 'country',
  EMERGENCY_CONTACT_INFO_EMAIL = 'emergency_contact_info_email',
  EMERGENCY_CONTACT_INFO_NAME = 'emergency_contact_info_name',
  EMERGENCY_CONTACT_INFO_PHONE = 'emergency_contact_info_phone',
  EMERGENCY_CONTACT_INFO_RELATIONSHIP = 'emergency_contact_info_relationship',
  GENDER = 'gender',
  HOME_PHONE = 'home_phone',
  MOBILE_PHONE = 'mobile_phone',
  POSTAL_CODE = 'postal_code',
  SEND_ACCOUNT_EMAILS = 'send_account_emails',
  SEND_SCHEDULE_EMAILS = 'send_schedule_emails',
  SEND_PROMOTIONAL_EMAILS = 'send_promotional_emails',
  STATE = 'state',
  WORK_PHONE = 'work_phone',
  WORK_EXTENSION = 'work_extension',
  EMAIL = 'email',
  IS_MALE = 'is_male',
  REFERRED_BY = 'referred_by',
}
