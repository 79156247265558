import { TRANSACTION_TYPES, TransactionDetailsServiceTypes } from 'shared/consts';
import { TransactionDetails } from 'store/transactions/types';
import { getEventDuration, getFormattedEventDuration, getFormattedEventTime } from './formatDate';

interface ReceiptInfo {
  title: string;
  eventBlockSubtitle?: string;
  paymentBlockTitle?: string;
}

// eslint-disable-next-line max-lines-per-function
export const getReceiptInfoFromTransaction = (
  transaction: TransactionDetails,
  locale: string,
  timezone?: string,
): ReceiptInfo => {
  if (transaction.type === TRANSACTION_TYPES.MEMBERSHIP) {
    return { title: 'summary', paymentBlockTitle: 'summary' };
  }
  if (transaction.type === TRANSACTION_TYPES.EVENT_POSTS) {
    return { title: 'default_details', paymentBlockTitle: 'payment_details' };
  }
  if (transaction.type !== TRANSACTION_TYPES.SERVICES_BOOKING) {
    return { title: 'default_details', paymentBlockTitle: 'default_details' };
  }

  switch (transaction.details.service?.type) {
    case TransactionDetailsServiceTypes.APPOINTMENT:
      return {
        title: 'appointment_details',
        paymentBlockTitle: 'appointment_details',
        eventBlockSubtitle: `${getFormattedEventTime(
          transaction.details.start_at,
          locale,
          timezone,
        )} / ${getEventDuration(transaction?.details?.start_at, transaction?.details?.end_at)} min`,
      };
    case TransactionDetailsServiceTypes.CLASS:
      return {
        title: 'class_details',
        paymentBlockTitle: 'class_details',
        eventBlockSubtitle: getFormattedEventDuration(
          transaction.details.start_at,
          transaction.details.end_at,
          locale,
          timezone,
        ),
      };
    case TransactionDetailsServiceTypes.EVENT:
      return {
        title: 'event_details',
        paymentBlockTitle: 'payment_details',
        eventBlockSubtitle: getFormattedEventDuration(
          transaction.details.start_at,
          transaction.details.end_at,
          locale,
          timezone,
        ),
      };
    default:
      return { title: 'default_details', paymentBlockTitle: 'default_details' };
  }
};
