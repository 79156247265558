import { PaymentMethod } from 'store/transactions/types';

interface formattedPaymentMethodDetails {
  capitalizedBrand: string;
  formattedExpMonth: string;
  formattedExpYear: string;
}

export const formatSavedPaymentMethodLabelFromTransaction = (
  paymentMethod: PaymentMethod,
): formattedPaymentMethodDetails => {
  const capitalizedBrand =
    paymentMethod && `${paymentMethod.card_type?.charAt(0).toUpperCase()}${paymentMethod.card_type?.slice(1)}`;

  const formattedExpMonth =
    paymentMethod &&
    (paymentMethod.exp_month?.toString().length < 2
      ? `0${paymentMethod.exp_month}`
      : paymentMethod.exp_month?.toString());

  const formattedExpYear = paymentMethod && paymentMethod.exp_year?.toString()?.slice(2);

  return { capitalizedBrand, formattedExpMonth, formattedExpYear };
};
