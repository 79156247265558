import React from 'react';
import ReactDOM from 'react-dom';

interface HeadPortalProps {
  children: React.ReactNode;
}

export const HeadPortal = ({ children }: HeadPortalProps): JSX.Element | null => {
  const { head } = document;

  if (!head) {
    return null;
  }

  return ReactDOM.createPortal(children, head);
};
