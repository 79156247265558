import moment from 'moment';
import { formatDateOptions } from 'utils/formatDateOptions';

// Cleanup once all date-time conversions are handled in BE and LD flag is removed
// eslint-disable-next-line max-lines-per-function
export const formatDate = (value: string, locale: string, timeZone: string, inLocalTimeLD?: boolean) => {
  // Remove once Helix removes Z from date-time strings https://ventureapp.atlassian.net/browse/SONIC-1501
  const localValue = inLocalTimeLD ? value.replace('Z', '') : value;
  const eventDateMonth = new Date(localValue).toLocaleString(
    locale,
    formatDateOptions(timeZone, { month: 'short' }, inLocalTimeLD),
  );
  const eventDateMonthNum = new Date(localValue).toLocaleString(
    locale,
    formatDateOptions(timeZone, { month: 'numeric' }, inLocalTimeLD),
  );
  const eventDateDay = new Date(localValue).toLocaleString(
    locale,
    formatDateOptions(timeZone, { day: 'numeric' }, inLocalTimeLD),
  );
  const eventDateMonthLong = new Date(localValue).toLocaleString(
    locale,
    formatDateOptions(timeZone, { month: 'long' }, inLocalTimeLD),
  );
  const eventDateYear = new Date(localValue).toLocaleString(
    locale,
    formatDateOptions(timeZone, { year: 'numeric' }, inLocalTimeLD),
  );
  const eventWeekDayLong = new Date(localValue).toLocaleString(
    locale,
    formatDateOptions(timeZone, { weekday: 'long' }, inLocalTimeLD),
  );
  const eventWeekDayShort = new Date(localValue).toLocaleString(
    locale,
    formatDateOptions(timeZone, { weekday: 'short' }, inLocalTimeLD),
  );
  const formatEventTime = new Date(localValue).toLocaleString(
    locale,
    formatDateOptions(
      timeZone,
      {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
      },
      inLocalTimeLD,
    ),
  );

  return {
    eventDateMonth,
    eventDateMonthNum,
    eventDateDay,
    eventDateMonthLong,
    eventDateYear,
    formatEventTime,
    eventWeekDayLong,
    eventWeekDayShort,
  };
};

export const getEventDuration = (startTime: string, endTime: string): string => {
  const eventStart = moment(startTime);
  const eventEnd = moment(endTime);

  return eventEnd.diff(eventStart, 'minutes').toString();
};

export const getFormattedEventDuration = (
  startDate: string,
  endDate: string,
  locale: string,
  timezone?: string,
): string => {
  const timeZone = timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
  const eventStartTime = new Date(startDate).toLocaleString(locale, {
    hour: 'numeric',
    minute: '2-digit',
    timeZone,
  });
  const eventEndTime = new Date(endDate).toLocaleString(locale, {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
    timeZone,
  });

  return `${eventStartTime} - ${eventEndTime}`;
};

export const getFormattedEventTime = (date: string, locale: string, timezone: string): string => {
  return new Date(date).toLocaleTimeString(locale, {
    timeZone: timezone,
    timeStyle: 'short',
  });
};

export const getFormattedEventTimeRange = (
  startDate: string,
  endDate: string,
  locale: string,
  timezone?: string,
): string => {
  const timeZone = timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;

  const startTime = new Date(startDate).toLocaleString(locale, {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
    timeZone,
  });

  const endTime = new Date(endDate).toLocaleString(locale, {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
    timeZone,
  });

  return `${startTime} - ${endTime}`;
};
