import { AccordionTw } from '@hqo/react-components-library/dist/molecules/accordion';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';

import { ContentContainer, ListContainer, ModalSubtitle, ModalTitle } from './styles';
import React, { useCallback, useEffect, useState } from 'react';

import { DEFAULT_LOCALE } from 'shared/consts';
import { Membership } from 'store/serviceMemberships/types';
import { MembershipDetails } from './membership-details';
import { ServiceProviderV3 } from 'store/serviceProviders/types';
import { useIntl } from 'react-intl';
import { setCurrentMembership } from 'store/serviceMemberships/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectBuilding } from 'store/building/selectors';
import { replace } from 'connected-react-router';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { RegistrationContractAgreementStep } from 'shared/consts/service-provider';

export interface Props {
  memberships: Array<Membership>;
  setIsButtonVisible: React.Dispatch<React.SetStateAction<boolean>>;
  currentServiceProvider: ServiceProviderV3;
}
export const SelectPlan = ({ memberships, setIsButtonVisible, currentServiceProvider }: Props) => {
  const intl = useIntl();
  const { 'selected-plan': selectedPlan } = useSearchParams();
  const restQueryParams = useSearchParams();
  const building = useSelector(selectBuilding);
  const dispatch = useDispatch();
  const isSmallViewportWidth = useIsSmallViewportWidth();

  const currentMembershipIndex = memberships.findIndex(
    membership => membership.id === decodeURIComponent(selectedPlan as string),
  );
  const [activeIndex, setActiveIndex] = useState(currentMembershipIndex >= 0 ? currentMembershipIndex : null);

  const checkPrice = useCallback(
    (id: string, subtotal: number) => {
      const userMembership = currentServiceProvider.user_memberships
        ? currentServiceProvider.user_memberships.find(element => element.id === id)
        : null;
      return userMembership
        ? ''
        : Intl.NumberFormat(DEFAULT_LOCALE, { style: 'currency', currency: building.currency_type }).format(subtotal);
    },
    [currentServiceProvider],
  );

  const checkDescription = useCallback(
    (id: string, tax: number) => {
      const userMembership = currentServiceProvider.user_memberships
        ? currentServiceProvider.user_memberships.find(element => element.id === id)
        : null;
      let description;
      if (userMembership) {
        description = intl.formatMessage({ id: 'current_rate' });
      } else if (!userMembership && tax === 0) {
        description = intl.formatMessage({ id: 'excludes_tax_and_tip' });
      } else {
        description = '';
      }
      return description;
    },
    [currentServiceProvider],
  );

  useEffect(() => {
    if (selectedPlan) {
      setIsButtonVisible(true);
    } else {
      setIsButtonVisible(false);
    }
  }, [selectedPlan, dispatch, setIsButtonVisible]);

  const selectPlan = useCallback(
    (index: number) => {
      const selectedMembership = memberships[index];
      dispatch(setCurrentMembership(selectedMembership));
      if (index === activeIndex) {
        setActiveIndex(null);
        dispatch(replace(`${location.pathname}?step=${RegistrationContractAgreementStep.ENROLLMENT}`));
      } else {
        setActiveIndex(index);
        dispatch(
          replace(
            `${location.pathname}?step=${
              RegistrationContractAgreementStep.ENROLLMENT
            }&selected-plan=${encodeURIComponent(selectedMembership.id)}`,
          ),
        );
      }
    },
    [activeIndex, dispatch, memberships, restQueryParams],
  );

  const closeDetails = useCallback(() => {
    setIsButtonVisible(false);
    setActiveIndex(null);
    dispatch(replace(`${location.pathname}?step=${RegistrationContractAgreementStep.ENROLLMENT}`));
  }, [dispatch, setActiveIndex, setIsButtonVisible]);

  return (
    <ContentContainer>
      {!isSmallViewportWidth && (
        <>
          <ModalTitle>{intl.formatMessage({ id: 'select_plan' })}</ModalTitle>
          <ModalSubtitle>{intl.formatMessage({ id: 'select_plan_description' })}</ModalSubtitle>
        </>
      )}
      <ListContainer>
        {memberships.map((membership, index) => (
          <AccordionTw
            key={membership.id}
            title={membership.name}
            onClick={selectPlan}
            price={checkPrice(membership.id, membership.subtotal / 100)}
            description={checkDescription(membership.id, membership.tax / 100)}
            content={
              <MembershipDetails
                description={membership.description}
                agreementTerms={membership.agreement_terms}
                onClick={closeDetails}
                name={membership.name}
                isSelected={activeIndex === index}
              />
            }
            index={index}
            isSelected={activeIndex === index}
          />
        ))}
      </ListContainer>
    </ContentContainer>
  );
};
