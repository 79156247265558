import { DIMENSIONS } from '@hqo/react-components-library';
import { EmptyStateTw } from '@hqo/react-components-library/dist/molecules/empty-state/emptyState';
import styled from 'styled-components';

export const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  text-align: center;
  width: 340px;

  @media screen and (max-width: ${DIMENSIONS.SMALL}) {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
`;

export const StyledEmptyState = styled(EmptyStateTw)``;
