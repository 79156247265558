import { ModalContainer, StyledModalWrapper } from './styles';
import React from 'react';
import { Container } from 'hocs/shared-styles';
import { OrderType } from 'store/cart/types';

import { Spinner } from '@hqo/react-components-library/dist/atoms/spinner';
import { defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';
import { useIFrame } from 'hooks/payment-hook/use-iFrame.hook';

export interface PaymentIFrameProps {
  currentOrderType?: OrderType;
  reverseAnimation: boolean;
  toggleReverseAnimation: VoidFunction;
}

export const PaymentIFrame = ({ currentOrderType, reverseAnimation, toggleReverseAnimation }: PaymentIFrameProps) => {
  const {
    isContentLoading,
    handleIframeLoading,
    iFrameUrl,
    isCheckoutDesktop,
    isQuickCheckout,
    quickCheckout,
    isSwipeModalContent,
    isReceipt,
    isFullCheckout,
    is3DSChallenge,
  } = useIFrame(currentOrderType, toggleReverseAnimation);
  const buildContent = (): JSX.Element => (
    <ModalContainer
      quickCheckout={!!quickCheckout}
      isCheckoutDesktop={isCheckoutDesktop}
      isQuickCheckout={isQuickCheckout}
      is3DSChallenge={is3DSChallenge}
      isSwipeModalContent={isSwipeModalContent}
    >
      {isContentLoading ? (
        <Container isLoading isIframeLoading>
          <Spinner color={defaultTheme.colors.$greyLight} size="10em" />
        </Container>
      ) : null}
      <iframe
        frameBorder="0"
        src={iFrameUrl}
        title="Iframe SAML"
        height="100%"
        width={isContentLoading ? '0%' : '100%'}
        data-testid="iframe_testid"
        sandbox="allow-scripts allow-same-origin allow-presentation allow-forms allow-pointer-lock"
        onLoad={handleIframeLoading}
      />
    </ModalContainer>
  );

  return (
    <StyledModalWrapper
      reverseAnimation={reverseAnimation}
      quickCheckout={!!quickCheckout}
      isReceipt={isReceipt}
      isFullCheckout={isFullCheckout}
      visible
      content={buildContent()}
      isCheckoutDesktop={isCheckoutDesktop}
      isQuickCheckout={isQuickCheckout}
    />
  );
};
