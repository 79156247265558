import { Subtitle, Title, TitleContainer, TitleWrapper } from './styles';

import React from 'react';
import { useIntl } from 'react-intl';

export const GymCardTitle = () => {
  const intl = useIntl();

  return (
    <TitleWrapper>
      <TitleContainer>
        <Title>{intl.formatMessage({ id: 'access_gym_card' })}</Title>
        <Subtitle>{intl.formatMessage({ id: 'scan_card' })}</Subtitle>
      </TitleContainer>
    </TitleWrapper>
  );
};
