import { CloseButton, StyledMobileModal } from './modal.styles';

import React from 'react';

interface MobileModalProps {
  children: React.ReactNode;
  onClose?: VoidFunction;
  hideMobileCloseButton?: boolean;
}

export const MobileModal = ({
  children,
  onClose: handleClose,
  hideMobileCloseButton,
}: MobileModalProps): JSX.Element => {
  return (
    <StyledMobileModal data-testid="mobile-modal-window" data-cy="modal-window">
      {children}
      {!hideMobileCloseButton && (
        <CloseButton icon={['fas', 'times']} onClick={handleClose} data-testid="close-modal" data-cy="close-modal" />
      )}
    </StyledMobileModal>
  );
};
