import { DEFAULT_TIMEZONE, WEEK_LENGTH } from 'shared/consts';
import moment, { unitOfTime } from 'moment-timezone';

export const getDatesInWeekRange = (date: Date, timeZone = DEFAULT_TIMEZONE, startingDay = 1) => {
  const firstDayOfWeek = moment(date).tz(timeZone).startOf('week').add(startingDay, 'days');
  return Array.from({ length: WEEK_LENGTH }, (_, index) => {
    return firstDayOfWeek.clone().add(index, 'days').toDate();
  });
};

export const isToday = (date: Date, locale?: string, timeZone?: string) => {
  const today = new Date(new Date().toLocaleDateString(locale, { timeZone }));
  const localDate = new Date(date.toLocaleDateString(locale, { timeZone }));
  return (
    localDate.getDate() === today.getDate() &&
    localDate.getMonth() === today.getMonth() &&
    localDate.getFullYear() === today.getFullYear()
  );
};

export const isBeforeCurrentDateTime = (date: Date, timeZone = DEFAULT_TIMEZONE, inLocalTimeLD = false) => {
  const localCurrentDate = moment.tz(timeZone).utcOffset(0, true);
  const incomingDate = inLocalTimeLD ? moment(date).utcOffset(0, true) : moment.tz(date, timeZone);
  return incomingDate.utcOffset(0, true).isBefore(localCurrentDate);
};

export const isDurationBeforeCurrentDateTime = (
  date: string,
  amountOfTime: number,
  unit: unitOfTime.Base,
  timeZone = DEFAULT_TIMEZONE,
) => {
  return moment.tz(date, timeZone).subtract(amountOfTime, unit).isBefore(moment.tz(timeZone));
};

export const isSameOrAfterBaseDateTime = (baseDate: Date, date: Date, timeZone = DEFAULT_TIMEZONE) => {
  return moment.tz(date, timeZone).isSameOrAfter(moment.tz(baseDate, timeZone));
};

export const isBetweenDatesTime = (
  startDate: Date,
  endDate: Date,
  date: Date,
  timeZone = DEFAULT_TIMEZONE,
  inLocalTimeLD = false,
) => {
  if (inLocalTimeLD) {
    return moment(date).isBetween(
      moment(startDate).utcOffset(0, true),
      moment(endDate).utcOffset(0, true),
      'second',
      '[]',
    );
  }
  return moment
    .tz(date, timeZone)
    .isBetween(moment.tz(startDate, timeZone), moment.tz(endDate, timeZone), 'second', '[]');
};
