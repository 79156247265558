/**
 * Returns a 123-456-7890 formatted phone number string
 *
 * @param phoneNumber string
 * @returns Formatted phone number string in 123-456-7890 format
 */
export const formatPhoneNumber = (phoneNumber: string) => {
  const formattedNumber = phoneNumber.split('-').join('');
  if (formattedNumber.length < 9) {
    const match = formattedNumber.match(/\d{1,3}/g);
    return match !== null ? match.join('-') : ''; // add dash (-) after every 3rd char.
  }
  return phoneNumber;
};

/**
 * Returns input phoneNumber without letters or double special characters
 *
 * @param phoneNumber string
 * @returns number without letters or double special characters
 */
export const validatePhoneNumber = (phoneNumber: string) => {
  let validatedNumber = phoneNumber.replace(/[^\d\- ]/g, '');

  const doubleCharacterRegex = /[- ]{2,}/;
  const doubleCharacters = phoneNumber.match(new RegExp(doubleCharacterRegex, 'g'));
  if (doubleCharacters) {
    doubleCharacters.forEach(character => {
      validatedNumber = validatedNumber.replace(doubleCharacterRegex, character[0]);
    });
  }

  return validatedNumber;
};
