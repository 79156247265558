import * as userActions from './actions';

import { catchError, filter, map, mapTo, switchMap, withLatestFrom } from 'rxjs/operators';

import { AjaxError } from 'rxjs/ajax';
import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { NotFound } from 'store/user/errors';
import { isActionOf } from 'typesafe-actions';
import { concat, of } from 'rxjs';
import qs from 'qs';
import { replace } from 'connected-react-router';
import { getBuilding } from 'store/building/actions';
import { getBuildingTheme } from 'store/theme/actions';
import {
  ERROR_DEFAULT,
  BAD_REQUEST_ERROR_CODE,
  INTERNAL_SERVER_ERROR_CODE,
  NOT_FOUND_ERROR_CODE,
  UNAUTHORIZED_ERROR_CODE,
} from 'shared/consts/errors';
import { getErrorCode } from 'store/utils/get-error-code.util';
import { externalLogin } from './actions';

const isUnauthorizedError = (error: unknown): boolean =>
  error instanceof AjaxError && error.status >= BAD_REQUEST_ERROR_CODE && error.status < INTERNAL_SERVER_ERROR_CODE;

export const externalLoginEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(userActions.externalLogin.request)),
    withLatestFrom(state$),
    switchMap(([, state]) => {
      const { authToken, apiUrl } = state.config;

      return apiClient(state, apiUrl, authToken)
        .getCurrentUser()
        .pipe(
          map(xhrPayload => userActions.externalLogin.success({ user: xhrPayload.response, apiUrl, token: authToken })),
          catchError((error: Error) => {
            if (isUnauthorizedError(error)) {
              return of(userActions.externalLogin.failure({ error: new NotFound(), errorCode: ERROR_DEFAULT }));
            }

            return of(userActions.externalLogin.failure({ error, errorCode: getErrorCode(error) }));
          }),
        );
    }),
  );

export const externalLoginRedirect: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(userActions.externalLogin.success)),
    withLatestFrom(state$),
    map(() => {
      const search: string = window.location?.search ?? '';
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { authToken, apiUrl, ...restSearch } = qs.parse(search.replace(/^\?/, ''));
      const newSearchString = qs.stringify(restSearch);
      const path = window.location?.pathname ?? '/';

      return replace(newSearchString ? `${path}?${newSearchString}` : path);
    }),
  );

export const externalLoginFailureRedirect: Epic<RootAction, RootAction, RootState> = action$ =>
  action$.pipe(filter(isActionOf(userActions.externalLogin.failure)), mapTo(replace('/')));

export const getCurrentUser: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(userActions.getCurrentUser.request)),
    withLatestFrom(state$),
    switchMap(([, state]) =>
      apiClient(state)
        .getCurrentUser()
        .pipe(
          map(xhrPayload => {
            return userActions.getCurrentUser.success({ user: xhrPayload.response });
          }),
          catchError((error: Error) => {
            if (
              error instanceof AjaxError &&
              (error.status === UNAUTHORIZED_ERROR_CODE || error.status === NOT_FOUND_ERROR_CODE)
            ) {
              return of(userActions.getCurrentUser.failure({ error: new NotFound(), errorCode: ERROR_DEFAULT }));
            }

            return of(userActions.getCurrentUser.failure({ error, errorCode: getErrorCode(error) }));
          }),
        ),
    ),
  );

export const refetchAfterRefreshToken: Epic<RootAction, RootAction, RootState, RootDependencies> = action$ =>
  action$.pipe(
    filter(isActionOf(userActions.refreshToken)),
    switchMap(() => concat(of(getBuilding.request(), getBuildingTheme.request(), externalLogin.request()))),
  );
