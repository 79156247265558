import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';

import { Nullable } from 'shared/types';
import { ViewParams } from 'shared/types/view-params';
import { configSelector } from 'store/config/selectors';
import { saveExternalConfig } from 'store/config/actions';
import { useSearchParams } from 'hooks/use-search-params.hook';

export const useViewParams = (): Nullable<Partial<ViewParams>> => {
  const searchParams = useSearchParams<Partial<ViewParams>>();
  const config = useSelector(configSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (searchParams) {
      dispatch(saveExternalConfig(searchParams));
    }
  }, [dispatch, searchParams]);

  return useMemo(
    () => ({
      ...config,
      ...searchParams,
    }),
    [searchParams, config],
  );
};
