import { ACTION_STATUSES, TRACK_EVENTS, TRACK_EVENT_TYPES } from 'shared/consts';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { registerUser } from 'store/serviceRegistration/actions';
import {
  selectRegisterUserStatus,
  selectRegistrationFields,
  selectRegistrationFieldsLoadingStatus,
} from 'store/serviceRegistration/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { RegistrationField, RegistrationFields } from 'store/serviceRegistration/types';
import { currentUser } from 'store/user/selectors';
import { isFormFilled } from 'utils/isFormFilled';
import { useBooleanState } from '@hqo/react-components-library';
import { useErrorModal } from 'hooks/use-error-modal-handler.hook';
import { track } from '@hqo/web-tracking';
import { formatUserInfo } from 'utils/formatUserInfo';
import { useParams } from 'react-router-dom';
import { ProviderParams } from 'shared/types';
import { useOwnerValues } from 'hooks/use-owner-values.hook';

export interface RegisterUserData {
  onClick: VoidFunction;
  showBookingErrorModal: boolean;
  onCloseError: VoidFunction;
  isLoading: boolean;
  hasErrors: boolean;
  setRegistrationInfo: Dispatch<SetStateAction<RegistrationField[]>>;
  setTermAccepted: VoidFunction;
  isTermAccepted: boolean;
  getRegistrationFieldsLoadingStatus: ACTION_STATUSES;
  registrationFields: RegistrationFields;
}

// eslint-disable-next-line max-lines-per-function
export const useRegisterUser = (): RegisterUserData => {
  const dispatch = useDispatch();
  const { companyUuid } = useParams<ProviderParams>();
  const { ownerType, ownerUuid } = useOwnerValues();
  const getRegistrationFieldsLoadingStatus = useSelector(selectRegistrationFieldsLoadingStatus);
  const registerUserStatus = useSelector(selectRegisterUserStatus);
  const user = useSelector(currentUser);
  const registrationFields = useSelector(selectRegistrationFields);
  const [registrationInfo, setRegistrationInfo] = useState<Array<RegistrationField>>([]);
  const { value: isTermAccepted, toggle: setTermAccepted } = useBooleanState(false);
  const isFormCompleted = isFormFilled(registrationInfo, isTermAccepted || !registrationFields?.waiver_url);
  const [isButtonClicked, setIsButtonClicked] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const hasErrors = !isFormCompleted && isButtonClicked;

  const { showBookingErrorModal, onCloseError } = useErrorModal();

  const onClick = useCallback(() => {
    if (isFormCompleted) {
      setIsLoading(true);
      track(TRACK_EVENTS.REGISTRATION_SUBMITTED_CLICK, { type: TRACK_EVENT_TYPES.ACTION }, { sendToHqoTracking: true });
      dispatch(
        registerUser.request({
          ownerType,
          ownerUuid,
          companyUuid,
          registerUserDto: formatUserInfo(user, registrationInfo),
        }),
      );
    }
    setIsButtonClicked(true);
  }, [dispatch, user, registrationInfo, isFormCompleted, ownerType, ownerUuid, companyUuid]);

  useEffect(() => {
    if (registerUserStatus === ACTION_STATUSES.REJECTED) {
      setIsButtonClicked(false);
      setIsLoading(false);
    }
  }, [registerUserStatus, isButtonClicked, isFormCompleted]);

  return {
    onClick,
    showBookingErrorModal,
    onCloseError,
    isLoading,
    hasErrors,
    setRegistrationInfo,
    setTermAccepted,
    isTermAccepted,
    getRegistrationFieldsLoadingStatus,
    registrationFields,
  };
};
