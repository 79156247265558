import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetPatchTransaction, resetRedirectTransaction, resetTransaction } from 'store/transactions/actions';
import { useLocation, useParams } from 'react-router';
import { ACTION_STATUSES, PROVIDER_BASE_PATH, PROVIDER_PATHS, DEFAULT_PATH } from 'shared/consts';
import { resetCart, resetCreateCartStatus, resetGetCartStatus, resetSubmitCartStatus } from 'store/cart/actions';
import { configSelector } from 'store/config/selectors';
import { useLocale } from '../use-locale.hook';
import { replace } from 'connected-react-router';
import { selectSubmitCartStatus, shouldSubmit3DSecure } from 'store/cart/selectors';
import { selectGetTransactionStatus } from 'store/transactions/selectors';
import { useSearchParams } from '../use-search-params.hook';
import qs from 'qs';
import { formatPath } from 'utils/formatPath';
import { ProviderParams } from 'shared/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { selectInitialRoute } from 'store/routes/selectors';

interface UseReceiptScreenReturnValues {
  onCloseReceipt: VoidFunction;
  openReceipt: VoidFunction;
  onPressBackReceipt: VoidFunction;
  withLeftArrowButtonOnTheReceipt: boolean;
}

// eslint-disable-next-line max-lines-per-function
export const useReceiptScreen = (): UseReceiptScreenReturnValues => {
  const dispatch = useDispatch();
  const { companyUuid, serviceUuid } = useParams<ProviderParams>();
  const { confirmCancel, ...restQueryParams } = useSearchParams();
  const { pathname, search } = useLocation();
  const { enableServiceBookingCancellation } = useFlags();
  const locale = useLocale();
  const submit3DSecure = useSelector(shouldSubmit3DSecure);
  const { buildingUuid } = useSelector(configSelector);
  const submitCartStatus = useSelector(selectSubmitCartStatus);
  const transactionStatus = useSelector(selectGetTransactionStatus);
  const initialRoute = useSelector(selectInitialRoute);

  const hasSuccessfullyPurchased = useMemo<boolean>(
    () =>
      submitCartStatus === ACTION_STATUSES.FULFILLED &&
      transactionStatus === ACTION_STATUSES.FULFILLED &&
      !submit3DSecure,
    [submitCartStatus, transactionStatus, submit3DSecure],
  );

  const serviceProviderRoute = formatPath({
    path: PROVIDER_BASE_PATH,
    companyUuid,
  });

  const eventsPath = formatPath({
    path: PROVIDER_PATHS.EVENT_DETAILS,
    companyUuid,
    serviceUuid,
  });

  const registerContractRoute = formatPath({
    path: PROVIDER_PATHS.REGISTER_CONTRACT,
    companyUuid,
  });

  const shouldGoToThePreviousPage = useMemo<boolean>(() => {
    const formattedPathname = pathname.replace('/receipt', '');

    return (
      formattedPathname === eventsPath ||
      formattedPathname === serviceProviderRoute ||
      formattedPathname === registerContractRoute
    );
  }, [pathname, eventsPath, serviceProviderRoute, registerContractRoute]);

  const withLeftArrowButtonOnTheReceipt = Boolean(confirmCancel);

  const onCloseReceipt = useCallback(() => {
    const queryString = `?locale=${locale}&buildingUuid=${buildingUuid}`;

    if (enableServiceBookingCancellation && shouldGoToThePreviousPage) {
      dispatch(replace(`${pathname.replace('/receipt', '').replace('/register-contract', '')}${queryString}`));
    } else {
      dispatch(replace(`${initialRoute || DEFAULT_PATH}${queryString}`));
    }

    dispatch(resetTransaction());
    dispatch(resetPatchTransaction());
    dispatch(resetRedirectTransaction());
    dispatch(resetSubmitCartStatus());
    dispatch(resetCart());
  }, [
    dispatch,
    enableServiceBookingCancellation,
    locale,
    buildingUuid,
    shouldGoToThePreviousPage,
    initialRoute,
    pathname,
  ]);

  const openReceipt = useCallback(() => {
    if (pathname.includes('quick-checkout')) {
      dispatch(replace(`${pathname.replace('quick-checkout', 'receipt')}${search}`));
    } else {
      dispatch(replace(`${pathname.replace('checkout', 'receipt')}${search}`));
    }
  }, [dispatch, pathname, search]);

  const onPressBackReceipt = useCallback(() => {
    const queryString = qs.stringify({ ...restQueryParams });

    dispatch(replace(`${pathname}?${queryString}`));
  }, [dispatch, pathname, restQueryParams]);

  useEffect(() => {
    if (hasSuccessfullyPurchased) {
      openReceipt();
      dispatch(resetSubmitCartStatus());
      dispatch(resetGetCartStatus());
      dispatch(resetCreateCartStatus());
    }
  }, [dispatch, hasSuccessfullyPurchased, openReceipt]);

  return {
    onCloseReceipt,
    openReceipt,
    onPressBackReceipt,
    withLeftArrowButtonOnTheReceipt,
  };
};
