import React from 'react';
import { FlexContainer } from '../styles';
import { ScheduleBlock } from './schedule-block';

export const ServiceSchedule = (): JSX.Element => {
  return (
    <FlexContainer gap="80" isColumnDirection top="25" left={16}>
      <ScheduleBlock />
      <ScheduleBlock />
    </FlexContainer>
  );
};
