import styled from 'styled-components';
import { DIMENSIONS } from '@hqo/react-components-library/dist/utils';

export const PriceBlock = styled.div`
  font: 500 16px/18px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.greys.adminBlack};
  background-color: ${({ theme }) => theme.colors.greys.sharedGreyBackground};
  border-radius: 18px;
  padding: 5px 10px;
  margin-bottom: 10px;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    margin-bottom: 0;
  }
`;
