import styled from 'styled-components';
import { CHECKOUT_BREAK_POINT } from 'shared/consts';
import { Button, Cancel } from '@hqo/react-components-library';

export const EventContentWrapper = styled.div`
  background: ${({ theme }) => theme.colors.universal.white};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 14;
  width: 100%;
  height: 100%;

  @media screen and (max-width: ${CHECKOUT_BREAK_POINT}px) {
    width: 100vw;
    border-radius: 8px 8px 0 0;
    transform: translate(0%, 0%);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

export const CancelIcon = styled(Cancel)`
  color: ${({ theme }) => theme.colors.admin.pink};
  background-color: ${({ theme }) => theme.colors.admin.lightPink};
  border-radius: 50%;
  padding: 12px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 14px;
  gap: 16px;
`;

export const Text = styled.div`
  font: 400 16px/22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.palette.systemGreyDark};
  text-align: center;
`;

export const Title = styled.div`
  font: 600 28px/22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.palette.systemBlack};
  text-align: center;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
`;

export const StyledButton = styled(Button)`
  height: 48px;
  width: 100%;
`;
