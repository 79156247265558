import { EmptyWrapper, StyledEmptyState } from 'components/empty-services-placeholder/styles';

import React from 'react';
import { useIntl } from 'react-intl';

export const EmptyServicesPlaceholder = () => {
  const intl = useIntl();

  return (
    <EmptyWrapper data-testid="empty-services-placeholder">
      <StyledEmptyState
        descriptions={[intl.formatMessage({ id: 'no_available_time_slots' })]}
        icon="calendar-times"
        title={intl.formatMessage({ id: 'no_available_services' })}
      />
    </EmptyWrapper>
  );
};
