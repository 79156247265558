import React, { useMemo } from 'react';
import { ServiceProviderConfig } from 'store/serviceProviders/types';
import { Container, StyledIcon, StyledLink } from './styles';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useIntl } from 'react-intl';

export interface LinkoutBlockProps {
  serviceProviderConfig: ServiceProviderConfig;
}

export const LinkoutBlock = ({ serviceProviderConfig }: LinkoutBlockProps): JSX.Element => {
  const intl = useIntl();
  const { linkout_url, linkout_icon, linkout_label } = serviceProviderConfig;
  const getIconProps = useMemo(
    () => (linkout_icon ? linkout_icon?.split(' ') : ['fal', 'calendar']) as IconProp,
    [linkout_icon],
  );

  if (!linkout_url) return null;

  return (
    <Container data-testid="linkout-block-container">
      <StyledIcon icon={getIconProps} data-testid="linkout-block-icon" />
      <StyledLink target="_blank" href={linkout_url}>
        {linkout_label || intl.formatMessage({ id: 'schedule_tour_consultation' })}
      </StyledLink>
    </Container>
  );
};
