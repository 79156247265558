import React from 'react';
import { CancelIcon, EventContentWrapper, ContentContainer, Text, Title } from './styles';
import { Background, ModalContainer } from 'components/payment/quick-checkout/styles';
import { useSelector } from 'react-redux';
import { selectCurrentAvailableService } from 'store/availableServices/selectors';
import { useIntl } from 'react-intl';
import { BoldText } from 'hocs/bold-text';
import { ButtonsBlock } from './buttons-block';

interface CancelEventContentProps {
  ctaLabel: string;
}

export const CancelEventContent = ({ ctaLabel }: CancelEventContentProps) => {
  const intl = useIntl();
  const service = useSelector(selectCurrentAvailableService);
  const title = intl.formatMessage({ id: `cancel_${ctaLabel.toLowerCase()}?` });
  const subtitle = intl.formatMessage(
    { id: 'cancel_event_subtitle' },
    { eventName: <BoldText>{service.name}</BoldText> },
  );
  const shouldRenderDescription = Boolean(service?.price);

  return (
    <ModalContainer>
      <EventContentWrapper data-testid="cancel-event-content">
        <ContentContainer>
          <CancelIcon />
          <Title>{title}</Title>
          <Text>{subtitle}</Text>
          {shouldRenderDescription && <Text>{intl.formatMessage({ id: 'cancel_event_description' })}</Text>}
          <ButtonsBlock ctaLabel={ctaLabel} />
        </ContentContainer>
      </EventContentWrapper>
      <Background />
    </ModalContainer>
  );
};
