import React from 'react';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ORDER_TYPES, TRANSACTION_TYPES, TransactionDetailsServiceTypes } from 'shared/consts';
import { selectCart } from 'store/cart/selectors';
import { selectTransaction } from 'store/transactions/selectors';
import { ButtonContainer, CtaButton, SummaryContainer } from './styles';
import { PaymentMethodSection } from './payment-method-section';
import { ServiceDetailsSection } from './service-details-section';

interface SummaryProps {
  isSwipeModalContent?: boolean;
  onCloseClick?: VoidFunction;
}

export const ReceiptSummary = ({ isSwipeModalContent = false, onCloseClick }: SummaryProps) => {
  const intl = useIntl();
  const cart = useSelector(selectCart);
  const transaction = useSelector(selectTransaction);
  const { transactionUuid } = useSearchParams();

  const isServiceBookingMemberType = transactionUuid
    ? transaction?.type === TRANSACTION_TYPES.MEMBERSHIP
    : cart?.type === ORDER_TYPES.SERVICE_BOOKING_MEMBERSHIP;

  const isServiceBookingEvent = cart
    ? cart.type === ORDER_TYPES.SERVICE_BOOKING_EVENT
    : transaction?.details?.service?.type === TransactionDetailsServiceTypes.EVENT;

  return (
    <SummaryContainer data-testid="receipt-summary">
      {!isServiceBookingMemberType && <ServiceDetailsSection isSwipeModalContent={isSwipeModalContent} />}
      {!!transaction?.payment_method?.type && <PaymentMethodSection />}
      {isServiceBookingEvent && isSwipeModalContent && (
        <ButtonContainer>
          <CtaButton data-testid="footer-cta-button" text={intl.formatMessage({ id: 'done' })} onClick={onCloseClick} />
        </ButtonContainer>
      )}
    </SummaryContainer>
  );
};
