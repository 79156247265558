import qs from 'qs';
import { User } from 'store/user/types';

export const getMicroFrontendParams = (
  user: User,
  apiUrl: string,
  authToken: string,
  buildingUuid: string,
  cartId: string,
  locale?: string,
  forPayment?: boolean,
  error?: boolean,
  paymentId?: string,
) => {
  const miniAppProps = {
    cartId,
    buildingUuid,
    authToken: `${authToken}`,
    firstName: user.first_name,
    lastName: user.last_name,
    userId: user.id,
    userUuid: user.uuid,
    apiUrl,
    locale,
    forPayment,
    error,
    paymentId,
  };

  return qs.stringify(miniAppProps);
};
