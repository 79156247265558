import { ModalContainer } from './styles';
import { Spinner } from '@hqo/react-components-library/dist/atoms/spinner';
import { defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';

import { ACTION_STATUSES } from 'shared/consts/consts';
import { Container } from 'hocs/shared-styles';
import { SinglePaneWrapper } from 'components/single-pane';
import React from 'react';

interface SinglePaneModalProps {
  contentStatus: ACTION_STATUSES;
  Button: React.ReactNode;
  PaneContent: React.ReactNode;
}

export const SinglePaneModal = ({ contentStatus, Button, PaneContent }: SinglePaneModalProps) => {
  return (
    <ModalContainer>
      {contentStatus === ACTION_STATUSES.PENDING ? (
        <Container isLoading>
          <Spinner color={defaultTheme.colors.$greyLight} size="10em" />
        </Container>
      ) : (
        contentStatus === ACTION_STATUSES.FULFILLED && (
          <SinglePaneWrapper Content={PaneContent} isButtonVisible Button={Button} />
        )
      )}
    </ModalContainer>
  );
};
