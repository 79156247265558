import { StyledModalWrapper, ModalContainer } from './styles';
import React from 'react';
import { QuickCheckoutContent } from './quick-checkout-content';
import { QuickCheckoutProps } from './types';

export const QuickCheckout = ({ closeQuickCheckoutHandler, reverseAnimation }: QuickCheckoutProps) => {
  const buildContent = (): JSX.Element => (
    <ModalContainer>
      <QuickCheckoutContent closeQuickCheckoutHandler={closeQuickCheckoutHandler} />
    </ModalContainer>
  );

  return <StyledModalWrapper reverseAnimation={reverseAnimation} content={buildContent()} visible />;
};
