import { NOT_FOUND_ERROR_CODE } from 'shared/consts/errors';

export class NotFound extends Error {
  status: number;

  constructor(message?: string) {
    super(message);
    this.status = NOT_FOUND_ERROR_CODE;
    this.name = 'User not found';
    Object.setPrototypeOf(this, NotFound.prototype);
  }
}
