import React, { useEffect } from 'react';
import { Router } from 'components/router';
import { useExternalLogin } from 'hooks/use-external-login.hook';
import { Spinner } from '@hqo/react-components-library/dist/atoms/spinner';
import { defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';
import { Container } from 'hocs/shared-styles';
import { useViewParams } from 'hooks/use-view-params.hook';
import { usePendo } from 'hooks/use-pendo.hook';
import { useMiniappSdk, useMiniappSdkEventHandler } from 'hooks/use-miniapp-sdk.hook';
import { useDispatch, useSelector } from 'react-redux';
import { getBuilding } from 'store/building/actions';
import { Building } from 'store/building/types';
import { InitHandlerPayload } from '@hqo/hqo-miniapp-client-sdk';
import { getBuildingTheme } from 'store/theme/actions';
import { BuildingTheme } from 'store/theme/types';
import { externalLogin, getCurrentUser } from 'store/user/actions';
import { User } from 'store/user/types';
import { USER_AGENT } from 'shared/consts';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { setFeatureFlags } from 'store/featureFlags/actions';
import { updateInitialRoute } from 'store/routes/actions';
import { useInitialRoute } from 'hooks/use-initial-route.hook';
import { selectInitialRoute } from 'store/routes/selectors';
import { getPathnameFromURL } from 'utils/getPathnameFromURL';

export const App: React.FC = (): JSX.Element => {
  const shouldWaitForLogin = useExternalLogin();
  const dispatch = useDispatch();
  const defaultRoute = useInitialRoute();
  const initialRoute = useSelector(selectInitialRoute);
  useViewParams();
  usePendo();
  const client = useMiniappSdk();
  const isDesktopApp =
    navigator.userAgent.toLowerCase().includes(USER_AGENT.CHROME) ||
    navigator.userAgent.toLowerCase().includes(USER_AGENT.SAFARI);
  const featureFlags = useFlags();

  useEffect(() => {
    dispatch(setFeatureFlags(featureFlags));
  }, [featureFlags]);

  useMiniappSdkEventHandler(
    'initResponse',
    (data: InitHandlerPayload) => {
      if (!initialRoute) {
        dispatch(updateInitialRoute(getPathnameFromURL(data?.initial_route) || defaultRoute));
      }
      if (isDesktopApp) {
        dispatch(
          externalLogin.success({
            token: `Bearer ${data.jwt_token}`,
            user: data.user as unknown as User,
            apiUrl: null,
          }),
        );
        dispatch(getCurrentUser.success({ user: data?.user as unknown as User }));
        dispatch(getBuilding.success(data?.building as unknown as Building));
        dispatch(getBuildingTheme.success(data?.theme as unknown as BuildingTheme));
      }
    },
    [dispatch, initialRoute, defaultRoute],
  );

  useEffect(() => {
    client?.header.setHeader('');
    client?.header.showHeader();
  }, [client]);

  if (shouldWaitForLogin) {
    return (
      <Container isLoading>
        <Spinner size="10em" color={defaultTheme.colors.$greyLight} />
      </Container>
    );
  }

  return <Router />;
};
