import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { selectCurrentServiceProvider, selectCurrentServiceProviderDetailView } from 'store/serviceProviders/selectors';
import {
  Content,
  DescriptionContainer,
  HeaderWrapper,
  MainContainer,
  ClientName,
  ContactUsButtonContainer,
  BackLinkWrapper,
} from './styles';
import { QRCode } from 'pages/gym-card/components/qrcode';
import { useBackButtonVisibility } from 'hooks/use-back-button-visibility.hook';
import { ButtonBlock } from '@hqo/react-components-library/dist/molecules/button-block';
import { NavBackLink } from '@hqo/react-components-library/dist/molecules/navBackLink';
import { GymCardTitle } from 'pages/gym-card/components/gym-card-title';
import { ClientDetails } from 'pages/gym-card/components/client-details';
import { MembershipStatusEnum } from 'store/serviceMemberships/types';
import { useGymCardPage } from 'hooks/use-gym-card-page.hook';

export const GymCardPage = (): JSX.Element => {
  const { handleContactUsOnClick, onPressBack } = useGymCardPage();
  const intl = useIntl();
  const providerDetails = useSelector(selectCurrentServiceProviderDetailView);
  const currentServiceProvider = useSelector(selectCurrentServiceProvider);
  const isBackButtonVisible = useBackButtonVisibility();

  const memberships = currentServiceProvider?.user_memberships
    .filter(membership => membership.status === MembershipStatusEnum.ACTIVE)
    .map(membership => membership.name);

  return (
    <Content>
      <HeaderWrapper data-testid="gym-card-header">
        {isBackButtonVisible && (
          <BackLinkWrapper>
            <NavBackLink
              dataTestId="gym-card-back-link"
              onClick={onPressBack}
              backLinkText={intl.formatMessage({ id: 'back' })}
            />
          </BackLinkWrapper>
        )}
        <GymCardTitle />
      </HeaderWrapper>
      <MainContainer data-testid="qr-main">
        <QRCode clientId={currentServiceProvider?.client_id} />
      </MainContainer>
      <DescriptionContainer data-testid="member-details">
        <ClientName data-testid="client-name">{currentServiceProvider?.client_name}</ClientName>
        <ClientDetails clientId={currentServiceProvider?.client_id} userMemberships={memberships} />
        {providerDetails.managementEmail && (
          <ContactUsButtonContainer data-testid="contact-us">
            <ButtonBlock
              text={intl.formatMessage({ id: 'having_trouble' })}
              buttonLabel={intl.formatMessage({ id: 'contact_us' })}
              onClick={handleContactUsOnClick}
            />
          </ContactUsButtonContainer>
        )}
      </DescriptionContainer>
    </Content>
  );
};
