import React from 'react';
import { Container, Title, Description, BoldText, Content } from './styles';
import { useIntl } from 'react-intl';
import { CancelButtonBlock } from './components/cancel-button-block';
import { useCancelBooking } from './hooks/use-cancel-booking.hook';

export const CancelBookingContent = () => {
  const intl = useIntl();
  const { isEventPaid, vendorName, onCancelButtonClick, ctaLabel } = useCancelBooking();
  const title = intl.formatMessage({ id: `cancel_${ctaLabel.toLowerCase()}?` });
  const description = intl.formatMessage(
    { id: 'cancel_booking_note' },
    { eventName: <BoldText>{vendorName}</BoldText> },
  );

  return (
    <Container data-testid="cancel-booking-content">
      <Content>
        <Title>{title}</Title>
        <Description>{description}</Description>
        {isEventPaid && <Description>{intl.formatMessage({ id: 'refund_note' })}</Description>}
      </Content>
      <CancelButtonBlock onCancel={onCancelButtonClick} ctaLabel={ctaLabel} />
    </Container>
  );
};
