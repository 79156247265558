import { ACTION_STATUSES } from 'shared/consts';
import { Container, StyledPaymentMethodsManager } from './styles';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { AddCard } from '../add-card';
import { usePaymentLabels } from './hooks/use-payment-labels.hook';
import { usePaymentMethodsManager } from './hooks/use-payment-methods-manager.hook';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { PaymentMethodsProps } from './types';

export const PaymentMethods = ({
  formTitle,
  paymentMethods,
  selectedPaymentMethodId,
  onRemovePaymentMethod,
  onAddNewCard,
  submittedPaymentMethodId,
  currentPaymentError,
  cartId,
  total,
  isSwipeModalContent = false,
  showAddCardForm,
  cart,
  onCancelAddCardClick,
}: PaymentMethodsProps) => {
  const intl = useIntl();
  const [errorPaymentId, setErrorPaymentId] = useState<number>(undefined);
  const { showResourceBookingAddOns } = useFlags();
  const { buildSavedPaymentLabels } = usePaymentLabels({
    paymentMethods,
    errorPaymentId,
    total,
  });
  const {
    onPressHandler,
    updatePaymentMethodStatus,
    savePaymentMethodStatus,
    getPaymentMethodStatus,
    deletePaymentMethodsStatus,
  } = usePaymentMethodsManager({
    setErrorPaymentId,
    submittedPaymentMethodId,
    currentPaymentError,
    cartId,
  });
  const isRadioFormDisabled = useMemo<boolean>(
    () =>
      (updatePaymentMethodStatus && updatePaymentMethodStatus !== ACTION_STATUSES.FULFILLED) ||
      getPaymentMethodStatus === ACTION_STATUSES.PENDING ||
      deletePaymentMethodsStatus === ACTION_STATUSES.PENDING ||
      savePaymentMethodStatus === ACTION_STATUSES.PENDING ||
      showAddCardForm,
    [
      updatePaymentMethodStatus,
      getPaymentMethodStatus,
      deletePaymentMethodsStatus,
      savePaymentMethodStatus,
      showAddCardForm,
    ],
  );
  const labels = useMemo(
    () => ({
      title: formTitle,
      invoiceText: intl.formatMessage({ id: 'invoice_text' }),
      addCardButtonText: intl.formatMessage({
        id: 'add_new_card',
      }),
    }),
    [intl, formTitle],
  );

  return (
    <Container
      showResourceBookingAddOns={showResourceBookingAddOns}
      data-testid="edit-payment"
      isSwipeModalContent={isSwipeModalContent}
    >
      <StyledPaymentMethodsManager
        paymentMethods={paymentMethods}
        onOptionClick={onPressHandler}
        onRemovePaymentMethod={onRemovePaymentMethod}
        selectedPaymentMethodId={selectedPaymentMethodId}
        onAddNewCard={onAddNewCard}
        options={buildSavedPaymentLabels()}
        isRadioFormDisabled={isRadioFormDisabled}
        labels={labels}
        isAddCardButtonDisabled={false}
      />
      {showAddCardForm && (
        <AddCard
          onCancel={onCancelAddCardClick}
          spreedlyEnvKey={cart.ui_enablements.spreedly_env_token}
          cartId={cartId}
        />
      )}
    </Container>
  );
};
