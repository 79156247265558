import React from 'react';
import { Spinner } from '@hqo/react-components-library/dist/atoms/spinner';
import { defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';
import { Container } from 'hocs/shared-styles';

interface Props {
  isModal?: boolean;
}

export const DefaultLoader = ({ isModal = false }: Props) => {
  return (
    <Container isLoading isModal={isModal}>
      <Spinner color={defaultTheme.colors.$greyLight} size="10em" />
    </Container>
  );
};
