import { IntlShape } from 'react-intl';
import { formatCurrency } from 'utils/formatCurrency';

interface PriceOptions {
  currencyType: string;
  buildingLocale: string;
}

export const formatItemPrice = (price: number, { currencyType, buildingLocale }: PriceOptions, intl: IntlShape) => {
  if (!price) {
    return intl.formatMessage({ id: 'free' });
  }

  return formatCurrency(price / 100, currencyType, buildingLocale, true, false);
};
