import styled from 'styled-components';

export const PriceBlockContainer = styled.div`
  width: auto;
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const Title = styled.div`
  font: 400 14px/16.41px ${({ theme }) => theme.fonts.join()};
`;

export const Price = styled.div`
  font: 400 14px/18.75px ${({ theme }) => theme.fonts.join()};
`;
