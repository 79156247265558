export const ROOT_PATH = '/providers' as const;

export const ERROR_PATH = '/error' as const;

export const PROVIDER_BASE_PATH = `${ROOT_PATH}/:companyUuid`;
export const RECEIPT_MODAL_PATH = `${ROOT_PATH}/receipt`;
export const CHECKOUT_MODAL_PATH = `${ROOT_PATH}/checkout`;

export const API_PATHS = {
  SERVICE_BOOKING: '/service-booking/v2',
  SERVICE_BOOKING_V3: '/service-booking/v3',
  USER: '/auth/current',
};

export const PROVIDER_PATHS = {
  SCHEDULE: `${PROVIDER_BASE_PATH}/schedule/:scheduleType`,
  SERVICE_TYPE: `${PROVIDER_BASE_PATH}/schedule/service-types/:serviceTypeId`,
  AVAILABILITY: `${PROVIDER_BASE_PATH}/schedule/availability`,
  REGISTER_CONTRACT: `${PROVIDER_BASE_PATH}/register-contract`,
  GYM_CARD: `${PROVIDER_BASE_PATH}/gymcard`,
  CLASS_DETAILS: `${PROVIDER_BASE_PATH}/class-details/:classId`,
  EVENT_DETAILS: `${PROVIDER_BASE_PATH}/events/:serviceUuid`,
};

export const CHECKOUT_PATH = '/checkout';
export const QUICK_CHECKOUT_PATH = '/quick-checkout';
export const RECEIPT_PATH = '/receipt';
export const DEFAULT_PATH = '/';
export const REDIRECT_PATH = '/redirect';
export const SERVICE_BOOKING_PATH = '/service-booking';
