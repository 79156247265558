import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { hideErrorNotifications } from 'store/errors/actions';
import { selectErrorNotifications } from 'store/errors/selectors';
import { useBooleanState } from '@hqo/react-components-library';

export const useErrorModal = (
  toggleBookingConfirmationModal?: VoidFunction,
): { showBookingErrorModal: boolean; onCloseError: VoidFunction } => {
  const dispatch = useDispatch();

  const hasErrorNotifications = useSelector(selectErrorNotifications);

  const { value: showBookingErrorModal, toggle: toggleBookingErrorModal } = useBooleanState(false);

  useEffect(() => {
    if (hasErrorNotifications) {
      if (toggleBookingConfirmationModal) {
        toggleBookingConfirmationModal();
      }
      toggleBookingErrorModal();
    }
  }, [hasErrorNotifications]);

  const onCloseError = useCallback(() => {
    toggleBookingErrorModal();
    dispatch(hideErrorNotifications());
  }, []);
  return { showBookingErrorModal, onCloseError };
};
