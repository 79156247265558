import React from 'react';
import {
  DetailsText,
  CloseIcon,
  DetailsContainer,
  Title,
  MembershipName,
  DetailsHeader,
  RightPane,
  LeftPane,
  DetailsContent,
  TermsContent,
  TermsTitle,
  TermsText,
} from './styles';
import { useIntl } from 'react-intl';

export interface DetailsProps {
  description: string;
  agreementTerms: string;
  onClick: VoidFunction;
  name: string;
  isSelected: boolean;
}

export const MembershipDetails = ({ description, onClick, name, isSelected, agreementTerms }: DetailsProps) => {
  const intl = useIntl();
  return (
    <DetailsContainer isSelected={isSelected}>
      <DetailsHeader>
        <LeftPane>
          <Title>{intl.formatMessage({ id: 'membership_details' })}</Title>
          <MembershipName>{name}</MembershipName>
        </LeftPane>
        <RightPane data-testid="exit-button" onClick={onClick}>
          <CloseIcon icon={['fas', 'times']} />
        </RightPane>
      </DetailsHeader>
      <DetailsContent>
        <DetailsText dangerouslySetInnerHTML={{ __html: description }} />
      </DetailsContent>
      <TermsContent>
        <TermsTitle>{intl.formatMessage({ id: 'terms_and_conditions' })}</TermsTitle>
        <TermsText dangerouslySetInnerHTML={{ __html: agreementTerms }} />
      </TermsContent>
    </DetailsContainer>
  );
};
