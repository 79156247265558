import { BookingStatus, ORDER_TYPES, TRANSACTION_TYPES } from 'shared/consts';
import {
  CancelButton,
  CancelButtonContainer,
  OverviewContainer,
  StyledStatusIconTitle,
  Subtitle,
  TitleContainer,
} from './styles';
import { LabelBlock } from '@hqo/react-components-library/dist/molecules/label-block/labelBlock';
import { IconContentProps } from '@hqo/react-components-library/dist/icons';
import React from 'react';
import { selectCart } from 'store/cart/selectors';
import { selectTransaction, selectTransactionDisplay } from 'store/transactions/selectors';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { selectCurrentAvailableService } from 'store/availableServices/selectors';
import { useReceipt } from 'components/payment/receipt/use-receipt.hook';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useIcon } from './hooks/use-icon.hook';
import { useTransactionOverview } from './hooks/use-transaction-overview.hook';
import { useCartOverview } from './hooks/use-cart-overview.hook';

interface ReceiptOverviewProps {
  isSwipeModalContent?: boolean;
}

export const ReceiptOverview = ({ isSwipeModalContent = false }: ReceiptOverviewProps) => {
  const intl = useIntl();
  const { handleCancelClick } = useReceipt();
  const cart = useSelector(selectCart);
  const { transactionUuid } = useSearchParams();
  const transactionDisplay = useSelector(selectTransactionDisplay);
  const transaction = useSelector(selectTransaction);
  const isTransactionCancelled = transaction?.details?.status === BookingStatus.CANCELLED;
  const service = useSelector(selectCurrentAvailableService);
  const { enableServiceBookingCancellation } = useFlags();

  const { icon, hideIcon } = useIcon();

  const useTransactionObject = Boolean(transactionUuid) || isTransactionCancelled;

  const { title: titleFromTransaction, subtitle: subtitleFromTransaction } = useTransactionOverview();
  const { title: titleFromCart, subtitle: subtitleFromCart } = useCartOverview();

  const title = useTransactionObject ? titleFromTransaction : titleFromCart;
  const subtitle = useTransactionObject ? subtitleFromTransaction : subtitleFromCart;

  const isMembershipBooking =
    transaction?.type === TRANSACTION_TYPES.MEMBERSHIP || cart?.type === ORDER_TYPES.SERVICE_BOOKING_MEMBERSHIP;

  const canCancelEventBooking = enableServiceBookingCancellation && !isTransactionCancelled && !isMembershipBooking;

  return (
    <OverviewContainer isSwipeModalContent={isSwipeModalContent} data-testid="receipt-overview">
      <TitleContainer>
        <StyledStatusIconTitle
          title={intl.formatMessage({
            id: title,
          })}
          icon={icon as unknown as React.FC<IconContentProps>}
          hideIcon={hideIcon}
        />
      </TitleContainer>
      <Subtitle>{subtitle}</Subtitle>
      {canCancelEventBooking && (
        <CancelButtonContainer>
          <CancelButton
            data-testid="cancel-button"
            variant="Outline"
            text={
              service?.cta_label
                ? intl.formatMessage({ id: `cancel_${service.cta_label.toLowerCase()}` })
                : intl.formatMessage({ id: 'cancel_book' })
            }
            onClick={handleCancelClick}
          />
        </CancelButtonContainer>
      )}
      {isMembershipBooking && (
        <LabelBlock
          title={intl.formatMessage({ id: 'your_plan' })}
          subtitle={
            useTransactionObject ? transactionDisplay.items[0].display_info.title : cart?.items[0].display_info.title
          }
        />
      )}
    </OverviewContainer>
  );
};
