import React, { useEffect, useMemo } from 'react';
import {
  selectGetRedirectTransactionStatus,
  selectGetTransactionStatus,
  selectPatchTransactionStatus,
  selectRedirectTransaction,
  selectTransaction,
} from 'store/transactions/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { ACTION_STATUSES, PATCH_STATUSES_3DS, searchParams } from 'shared/consts';
import {
  getRedirectTransaction,
  getTransaction,
  patchTransaction,
  resetPatchTransaction,
  resetRedirectTransaction,
} from 'store/transactions/actions';
import { useLocation } from 'react-router-dom';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { usePaymentMethods } from 'hooks/use-payment-methods.hook';
import { useCart } from 'hooks/use-cart.hook';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Skeleton } from './components/skeleton';
import { replace } from 'connected-react-router';
import { configSelector } from 'store/config/selectors';
import { useLocale } from 'hooks/use-locale.hook';
import { updateInitialRoute } from 'store/routes/actions';
import qs from 'qs';

export const RedirectPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { buildingUuid } = useSelector(configSelector);
  const locale = useLocale();
  const { cartId, transaction_token, initialRoute, redirectRoute } = useSearchParams<searchParams>();
  const { isPaymentMethodsFullfiled } = usePaymentMethods(cartId as string);
  const { isCartFulfilled, cart } = useCart(cartId as string);
  const getRedirectTransactionStatus = useSelector(selectGetRedirectTransactionStatus);
  const redirectTransaction = useSelector(selectRedirectTransaction);
  const { showMiniappPaymentsNavigation } = useFlags();
  const transactionStatus = useSelector(selectGetTransactionStatus);
  const transaction = useSelector(selectTransaction);
  const patchTransactionStatus = useSelector(selectPatchTransactionStatus);
  const route = useMemo<string>(
    () => `${initialRoute}/receipt?locale=${locale}&buildingUuid=${buildingUuid}`,
    [initialRoute, locale, buildingUuid],
  );
  const checkoutErrorRedirectRoute = useMemo<string>(() => {
    const queryString = qs.stringify({
      locale,
      buildingUuid,
      error: true,
      isOpenFromRedirect: true,
    });

    return redirectRoute?.includes('availability') || redirectRoute?.includes('checkout')
      ? `${initialRoute}/checkout?${queryString}`
      : `${redirectRoute}/checkout?${queryString}`;
  }, [redirectRoute, locale, buildingUuid]);

  useEffect(() => {
    dispatch(updateInitialRoute(initialRoute as string));
  }, [initialRoute, dispatch]);

  useEffect(() => {
    if (transaction_token) {
      dispatch(getRedirectTransaction.request(transaction_token as string));
    }
  }, [dispatch, transaction_token]);

  useEffect(() => {
    if (showMiniappPaymentsNavigation && cart?.transaction_uuid) {
      dispatch(getTransaction.request(cart.transaction_uuid));
    }
  }, [cart?.transaction_uuid, dispatch, showMiniappPaymentsNavigation]);

  useEffect(() => {
    if (patchTransactionStatus === ACTION_STATUSES.FULFILLED) {
      dispatch(replace(checkoutErrorRedirectRoute));
      dispatch(resetRedirectTransaction());
      dispatch(resetPatchTransaction());
    }
  }, [initialRoute, locale, buildingUuid, patchTransactionStatus]);

  useEffect(() => {
    if (
      getRedirectTransactionStatus === ACTION_STATUSES.FULFILLED &&
      isPaymentMethodsFullfiled &&
      isCartFulfilled &&
      cart?.transaction_uuid &&
      transactionStatus === ACTION_STATUSES.FULFILLED
    ) {
      if (redirectTransaction?.succeeded) {
        if (showMiniappPaymentsNavigation && transactionStatus === ACTION_STATUSES.FULFILLED) {
          dispatch(
            patchTransaction.request({
              transactionId: transaction?.id.toString(),
              status_3ds: PATCH_STATUSES_3DS.SUCCEEDED,
              cartId: cartId as string,
            }),
          );
          dispatch(replace(route));
        }
      } else {
        dispatch(
          patchTransaction.request({
            transactionId: transaction?.id.toString(),
            status_3ds: PATCH_STATUSES_3DS.FAILED,
            cartId: cartId as string,
          }),
        );
      }
    }
  }, [
    transactionStatus,
    transaction,
    cart?.transaction_uuid,
    cart?.transaction_id,
    dispatch,
    getRedirectTransactionStatus,
    isCartFulfilled,
    isPaymentMethodsFullfiled,
    location.search,
    redirectTransaction,
    showMiniappPaymentsNavigation,
    route,
  ]);

  return <Skeleton />;
};
