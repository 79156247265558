import * as availableServicesEpics from 'store/availableServices/epics';
import * as buildingEpics from 'store/building/epics';
import * as cartEpics from 'store/cart/epics';
import * as errorsEpics from 'store/errors/epics';
import * as serviceMembershipsEpics from 'store/serviceMemberships/epics';
import * as serviceProvidersEpics from 'store/serviceProviders/epics';
import * as serviceRegistrationEpics from 'store/serviceRegistration/epics';
import * as themeEpics from 'store/theme/epics';
import * as transactionEpics from 'store/transactions/epics';
import * as userEpics from 'store/user/epics';
import * as addCardEpics from 'store/add-card/epics';
import * as paymentEpics from 'store/payment/epics';

import { combineEpics } from 'redux-observable';

export default combineEpics(
  ...Object.values(userEpics),
  ...Object.values(themeEpics),
  ...Object.values(buildingEpics),
  ...Object.values(serviceProvidersEpics),
  ...Object.values(errorsEpics),
  ...Object.values(serviceRegistrationEpics),
  ...Object.values(serviceMembershipsEpics),
  ...Object.values(transactionEpics),
  ...Object.values(availableServicesEpics),
  ...Object.values(cartEpics),
  ...Object.values(addCardEpics),
  ...Object.values(paymentEpics),
);
