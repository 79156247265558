import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';
import { useMiniappSdk } from 'hooks/use-miniapp-sdk.hook';
import { Receipt } from 'components/payment/receipt';
import { useReceiptScreen } from 'hooks/payment-content/use-receipt-screen.hook';
import { PaymentModalHandler } from 'components/payment-modal-handler';

export const ReceiptModal = (): JSX.Element => {
  const isMobileDevice = useIsSmallViewportWidth();
  const client = useMiniappSdk();
  const { pathname } = useLocation();
  const { onCloseReceipt } = useReceiptScreen();
  const hideHeader = useMemo<boolean>(
    () => (pathname.includes('checkout') || pathname.includes('receipt')) && isMobileDevice,
    [pathname, isMobileDevice],
  );

  useEffect(() => {
    if (hideHeader) {
      client?.header.hideHeader();
      client?.swipe.disableBackSwipe();
    }

    return () => {
      client?.header.showHeader();
      client?.swipe.enableBackSwipe();
    };
  }, [client, isMobileDevice, hideHeader]);

  return <PaymentModalHandler content={<Receipt onCloseReceipt={onCloseReceipt} />} onClose={onCloseReceipt} />;
};
