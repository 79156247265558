import React from 'react';
import { ButtonContainer, StyledButton } from './styles';
import { ButtonProps } from './types';

export const Button = ({
  isDisabled,
  onClick,
  text,
  isLoading,
  isPaymentMethodsAvailable,
  isFreeCart,
}: ButtonProps): JSX.Element => {
  return (
    <ButtonContainer isNext={!isPaymentMethodsAvailable} isFreeCart={isFreeCart}>
      <StyledButton
        data-testid="checkout-button"
        text={text}
        loading={isLoading}
        disabled={isDisabled}
        onClick={onClick}
      />
    </ButtonContainer>
  );
};
