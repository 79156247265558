import React from 'react';

import { Container, StyledText, StyledDot } from './styles';

interface DateTimeProps {
  formattedDate: string;
  formattedTime: string;
}
export const DateTime = ({ formattedDate, formattedTime }: DateTimeProps): JSX.Element => {
  return (
    <Container>
      <StyledText data-testid="selected-date-text">{formattedDate}</StyledText>
      <StyledDot />
      <StyledText data-testid="selected-time-text">{formattedTime}</StyledText>
    </Container>
  );
};
