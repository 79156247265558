import { useSearchParams } from 'hooks/use-search-params.hook';
import { useSelector } from 'react-redux';
import { selectTransaction } from 'store/transactions/selectors';
import { selectCart } from 'store/cart/selectors';
import { useLocale } from 'hooks/use-locale.hook';
import { selectBuildingTimeZone } from 'store/building/selectors';
import { formatDate } from 'utils/formatDate';

interface UseBookingDetailsReturnValues {
  eventWeekDayLong: string;
  eventDateMonthLong: string;
  eventDateDay: string;
}

export const useBookingDetails = (): UseBookingDetailsReturnValues => {
  const { transactionUuid } = useSearchParams();
  const transaction = useSelector(selectTransaction);
  const cart = useSelector(selectCart);
  const locale = useLocale();
  const buildingTimeZone = useSelector(selectBuildingTimeZone);
  const bookingStart = transactionUuid ? transaction?.details?.start_at : cart?.items[0]?.service_booking?.start_at;

  const { eventWeekDayLong, eventDateMonthLong, eventDateDay } = formatDate(
    bookingStart,
    locale as string,
    buildingTimeZone,
  );

  return {
    eventWeekDayLong,
    eventDateMonthLong,
    eventDateDay,
  };
};
