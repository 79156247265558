import { Button } from '@hqo/react-components-library';
import { DIMENSIONS } from '@hqo/react-components-library/dist/utils';
import { EVENT_DETAILS_SECTIONS_Z_INDEXES } from 'pages/event-details/constants';
import styled from 'styled-components';

export const CtaButton = styled(Button)`
  width: 196px;
  height: 48px;
  padding: 16px;
  -webkit-tap-highlight-color: transparent;
  align-self: flex-end;

  @media only screen {
    width: 100%;
    font-size: 16px;
    display: flex;
    text-align: center;
    align-items: center;
    padding: 0;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 700px;
  margin: 16px;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    align-items: center;
    flex-direction: column;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  bottom: 0;
  width: 100%;
  height: auto;
  background-color: white;
  box-shadow: ${({ theme }) => `0px 2px 4px ${theme.colors.greys.adminBlack}33`};
`;

export const FooterContainer = styled.div`
  width: 100%;
  position: fixed;
  margin: 0 auto;
  right: 0;
  bottom: 0;
  z-index: ${EVENT_DETAILS_SECTIONS_Z_INDEXES.HEADER_AND_FOOTER};
`;

export const DateTimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    flex-direction: column;
  }

  @media only screen and (max-width: ${DIMENSIONS.LARGE}) {
    align-items: center;
  }
`;

export const RegistrationTextContainer = styled.div`
  font: italic 16px/24px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.palette.systemGreyDark};
  margin-top: 6px;
  margin-right: auto;
`;
