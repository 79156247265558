import { DEFAULT_MODAL_HEIGHT, DEFAULT_MODAL_PADDINGS, DEFAULT_MODAL_WIDTH } from 'shared/consts';
import { ModalWrapper } from '@hqo/react-components-library/dist/molecules/modals/wrapper';
import { ErrorNotification } from '@hqo/react-components-library/dist/molecules/errorNotification';

import React from 'react';
import { Wrapper } from './styles';
import { useIntl } from 'react-intl';

interface BookingErrorModalProps {
  isVisible?: boolean;
  onClose: VoidFunction;
  onButtonClick: VoidFunction;
}

export const BookingErrorModal = ({ isVisible = true, onClose, onButtonClick }: BookingErrorModalProps) => {
  const intl = useIntl();

  return (
    <ModalWrapper
      visible={isVisible}
      onClose={onClose}
      withExitButton
      content={
        <Wrapper width={DEFAULT_MODAL_WIDTH} height={DEFAULT_MODAL_HEIGHT} paddings={DEFAULT_MODAL_PADDINGS}>
          <ErrorNotification
            title={intl.formatMessage({ id: 'unable_to_complete' })}
            description={intl.formatMessage({ id: 'please_try_again_later' })}
            buttonText={intl.formatMessage({ id: 'okay' })}
            onClick={onButtonClick}
          />
        </Wrapper>
      }
    />
  );
};
