import { useSelector } from 'react-redux';
import { selectBuilding, selectBuildingTimeZone } from 'store/building/selectors';
import { getCurrentCurrency } from 'utils/getCurrentCurrency';
import { selectCurrentAvailableService } from 'store/availableServices/selectors';
import moment from 'moment-timezone';
import { useLocale } from 'hooks/use-locale.hook';
import { formatDate } from 'utils';

interface UseSelectedDateTimeFilterReturnValues {
  locale: string;
  price?: number;
  currency?: string;
  formattedDate: string;
  formattedTime: string;
}

export const useSelectedDateTime = (): UseSelectedDateTimeFilterReturnValues => {
  const locale = useLocale();
  const timeZone = useSelector(selectBuildingTimeZone);
  const service = useSelector(selectCurrentAvailableService);
  const building = useSelector(selectBuilding);
  const currency = getCurrentCurrency(service, building);
  const startAt = moment(service?.start_at).tz(timeZone);
  const endAt = moment(service?.end_at).tz(timeZone);
  const formattedStartTime = service ? startAt.clone().format('h:mm') : '';
  const formattedStartAmPm = service ? startAt.clone().format('a') : '';
  const formattedEndTime = service ? endAt.clone().format('h:mm') : '';
  const formattedEndAmPm = service ? endAt.clone().format('a') : '';

  const { eventWeekDayShort, eventDateMonth, eventDateDay, eventDateYear } = formatDate(
    service?.start_at,
    locale,
    timeZone,
  );

  return {
    locale,
    currency,
    price: service?.price,
    formattedDate: `${eventWeekDayShort}, ${eventDateMonth} ${eventDateDay}, ${eventDateYear}`,
    formattedTime: `${formattedStartTime}${
      formattedStartAmPm !== formattedEndAmPm ? formattedStartAmPm : ''
    } - ${formattedEndTime}${formattedEndAmPm}`,
  };
};
