import React, { useCallback } from 'react';

import { ErrorNotification } from '@hqo/react-components-library/dist/molecules/errorNotification';
import { ErrorWrapper } from './styles';
import { goBack } from 'connected-react-router';
import { hideGenericErrorNotifications } from 'store/errors/actions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useIntl } from 'react-intl';

interface ErrorPageProps {
  onClick?: VoidFunction;
}

export const ErrorPage = ({ onClick }: ErrorPageProps): JSX.Element => {
  const { action } = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();

  const handleClick = useCallback(() => {
    if (action !== 'POP') {
      dispatch(goBack());
      dispatch(hideGenericErrorNotifications());
    }
  }, [action, dispatch]);

  return (
    <ErrorWrapper>
      <ErrorNotification
        title={intl.formatMessage({ id: 'something_went_wrong' })}
        description={intl.formatMessage({ id: 'unexpected_error_occurred' })}
        buttonText={intl.formatMessage({ id: 'go_back' })}
        onClick={onClick || handleClick}
      />
    </ErrorWrapper>
  );
};
