import { formatInitialPath } from 'utils/formatPath';

export const getPathnameFromURL = (url: string): string | null => {
  if (!url) {
    return null;
  }
  const location = new URL(url);

  return formatInitialPath(location.pathname);
};
