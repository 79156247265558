import { RegistrationTextContainer } from 'components/footer/styles';
import React from 'react';
import { useIntl } from 'react-intl';
import { formatRegistrationDateTime } from 'utils/dateFormatting';

interface RegistrationInterface {
  bookableStart: string;
}

export const Registration = ({ bookableStart }: RegistrationInterface): JSX.Element => {
  const intl = useIntl();
  const { date: registrationOpenDate, time: registrationOpenTime } = formatRegistrationDateTime(bookableStart);

  return (
    <RegistrationTextContainer>
      {intl.formatMessage(
        { id: 'registration_opens' },
        {
          registrationOpenDate,
          registrationOpenTime,
        },
      )}
    </RegistrationTextContainer>
  );
};
