import {
  CHECKOUT_MODAL_PATH,
  ERROR_PATH,
  PROVIDER_BASE_PATH,
  PROVIDER_PATHS,
  RECEIPT_MODAL_PATH,
  REDIRECT_PATH,
  ROOT_PATH,
} from 'shared/consts';
import { Route, Switch } from 'react-router-dom';

import { AppContentWrapper } from 'components/app-content-wrapper/app-content-wrapper';
import { DefaultRedirect } from './default-redirect';
import { ErrorPage } from 'pages/error';
import { PrivateRoute } from 'components/private-route';
import React from 'react';
import { ServiceProviderPage } from 'pages/service-provider';
import { ServiceProvidersPage } from 'pages/service-providers';
import { GymCardPage } from 'pages/gym-card';
import { SchedulePage } from 'pages/schedule';
import { ServiceTypePage } from 'pages/service-type';
import { AvailabilityCalendarPage } from 'pages/availability-calendar';
import { ClassDetails } from 'pages/class-details';
import { RedirectPage } from 'components/payment/redirect';
import { ReceiptModal } from 'components/modals/receipt-modal';
import { CheckoutModal } from 'components/modals/checkout-modal';
import { EventDetails } from 'pages/event-details';

export const Router: React.FC = (): JSX.Element => (
  <AppContentWrapper>
    <Switch>
      <PrivateRoute component={ClassDetails} path={PROVIDER_PATHS.CLASS_DETAILS} />
      <PrivateRoute component={EventDetails} path={PROVIDER_PATHS.EVENT_DETAILS} />
      <PrivateRoute component={GymCardPage} exact path={PROVIDER_PATHS.GYM_CARD} />
      <PrivateRoute component={ReceiptModal} exact path={RECEIPT_MODAL_PATH} />
      <PrivateRoute component={CheckoutModal} exact path={CHECKOUT_MODAL_PATH} />
      <PrivateRoute component={ServiceTypePage} path={PROVIDER_PATHS.SERVICE_TYPE} />
      <PrivateRoute component={AvailabilityCalendarPage} path={PROVIDER_PATHS.AVAILABILITY} />
      <PrivateRoute component={SchedulePage} path={PROVIDER_PATHS.SCHEDULE} />
      {/* the /:paymentPath? allows the payments modal to display on the provider details page */}
      <PrivateRoute component={ServiceProviderPage} path={`${PROVIDER_BASE_PATH}/:paymentPath?`} />
      <PrivateRoute component={ServiceProvidersPage} path={ROOT_PATH} />
      <PrivateRoute component={RedirectPage} path={REDIRECT_PATH} />
      <Route component={ErrorPage} path={ERROR_PATH} />
      <Route component={DefaultRedirect} />
    </Switch>
  </AppContentWrapper>
);
