import React, { useCallback, useEffect } from 'react';
import { ACTION_STATUSES } from 'shared/consts';
import { useDispatch, useSelector } from 'react-redux';
import { selectComplete3DSCartError, selectSubmitCartStatus } from 'store/cart/selectors';
import { updatePaymentMethod } from 'store/payment/actions';
import {
  selectDeletePaymentMethodsStatus,
  selectGetPaymentMethodsStatus,
  selectSavePaymentMethodStatus,
  selectUpdatePaymentMethodStatus,
} from 'store/payment/selectors';
import { UsePaymentMethodsManagerProps, UsePaymentMethodsManagerValues } from '../types';

// eslint-disable-next-line max-lines-per-function
export const usePaymentMethodsManager = ({
  setErrorPaymentId,
  submittedPaymentMethodId,
  currentPaymentError,
  cartId,
}: UsePaymentMethodsManagerProps): UsePaymentMethodsManagerValues => {
  const dispatch = useDispatch();
  const submitCartStatus = useSelector(selectSubmitCartStatus);
  const complete3DSCartError = useSelector(selectComplete3DSCartError);
  const updatePaymentMethodStatus = useSelector(selectUpdatePaymentMethodStatus);
  const getPaymentMethodStatus = useSelector(selectGetPaymentMethodsStatus);
  const deletePaymentMethodsStatus = useSelector(selectDeletePaymentMethodsStatus);
  const savePaymentMethodStatus = useSelector(selectSavePaymentMethodStatus);

  useEffect(() => {
    if (submitCartStatus === ACTION_STATUSES.REJECTED || complete3DSCartError || currentPaymentError) {
      setErrorPaymentId(submittedPaymentMethodId);
    } else {
      setErrorPaymentId(undefined);
    }
  }, [submitCartStatus, submittedPaymentMethodId, complete3DSCartError, currentPaymentError]);

  const onPressHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        updatePaymentMethod.request({
          default: true,
          card_id: event.target.value,
          cartId,
        }),
      );
    },
    [dispatch, cartId],
  );

  return {
    onPressHandler,
    updatePaymentMethodStatus,
    getPaymentMethodStatus,
    deletePaymentMethodsStatus,
    savePaymentMethodStatus,
  };
};
