import { useMiniappSdk } from 'hooks/use-miniapp-sdk.hook';
import { useEffect } from 'react';

export const useToggleSwipeGestures = () => {
  const client = useMiniappSdk();

  useEffect(() => {
    client?.swipe.disableBackSwipe();
    return () => client?.swipe.enableBackSwipe();
  }, [client]);
};
