import { RootState } from 'store/reducer';
import { ServiceMembershipsState } from './types';
import { createSelector } from 'reselect';

export const serviceMembershipsSelector = (state: RootState): ServiceMembershipsState => state.serviceMemberships;

export const selectMemberships = createSelector(
  [serviceMembershipsSelector],
  serviceMemberships => serviceMemberships.memberships,
);

export const selectMembershipsLoadingStatus = createSelector(
  [serviceMembershipsSelector],
  serviceMemberships => serviceMemberships.getMemberships.status,
);

export const selectMembershipsError = createSelector(
  [serviceMembershipsSelector],
  serviceMemberships => serviceMemberships.getMemberships.error,
);

export const selectCurrentMembership = createSelector(
  [serviceMembershipsSelector],
  serviceMemberships => serviceMemberships.currentMembership,
);
