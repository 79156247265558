import { RootState } from 'store/reducer';
import { createSelector } from 'reselect';
import { ORDER_TYPES, PATCH_STATUSES, TRANSACTION_TYPES, TransactionDetailsServiceTypes } from 'shared/consts';
import { DisplayInfo, Item } from 'store/cart/types';
import { TransactionDetails, TransactionsState } from './types';

export const transactionsSelector = (state: RootState): TransactionsState => state.transactions;

export const selectTransactions = createSelector([transactionsSelector], transactions => transactions.transactions);

export const selectGetTransactionsStatus = createSelector(
  [transactionsSelector],
  transactions => transactions.getTransactions.status,
);

export const selectPatchTransactionStatus = createSelector(
  [transactionsSelector],
  transactions => transactions.patchTransaction.status,
);

export const selectPatchTransactionType = createSelector([transactionsSelector], data => data.patchTransaction.type);

export const selectPatchTransactionId = createSelector(
  [transactionsSelector],
  transactions => transactions.patchTransactionId,
);

export const selectIs3dsPatchTransaction = createSelector(
  [transactionsSelector],
  data => data.patchTransaction.type !== PATCH_STATUSES.CANCELLED,
);

export const selectRedirectTransaction = createSelector(
  [transactionsSelector],
  transactions => transactions.redirectTransaction,
);

export const selectGetRedirectTransactionStatus = createSelector(
  [transactionsSelector],
  transactions => transactions.getRedirectTransaction.status,
);

export const selectGetRedirectTransactionError = createSelector(
  [transactionsSelector],
  transactions => transactions.getRedirectTransaction.error,
);

export const selectTransaction = createSelector([transactionsSelector], transactions => transactions.transaction);

export const selectTransactionStatus = createSelector([selectTransaction], transaction => transaction?.details.status);

export const selectGetTransactionStatus = createSelector(
  [transactionsSelector],
  transactions => transactions.getTransaction.status,
);

const getTransactionVendorDisplay = (transaction?: TransactionDetails) => {
  let serviceProviderAddress;
  let serviceProviderLogoUrl;
  let serviceProviderName;

  if (transaction?.type === TRANSACTION_TYPES.MEMBERSHIP) {
    serviceProviderAddress = transaction?.details.service_provider.service_provider_address;
    serviceProviderLogoUrl = transaction?.details.service_provider.service_provider_logo_url;
    serviceProviderName = transaction?.details.service_provider.service_provider_name;
  } else if (transaction?.type === TRANSACTION_TYPES.SERVICES_BOOKING) {
    serviceProviderAddress = transaction?.details.service.service_provider.service_provider_address;
    serviceProviderLogoUrl = transaction?.details.service.service_provider.service_provider_logo_url;
    serviceProviderName = transaction?.details.service.service_provider.service_provider_name;
  }

  return {
    name: serviceProviderName,
    logo_url: serviceProviderLogoUrl,
    formatted_address: serviceProviderAddress,
  };
};

// eslint-disable-next-line max-lines-per-function
const getTransactionItemDisplay = (transaction?: TransactionDetails) => {
  let title = '';
  let description1 = '';
  let type = '';
  let itemId = '';
  let price;
  if (transaction?.type === TRANSACTION_TYPES.MEMBERSHIP) {
    title = transaction?.details?.name;
    type = ORDER_TYPES.SERVICE_BOOKING_MEMBERSHIP;
    itemId = transaction?.details?.membership_id;
    price = transaction?.details?.price;
  } else if (transaction?.type === TRANSACTION_TYPES.SERVICES_BOOKING) {
    title = transaction?.details?.service?.name;
    description1 = transaction?.details?.service?.resource_name;
    itemId = transaction?.details?.service_id;
    price = transaction?.details?.service.price;
    type =
      transaction?.details?.service?.type === TransactionDetailsServiceTypes.CLASS
        ? ORDER_TYPES.SERVICE_BOOKING_CLASS
        : ORDER_TYPES.SERVICE_BOOKING_APPOINTMENT;
  }

  return {
    display_info: {
      title,
      description1,
    } as DisplayInfo,
    id: itemId,
    price,
    type,
    quantity: 1,
  } as Item;
};

/**
 * The updated transaction data mimics the cart data structure and will be used in the receipt page when there is no cart.
 */
export const selectTransactionDisplay = createSelector(selectTransaction, transaction => {
  return {
    ...transaction,
    total_summary: {
      currency_type: transaction?.currency_type,
      discount_applied: transaction?.total_discount,
      subtotal: transaction?.total_price,
      tax_summary: {
        taxes_total: transaction?.total_tax,
      },
      total: transaction?.total_charge,
    },
    items: [getTransactionItemDisplay(transaction)],
    vendor: getTransactionVendorDisplay(transaction),
  };
});
