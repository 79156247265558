import React from 'react';
import { Container, ModalTitle } from './styles';
import { Title } from './title';
import { Form } from './form';
import { FieldName } from './form/field-name';
import { useIntl } from 'react-intl';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';

interface AgreementContentProps {
  setName: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  hasError: boolean;
  modalRef?: React.Ref<HTMLDivElement>;
}

export const AgreementContent = ({ setName, name, hasError, modalRef }: AgreementContentProps) => {
  const intl = useIntl();
  const isSmallViewportWidth = useIsSmallViewportWidth();

  return (
    <Container ref={modalRef}>
      {!isSmallViewportWidth && (
        <>
          <ModalTitle>{intl.formatMessage({ id: 'contract_agreement' })}</ModalTitle>
          <Title />
        </>
      )}
      <FieldName setName={setName} name={name} hasError={hasError} />
      <Form />
    </Container>
  );
};
