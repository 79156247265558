import styled from 'styled-components';
import { Image } from 'components/image';
import { EVENT_DETAILS_SECTIONS_Z_INDEXES } from 'pages/event-details/constants';
import { SMALL_DEVICE_MAX_WIDTH } from '@hqo/react-components-library/dist/device';

export const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;

  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    height: calc(100vh - 70px);
  }
`;

export const StyledMobileHeaderWrapper = styled.div`
  z-index: ${EVENT_DETAILS_SECTIONS_Z_INDEXES.HEADER_AND_FOOTER};
`;

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex: 1;
  padding: 34px 52px;
  overflow-y: auto;

  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    flex-direction: column;
    justify-content: flex-start;
    padding: 0;

    ::-webkit-scrollbar {
      width: 0;
      display: none;
    }
  }
`;

export const StyledImage = styled(Image)`
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: auto;

  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    position: fixed;
    z-index: ${EVENT_DETAILS_SECTIONS_Z_INDEXES.IMAGE_SECTION};
  }
`;

export const EventDetailsContainer = styled.div`
  width: min(700px, 100%);
  margin-bottom: 50px;

  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    position: relative;
  }
`;
