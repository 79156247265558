import { RootState } from '../reducer';
import { FormattedUserContracts, ServiceProvidersState } from 'store/serviceProviders/types';
import { createSelector } from 'reselect';

export const serviceProvidersStateSelector = (state: RootState): ServiceProvidersState => state.serviceProviders;

export const selectServiceProviders = createSelector(
  serviceProvidersStateSelector,
  serviceProvidersState => serviceProvidersState.serviceProviders,
);

export const selectServiceProvidersStatus = createSelector(
  serviceProvidersStateSelector,
  serviceProvidersState => serviceProvidersState.getServiceProviders.status,
);

export const selectServiceProvidersListView = createSelector(selectServiceProviders, serviceProviders => {
  return serviceProviders?.length
    ? serviceProviders.map(serviceProvider => {
        return {
          uuid: serviceProvider?.uuid,
          title: serviceProvider?.name,
          subtitle: serviceProvider?.tagline,
          imageUrl: serviceProvider?.hero_image_url ?? serviceProvider?.logo_url,
        };
      })
    : [];
});

export const selectCurrentServiceProvider = createSelector(
  serviceProvidersStateSelector,
  serviceProvidersState => serviceProvidersState.currentServiceProvider,
);

export const selectCurrentServiceProviderStatus = createSelector(
  serviceProvidersStateSelector,
  serviceProvidersState => serviceProvidersState.getCurrentServiceProvider.status,
);

export const selectCurrentServiceProviderDetailView = createSelector(selectCurrentServiceProvider, serviceProvider => ({
  uuid: serviceProvider?.uuid,
  title: serviceProvider?.name,
  subtitle: serviceProvider?.tagline,
  description: serviceProvider?.description,
  directions: serviceProvider?.directions,
  address: serviceProvider?.formatted_address,
  imageUrl: serviceProvider?.hero_image_url ?? serviceProvider?.logo_url,
  phone: serviceProvider?.phone,
  userCount: serviceProvider?.user_count,
  managementEmail: serviceProvider?.config?.management_email,
  supportsServiceTypes: serviceProvider?.config.supports_service_types,
  isClassesEnable: serviceProvider?.config.group,
  isAppointmentEnable: serviceProvider?.config.appointment,
}));

export const selectIsUserRegistered = createSelector(
  selectCurrentServiceProvider,
  currentServiceProvider => currentServiceProvider?.is_user_registered,
);

export const selectIsMembershipSignupEnabled = createSelector(
  selectCurrentServiceProvider,
  currentServiceProvider => currentServiceProvider?.config?.enable_membership_signup,
);

export const selectIsRegistrationEnabled = createSelector(
  selectCurrentServiceProvider,
  currentServiceProvider => currentServiceProvider?.config?.enable_registration,
);

export const selectIsQRCodeEnabled = createSelector(
  selectCurrentServiceProvider,
  currentServiceProvider => currentServiceProvider?.config?.enable_qr_code,
);

export const selectUserMemberships = createSelector(
  selectCurrentServiceProvider,
  currentServiceProvider => currentServiceProvider?.user_memberships,
);

export const selectFormattedUserContracts = createSelector(selectUserMemberships, userMemberships => {
  const contractsObject: FormattedUserContracts = {};
  return userMemberships
    ? userMemberships.reduce((obj, contract) => {
        const contracts: FormattedUserContracts = JSON.parse(JSON.stringify(obj));
        contracts[contract.name] = contract.status;
        return contracts;
      }, contractsObject)
    : {};
});

export const selectServiceProviderAdapter = createSelector(
  selectCurrentServiceProvider,
  currentServiceProvider => currentServiceProvider?.config?.adapter,
);
