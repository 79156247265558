import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useCurrentUser } from 'hooks/use-current-user.hook';
import { formatInitialPath } from 'utils/formatPath';

export const DefaultRedirect: React.FC = (): JSX.Element => {
  const [user, loading] = useCurrentUser();
  const { pathname } = useLocation();
  const redirectPath = formatInitialPath(pathname);

  if (user || loading) {
    return <Redirect to={`${redirectPath}${location.search}`} />;
  }

  return <Redirect to="/error" />;
};
