import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectBuilding } from 'store/building/selectors';
import { VERTICAL_NAME } from 'shared/consts/service-booking-vertical';
import { currentToken } from 'store/user/selectors';
import { apiUrlSelector } from 'store/config/selectors';

export interface WebTrackingData {
  buildingUuid: string;
  appUuid: string;
  token: string;
  apiUrl: string;
}

export const useWebTrackingAnalytics = (): WebTrackingData | null => {
  const building = useSelector(selectBuilding);
  const token = useSelector(currentToken);
  const currentApiUrl = useSelector(apiUrlSelector);

  return useMemo(() => {
    if (!building || !token || !currentApiUrl) {
      return null;
    }
    return {
      buildingUuid: building.uuid,
      appUuid: VERTICAL_NAME,
      token,
      apiUrl: currentApiUrl,
    };
  }, [building, token, currentApiUrl]);
};
