import styled from 'styled-components';
import { SelectBlock } from '@hqo/react-components-library/dist/molecules/selectBlock';

export const ListContainer = styled.div`
  @media only screen and (min-width: 984px) {
    width: 635px;
  }
  width: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 16px;
`;

export const AppointmentsWrapper = styled.div`
  overflow-y: scroll;
`;

export const StyledTile = styled(SelectBlock)`
  &.appointment-item {
    height: 35px;
  }

  @media only screen and (max-width: 984px) {
    .accordion-content {
      margin-right: -16px;
      margin-left: -16px;
    }
  }
`;
