import React from 'react';
import { FlexContainer, SkeletonBlock } from '../styles';

export const ScheduleBlock = (): JSX.Element => {
  return (
    <FlexContainer gap="25">
      <SkeletonBlock width="66" />
      <FlexContainer gap="25" isColumnDirection>
        <SkeletonBlock width="650" />
        <SkeletonBlock width="650" />
        <SkeletonBlock width="650" />
      </FlexContainer>
    </FlexContainer>
  );
};
