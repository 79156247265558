import { CHECKOUT_BREAK_POINT } from 'shared/consts';
import styled, { css } from 'styled-components';
import { DIMENSIONS } from '@hqo/react-components-library/dist/utils';

interface SkeletonProps {
  width?: '650' | '374' | '349' | '281' | '232' | '171' | '94' | '66';
  gap?: '80' | '58' | '38' | '25' | '23' | '21' | '14';
  isColumnDirection?: boolean;
  isWidth?: boolean;
  top?: '25' | '15' | '10';
  left?: number;
}

export const skeletonAnimation = () => css`
  background: ${({ theme }) => theme?.colors?.greys?.sharedGreyBackground};
  background: ${({ theme }) =>
    `linear-gradient(110deg, ${theme?.colors?.greys?.adminGreyMedium} 8%, ${theme?.colors?.greys?.adminGreyLightAlt} 18%, ${theme?.colors?.greys?.adminGreyMedium} 33%)`};
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;

  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
`;

const skeletonBlockWidthStyles: Record<NonNullable<SkeletonProps['width']>, ReturnType<typeof css>> = {
  650: css`
    width: 650px;

    @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
      width: 278px;
    }
  `,
  374: css`
    width: 374px;

    @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
      width: 160px;
    }
  `,
  349: css`
    width: 349px;

    @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
      width: 149px;
    }
  `,
  281: css`
    width: 281px;

    @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
      width: 120px;
    }
  `,
  232: css`
    width: 232px;

    @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
      width: 99px;
    }
  `,
  171: css`
    width: 171px;

    @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
      width: 73px;
    }
  `,
  94: css`
    width: 94px;

    @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
      width: 40px;
    }
  `,
  66: css`
    width: 66px;

    @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
      width: 28px;
    }
  `,
};

const flexContainerGapStyles: Record<NonNullable<SkeletonProps['gap']>, ReturnType<typeof css>> = {
  80: css`
    gap: 80px;

    @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
      gap: 32px;
    }
  `,
  58: css`
    gap: 58px;

    @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
      gap: 25px;
    }
  `,
  38: css`
    gap: 38px;
  `,
  25: css`
    gap: 25px;

    @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
      gap: 11px;
    }
  `,
  23: css`
    gap: 23px;

    @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
      gap: 20px;
    }
  `,
  21: css`
    gap: 21px;

    @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
      gap: 18px;
    }
  `,
  14: css`
    gap: 14px;

    @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
      gap: 12px;
    }
  `,
};

const flexContainerTopStyles: Record<NonNullable<SkeletonProps['top']>, ReturnType<typeof css>> = {
  25: css`
    margin-top: 25px;

    @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
      margin-top: 0;
    }
  `,
  15: css`
    margin-top: 15px;

    @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
      margin-top: 0;
    }
  `,
  10: css`
    margin-top: 10px;

    @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
      margin-top: 0;
    }
  `,
};

export const Container = styled.div`
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-bottom: 25px;

  .desktop {
    display: none;
    padding: 70px 52px;
  }
  @media (min-width: ${CHECKOUT_BREAK_POINT}px) {
    .desktop {
      display: flex;
    }
    .mobile {
      display: none;
    }
  }
`;

export const ServiceImage = styled.div`
  width: 876px;
  height: 300px;
  background-color: ${({ theme }) => theme?.colors?.greys?.sharedGreyBackground ?? '#EDEDED'};
  ${skeletonAnimation};

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    width: 100%;
    height: 188px;
  }
`;

export const FlexContainer = styled.div<SkeletonProps>`
  display: flex;
  height: 100%;
  flex-direction: ${({ isColumnDirection }) => (isColumnDirection ? 'column' : 'row')};
  ${({ isWidth }) => isWidth && 'width: min(900px, 100%)'};
  ${({ left }) => left && `padding-left: 0px;`};
  ${({ gap }) => flexContainerGapStyles[gap]};
  ${({ top }) => flexContainerTopStyles[top]};

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    ${({ left }) => left && `padding-left: 16px;`};
  }
`;

export const SkeletonBlock = styled.div<SkeletonProps>`
  background-color: ${({ theme }) => theme?.colors?.greys?.sharedGreyBackground ?? '#EDEDED'};
  height: 18px;
  display: flex;
  border-radius: 4px;
  ${({ width }) => skeletonBlockWidthStyles[width]};
  ${skeletonAnimation};

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    height: 15px;
  }
`;
