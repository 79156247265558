import styled from 'styled-components';
import { Modal } from '@hqo/react-components-library/dist/molecules/modals/modal';
import { SMALL_DEVICE_MAX_WIDTH } from '@hqo/react-components-library/dist/device';

export const StyledModal = styled(Modal)`
  overflow: auto;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 348px;
    max-height: 683px;
    max-width: 963px;
    padding: 24px 32px;
    margin: auto;
    overflow: initial;
  }

  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    padding: 24px 56px;
  }
`;

export const BackLinkWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  margin: 25px;
  justify-content: flex-start;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;
