import { StyledPromoCodeLink } from './styles';

import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

interface PromoCodeLinkProps {
  onClick: VoidFunction;
  isDisabled: boolean;
}

export const PromoCodeLink = ({ onClick, isDisabled }: PromoCodeLinkProps): JSX.Element => {
  const intl = useIntl();

  const handleKeyboardClick = useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.key === 'Enter' || event.key === ' ') {
        onClick();
      }
    },
    [onClick],
  );

  return (
    <StyledPromoCodeLink
      data-testid="promo-link-test"
      onClick={onClick}
      onKeyDown={handleKeyboardClick}
      disabled={isDisabled}
    >
      {intl.formatMessage({ id: 'add_promo_code' })}
    </StyledPromoCodeLink>
  );
};
