import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { goBack } from 'connected-react-router';

import { selectCurrentServiceProviderDetailView } from 'store/serviceProviders/selectors';

interface UseGymCardPage {
  handleContactUsOnClick: () => Window;
  onPressBack: VoidFunction;
}

export const useGymCardPage = (): UseGymCardPage => {
  const dispatch = useDispatch();
  const providerDetails = useSelector(selectCurrentServiceProviderDetailView);

  const handleContactUsOnClick = useCallback(() => {
    return !!providerDetails.managementEmail && window.open(`mailto:${providerDetails.managementEmail}`, '_blank');
  }, [providerDetails.managementEmail]);

  const onPressBack = useCallback(() => {
    dispatch(goBack());
  }, [dispatch]);

  return {
    handleContactUsOnClick,
    onPressBack,
  };
};
