import styled from 'styled-components';
import { SMALL_DEVICE_MAX_WIDTH } from '@hqo/react-components-library/dist/device';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  flex: 1;
  margin-top: 55px;

  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    margin-top: 0px;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }
`;

export const DoublePane = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;

  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    grid-template-columns: 1fr;
    gap: 20px;
    overflow-y: scroll;
    padding: 0px;
    width: 100%;
    max-height: calc(100vh - 270px);
    font: 400 16px/18px ${({ theme }) => theme.fonts.join()};
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 89px;

  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    background: ${({ theme }) => theme.colors.$white};
    right: 0;
    bottom: 0;
    width: 100%;
    height: 48px;
    padding: 13px 0 16px;
  }
`;

export const RightPane = styled.div`
  height: 450px;
  overflow-y: scroll;
  overflow-x: hidden;

  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    height: 100%;
  }

  ::-webkit-scrollbar {
    width: 8px;
    background: ${({ theme }) => theme.colors.$transparent};
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.greys.sharedMediumGrey};
    border-radius: 4px;
  }
`;

export const LeftPane = styled.div``;
