import * as actions from './actions';

import { ActionType, createReducer } from 'typesafe-actions';

import { ACTION_STATUSES } from 'shared/consts';
import { ServiceRegistrationState } from './types';

export const initialState: ServiceRegistrationState = {
  registrationFields: null,
  getRegistrationFields: {
    status: null,
    error: null,
  },
  registerUser: {
    status: null,
    error: null,
  },
};

const getRegistrationFieldsRequestHandler = (state: ServiceRegistrationState): ServiceRegistrationState => ({
  ...state,
  getRegistrationFields: {
    status: ACTION_STATUSES.PENDING,
    error: null,
  },
});

const getRegistrationFieldsSuccessHandler = (
  state: ServiceRegistrationState,
  { payload }: ActionType<typeof actions.getRegistrationFields.success>,
): ServiceRegistrationState => ({
  ...state,
  registrationFields: payload,
  getRegistrationFields: {
    status: ACTION_STATUSES.FULFILLED,
    error: null,
  },
});

const getRegistrationFieldsFailureHandler = (
  state: ServiceRegistrationState,
  { payload: error }: ActionType<typeof actions.getRegistrationFields.failure>,
): ServiceRegistrationState => ({
  ...state,
  getRegistrationFields: {
    status: ACTION_STATUSES.REJECTED,
    error: error.error,
  },
});

const registerUserRequestHandler = (state: ServiceRegistrationState): ServiceRegistrationState => ({
  ...state,
  registerUser: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const registerUserSuccessHandler = (state: ServiceRegistrationState): ServiceRegistrationState => ({
  ...state,
  registerUser: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const registerUserFailureHandler = (
  state: ServiceRegistrationState,
  { payload: error }: ActionType<typeof actions.registerUser.failure>,
): ServiceRegistrationState => ({
  ...state,
  registerUser: {
    status: ACTION_STATUSES.REJECTED,
    error: error.error,
  },
});

const resetRegisterUserStatusHandler = (state: ServiceRegistrationState): ServiceRegistrationState => ({
  ...state,
  registerUser: {
    ...initialState.registerUser,
  },
});

const serviceRegistrationReducer = createReducer(initialState)
  .handleAction(actions.getRegistrationFields.request, getRegistrationFieldsRequestHandler)
  .handleAction(actions.getRegistrationFields.success, getRegistrationFieldsSuccessHandler)
  .handleAction(actions.getRegistrationFields.failure, getRegistrationFieldsFailureHandler)
  .handleAction(actions.registerUser.request, registerUserRequestHandler)
  .handleAction(actions.registerUser.success, registerUserSuccessHandler)
  .handleAction(actions.registerUser.failure, registerUserFailureHandler)
  .handleAction(actions.resetRegisterUserStatus, resetRegisterUserStatusHandler);

export default serviceRegistrationReducer;
