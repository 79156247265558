import React, { useCallback } from 'react';

import { ContentTile } from '@hqo/react-components-library/dist/molecules/tiles/content-tile';
import { IMG_URL } from 'shared/consts';
import { ServiceProviderListView } from 'store/serviceProviders/types';
import { Wrapper } from './styles';

export interface ListProps {
  providers: Array<ServiceProviderListView>;
  handleProviderClick: (uuid: string) => void;
}

export const List = ({ providers, handleProviderClick }: ListProps) => {
  const onClickHandler = useCallback(
    (companyUuid: string) => {
      handleProviderClick(companyUuid);
    },
    [providers],
  );

  return (
    <Wrapper data-testid="providers">
      {providers.map(provider => {
        return (
          <ContentTile
            key={provider.uuid}
            imageUrl={provider.imageUrl ? `${IMG_URL}${provider.imageUrl}` : null}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => onClickHandler(provider.uuid)}
            subtitle={provider.subtitle}
            data-testid={`provider ${provider.uuid}`}
            title={provider.title}
          />
        );
      })}
    </Wrapper>
  );
};
