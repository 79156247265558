import { User, UserLoginResponse } from './types';

import { FailureActionPayload } from '../errors/types';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const externalLogin = createAsyncAction(
  'user/EXTERNAL_LOGIN_REQUEST',
  'user/EXTERNAL_LOGIN_RESPONSE',
  'user/EXTERNAL_LOGIN_FAILURE',
)<[undefined, undefined], UserLoginResponse, FailureActionPayload>();

export const getCurrentUser = createAsyncAction(
  'user/GET_CURRENT_REQUEST',
  'user/GET_CURRENT_RESPONSE',
  'user/GET_CURRENT_FAILURE',
)<void, { user: User }, FailureActionPayload>();

export const refreshToken = createAction('config/REFRESH_TOKEN')<undefined>();
