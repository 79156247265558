import { RegisterContractAgreementContent } from 'pages/register-contract-agreement-modal/components/register-contract-agreement-content';
import { RegisterContractAgreementDesktopModal } from 'pages/register-contract-agreement-modal/components/register-contract-agreement-desktop-modal';
import { SwipeModal } from '@hqo/react-components-library/dist/molecules/modals/swipe-modal';
import React, { useEffect, useCallback, useState } from 'react';
import { OrderType } from 'store/cart/types';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { useDispatch, useSelector } from 'react-redux';
import { replace } from 'connected-react-router';
import qs from 'qs';
import { RegistrationContractAgreementStep } from 'shared/consts/service-provider';
import { setBookingFromClassPage } from 'store/availableServices/actions';
import { track } from '@hqo/web-tracking';
import { getMemberships } from 'store/serviceMemberships/actions';
import { ACTION_STATUSES, TRACK_EVENTS, TRACK_EVENT_TYPES } from 'shared/consts';
import { selectMemberships, selectMembershipsLoadingStatus } from 'store/serviceMemberships/selectors';
import { getRegistrationFields } from 'store/serviceRegistration/actions';
import { selectCurrentServiceProvider } from 'store/serviceProviders/selectors';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';
import { PAGES } from 'shared/consts/consts';
import { useIntl } from 'react-intl';
import { selectCartId, selectCreateCartStatus } from 'store/cart/selectors';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { resetCreateCartStatus } from 'store/cart/actions';
import { useToggleNativeHeader } from 'hooks/use-toggle-native-header.hook';
import { useToggleSwipeGestures } from 'hooks/use-toggle-swipe-gestures.hook';
import { selectBuilding } from 'store/building/selectors';
import { useParams } from 'react-router-dom';
import { ProviderParams } from 'shared/types';
import { useOwnerValues } from 'hooks/use-owner-values.hook';

export interface RegisterContractAgreementModalProps {
  showMembershipsModal: boolean;
  setCurrentOrderType: React.Dispatch<React.SetStateAction<OrderType>>;
}

export const RegisterContractAgreementModal = ({
  showMembershipsModal,
  setCurrentOrderType,
}: RegisterContractAgreementModalProps): JSX.Element => {
  const intl = useIntl();
  const isSmallViewportWidth = useIsSmallViewportWidth();
  const { step } = useSearchParams();
  const { companyUuid } = useParams<ProviderParams>();
  const { ownerType, ownerUuid } = useOwnerValues();
  const isRegistrationPage = step === RegistrationContractAgreementStep.ENROLLMENT && !showMembershipsModal;
  const isContractListPage = step === RegistrationContractAgreementStep.ENROLLMENT && showMembershipsModal;
  const isAgreementPage = step === RegistrationContractAgreementStep.AGREEMENT;
  const dispatch = useDispatch();
  const restQueryParams = useSearchParams();
  const [canTrack, setCanTrack] = useState(false);
  const getMembershipsLoadingStatus = useSelector(selectMembershipsLoadingStatus);
  const getMembershipsData = useSelector(selectMemberships);
  const currentServiceProvider = useSelector(selectCurrentServiceProvider);
  const building = useSelector(selectBuilding);
  const [reverseAnimation, setReverseAnimation] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<string>(null);
  const [currentPageTitle, setCurrentPageTitle] = useState<string>(null);
  const [currentPageSubtitle, setCurrentPageSubtitle] = useState<string>(null);
  const [isModalWithoutSlideAnimation, setIsModalWithoutSlideAnimation] = useState<boolean>(true);
  const [reverseHorizontalAnimation, setReverseHorizontalAnimation] = useState<boolean>(false);
  const createCartStatus = useSelector(selectCreateCartStatus);
  const { showMiniappPaymentsNavigation, enableServiceBookingPaymentsMigration } = useFlags();
  const cartId = useSelector(selectCartId);
  const isMobileDevice = useIsSmallViewportWidth();
  useToggleNativeHeader();
  useToggleSwipeGestures();

  useEffect(() => {
    if (isRegistrationPage) {
      setCurrentPage(PAGES.REGISTRATION);
      setCurrentPageTitle(intl.formatMessage({ id: 'contact_information' }));
      setCurrentPageSubtitle(intl.formatMessage({ id: 'contact_information_description' }));
    } else if (isContractListPage) {
      setIsModalWithoutSlideAnimation(true);
      setReverseHorizontalAnimation(false);
      setCurrentPage(PAGES.CONTRACT_LIST);
      setCurrentPageTitle(intl.formatMessage({ id: 'select_plan' }));
      setCurrentPageSubtitle(intl.formatMessage({ id: 'select_plan_description' }));
    } else if (isAgreementPage) {
      setCurrentPage(PAGES.AGREEMENT);
      setCurrentPageTitle(intl.formatMessage({ id: 'contract_agreement' }));
      setCurrentPageSubtitle(intl.formatMessage({ id: 'mindbody_agreement' }));
    }
  }, [showMembershipsModal, isRegistrationPage, isContractListPage, isAgreementPage, step]);

  useEffect(() => {
    if (createCartStatus === ACTION_STATUSES.FULFILLED && !enableServiceBookingPaymentsMigration) {
      const isSwipeModalContent: boolean = showMiniappPaymentsNavigation && isMobileDevice;
      const internalPath = isSwipeModalContent
        ? `${location.pathname}/swipe-payment/${cartId}?swipePaymentIFramePath=checkout`
        : `${location.pathname}/payment/${cartId}`;
      dispatch(resetCreateCartStatus());
      setReverseAnimation?.(true);
      dispatch(replace(internalPath));
    }
    dispatch(resetCreateCartStatus());
  }, [cartId, createCartStatus, isMobileDevice, enableServiceBookingPaymentsMigration]);

  useEffect(() => {
    setCurrentOrderType(OrderType.SERVICE_BOOKING_MEMBERSHIP);
  }, []);

  const onPressBack = useCallback(() => {
    setIsModalWithoutSlideAnimation(false);
    setReverseHorizontalAnimation(true);
    if (isAgreementPage) {
      // Timeout ensures that the transition finishes before switching content
      setTimeout(() => {
        dispatch(
          replace(
            `${location.pathname}?${qs.stringify({
              ...restQueryParams,
              step: RegistrationContractAgreementStep.ENROLLMENT,
            })}`,
          ),
        );
      }, 350);
    }
  }, [dispatch, isAgreementPage, restQueryParams]);

  const onClose = useCallback(() => {
    if (isContractListPage) {
      track(TRACK_EVENTS.CONTRACT_LIST_EXIT_CLICK, { type: TRACK_EVENT_TYPES.ACTION }, { sendToHqoTracking: true });
    }
    setReverseAnimation(true);
    dispatch(
      replace(
        `${location.pathname.replace('/register-contract', '')}?${qs.stringify({
          ...restQueryParams,
        })}`,
      ),
    );
  }, [dispatch, setReverseAnimation, isContractListPage, restQueryParams]);

  useEffect(() => {
    dispatch(setBookingFromClassPage(false));

    if (isRegistrationPage) {
      dispatch(
        getRegistrationFields.request({
          ownerType,
          ownerUuid,
          companyUuid,
        }),
      );
    } else if (isContractListPage) {
      dispatch(
        getMemberships.request({
          ownerType,
          ownerUuid,
          companyUuid,
        }),
      );
      setCanTrack(true);
    }
  }, [
    currentServiceProvider,
    showMembershipsModal,
    ownerType,
    ownerUuid,
    companyUuid,
    building,
    isContractListPage,
    isRegistrationPage,
  ]);

  useEffect(() => {
    if (
      getMembershipsLoadingStatus === ACTION_STATUSES.FULFILLED &&
      canTrack &&
      step === RegistrationContractAgreementStep.ENROLLMENT
    ) {
      track(
        TRACK_EVENTS.CONTRACT_LIST_IMPRESSION,
        {
          type: TRACK_EVENT_TYPES.IMPRESSION,
          contracts: getMembershipsData?.data?.memberships.map(membership => membership.name),
        },
        { sendToHqoTracking: true },
      );
    }
  }, [getMembershipsLoadingStatus, canTrack, getMembershipsData, step]);

  return isSmallViewportWidth ? (
    <SwipeModal
      data-testid="swipe-modal"
      onClose={onClose}
      isModalWithoutSlideAnimation={isModalWithoutSlideAnimation}
      reverseHorizontalAnimation={reverseHorizontalAnimation}
      reverseAnimation={reverseAnimation}
      title={currentPageTitle}
      subtitle={currentPageSubtitle}
      isScrollableContent={false}
      content={
        <RegisterContractAgreementContent
          currentServiceProvider={currentServiceProvider}
          setCurrentOrderType={setCurrentOrderType}
          currentPage={currentPage}
          setIsModalWithoutSlideAnimation={setIsModalWithoutSlideAnimation}
        />
      }
      onPressBack={onPressBack}
      withLeftArrowButton={isAgreementPage}
      withLeftExitButton={!isAgreementPage}
    />
  ) : (
    <RegisterContractAgreementDesktopModal
      onClose={onClose}
      content={
        <RegisterContractAgreementContent
          currentServiceProvider={currentServiceProvider}
          setCurrentOrderType={setCurrentOrderType}
          currentPage={currentPage}
        />
      }
      onPressBack={onPressBack}
      showBackButton={isAgreementPage}
    />
  );
};
