import styled from 'styled-components';
import { IframeModalWrapper } from '../iframeWrapper';
import { DIMENSIONS } from '@hqo/react-components-library';
import { reverseSlide, slide } from 'hocs/shared-styles';

interface StyledCancelEventModalProps {
  reverseAnimation: boolean;
  isFreeService: boolean;
}

export const StyledCancelEventModal = styled(IframeModalWrapper)<StyledCancelEventModalProps>`
  width: 543px;
  transition: width 1s;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    height: 400px;

    .iframe-wrapper {
      height: ${({ isFreeService }) => (isFreeService ? '295px' : '355px')};
      border-radius: 8px 8px 0 0;
      top: auto;
      animation: ${({ reverseAnimation }) => (reverseAnimation ? reverseSlide : slide)} 0.5s;
    }

    .iframe-modal-content {
      border-radius: 8px 8px 0 0;
      height: auto;

      * {
        box-sizing: revert;
      }
    }

    .iframe-modal-content > div {
      border-radius: 8px 8px 0 0;
      height: 100%;
      inset: 0;
    }
  }

  @media only screen and (max-width: ${DIMENSIONS.LARGE}) {
    width: 100%;
  }
`;
