import { Button, Image } from '@hqo/react-components-library';

import { DEVICE_WIDTHS } from 'shared/consts';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const PageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const MainContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex: 1;
  padding: 50px 60px 95px 60px;
  overflow-y: auto;

  @media only screen and (max-width: ${DEVICE_WIDTHS.SMALL}px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 20px 0px 20px;
  }
`;

export const Title = styled.h1`
  margin: 30px 0 0 0;
  font: 700 36px/36px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};

  @media only screen and (max-width: ${DEVICE_WIDTHS.SMALL}px) {
    font: 500 24px/24px ${({ theme }) => theme.fonts.join()};
  }
`;

export const Subtitle = styled.h2`
  font: 400 14px/14px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.$sharedGreyDark};
`;

export const InfoText = styled.p`
  margin-top: 6px;
  font: 400 italic 14px/24px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.greys.adminGreyDark};
`;

export const SectionHeader = styled.div`
  display: block;
  font: 500 16px/24px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
  margin: 0 0 16px 0;
`;

export const StyledImage = styled(Image)`
  box-sizing: border-box;
  display: block;
  width: 100%;

  @media only screen and (max-width: ${DEVICE_WIDTHS.SMALL}px) {
    width: 100vw;
    height: 25vh;
    object-fit: cover;
    margin-left: 50%;
    transform: translateX(-50%);
  }
`;

export const IconBlockContainer = styled.div`
  margin: 30px 0;
`;

export const Content = styled.div<{ hasInfo?: boolean }>`
  ${({ hasInfo }) => hasInfo && `margin-bottom: 0px;`};
  color: ${({ theme }) => theme.colors.fontColor};
  font-size: 14px;
`;

export const ProviderContainer = styled.div`
  width: min(700px, 100%);

  @media only screen and (max-width: ${DEVICE_WIDTHS.SMALL}px) {
    margin-bottom: 20px;
  }
`;

export const Section = styled.div<{ hidden?: boolean }>`
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
  margin: 30px 0;
`;

export const ServiceAvailabilityButtonWrapper = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.$white};
  margin-left: 60px;

  @media only screen and (max-width: ${DEVICE_WIDTHS.SMALL}px) {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 100%;
  }
`;

export const ServicesContainer = styled.div`
  background: ${({ theme }) => theme.colors.$white};
  display: flex;
  flex-direction: column;
  width: min(700px, 100%);

  @media only screen and (max-width: ${DEVICE_WIDTHS.SMALL}px) {
    flex-direction: column-reverse;
  }
`;

export const ServiceAvailabilityButton = styled(Button)`
  width: 256px;
  height: 36px;
  @media only screen and (max-width: ${DEVICE_WIDTHS.SMALL}px) {
    width: 100%;
    height: 48px;
  }
`;

export const BecomeMemberButtonWrapper = styled.div`
  @media only screen and (max-width: ${DEVICE_WIDTHS.SMALL}px) {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    right: 0;
    bottom: 0;
    width: 100%;
  }
`;

export const ContactUsButtonContainer = styled.div`
  margin-top: 66px;

  @media only screen and (max-width: ${DEVICE_WIDTHS.SMALL}px) {
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    bottom: 0;
    width: 100%;
    margin-top: 36px;
  }
`;

export const StyledButton = styled(Button)`
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  width: 256px;
  height: 36px;
  @media only screen and (max-width: ${DEVICE_WIDTHS.SMALL}px) {
    width: 100%;
    height: 48px;
    margin: 10px 0 12px;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  @media only screen and (max-width: ${DEVICE_WIDTHS.SMALL}px) {
    width: 100%;
    margin: 10px 8px 12px;
  }
`;

export const StyledLinkBecomeMember = styled(Link)`
  text-decoration: none;
  @media only screen and (max-width: ${DEVICE_WIDTHS.SMALL}px) {
    width: 100%;
    margin: 10px 0 12px;
  }
`;
