import { EmptyContentTile } from '@hqo/react-components-library/dist/molecules/tiles/empty-content-tile';
import React from 'react';
import { Wrapper } from './styles';

export const InfiniteLoadingList = () => {
  return (
    <Wrapper data-testid="infinite-loading-list">
      <EmptyContentTile />
      <EmptyContentTile />
      <EmptyContentTile />
    </Wrapper>
  );
};
