import { BackLinkWrapper, Container } from 'pages/register-contract-agreement-modal/styles';
import { NavBackLink } from '@hqo/react-components-library/dist/molecules/navBackLink';
import React from 'react';
import { Modal } from 'components/modals/modal-portal/modal';

export interface RegisterContractAgreementDesktopModalProps {
  onClose: VoidFunction;
  content: JSX.Element;
  onPressBack: VoidFunction;
  showBackButton: boolean;
}
export const RegisterContractAgreementDesktopModal = ({
  onClose,
  content,
  onPressBack,
  showBackButton,
}: RegisterContractAgreementDesktopModalProps): JSX.Element => {
  return (
    <Modal onClose={onClose}>
      <Container data-testid="register-contract-agreement-desktop-modal">
        {showBackButton && (
          <BackLinkWrapper>
            <NavBackLink onClick={onPressBack} />
          </BackLinkWrapper>
        )}
        {content}
      </Container>
    </Modal>
  );
};
