import * as actions from './actions';

import { ActionType, createReducer } from 'typesafe-actions';

import { ACTION_STATUSES } from 'shared/consts/consts';
import { AvailableServicesState } from 'store/availableServices/types';

export const INITIAL_STATE: AvailableServicesState = {
  availableServices: null,
  currentAvailableService: null,
  selectedAvailableService: null,
  serviceTypes: null,
  selectedDate: null,
  isBookingFromClassPage: null,
  imageIsLoaded: false,
  getAvailableServices: {
    status: null,
    error: null,
  },
  getCurrentAvailableService: {
    status: null,
    error: null,
  },
  getServiceTypes: {
    status: null,
    error: null,
  },
};

const getAvailableServicesRequestHandler = (state: AvailableServicesState): AvailableServicesState => ({
  ...state,
  getAvailableServices: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getAvailableServicesSuccessHandler = (
  state: AvailableServicesState,
  { payload }: ActionType<typeof actions.getAvailableServices.success>,
): AvailableServicesState => ({
  ...state,
  availableServices: payload,
  getAvailableServices: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const getAvailableServicesFailureHandler = (
  state: AvailableServicesState,
  { payload }: ActionType<typeof actions.getAvailableServices.failure>,
): AvailableServicesState => ({
  ...state,
  getAvailableServices: {
    error: payload.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const getCurrentAvailableServiceRequestHandler = (state: AvailableServicesState): AvailableServicesState => ({
  ...state,
  getCurrentAvailableService: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getCurrentAvailableServiceSuccessHandler = (
  state: AvailableServicesState,
  { payload }: ActionType<typeof actions.getCurrentAvailableService.success>,
): AvailableServicesState => ({
  ...state,
  currentAvailableService: payload,
  isBookingFromClassPage: false,
  getCurrentAvailableService: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const getCurrentAvailableServiceFailureHandler = (
  state: AvailableServicesState,
  { payload }: ActionType<typeof actions.getCurrentAvailableService.failure>,
): AvailableServicesState => ({
  ...state,
  getCurrentAvailableService: {
    error: payload.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const setCurrentAvailableServiceHandler = (
  state: AvailableServicesState,
  { payload }: ActionType<typeof actions.setCurrentAvailableService>,
): AvailableServicesState => ({
  ...state,
  currentAvailableService: payload,
});

const setSelectedAvailableServiceHandler = (
  state: AvailableServicesState,
  { payload }: ActionType<typeof actions.setSelectedAvailableService>,
): AvailableServicesState => ({
  ...state,
  selectedAvailableService: payload,
});

const getServiceTypesRequestHandler = (state: AvailableServicesState): AvailableServicesState => ({
  ...state,
  getServiceTypes: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getServiceTypesSuccessHandler = (
  state: AvailableServicesState,
  { payload }: ActionType<typeof actions.getServiceTypes.success>,
): AvailableServicesState => ({
  ...state,
  serviceTypes: payload,
  getServiceTypes: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const getServiceTypesFailureHandler = (
  state: AvailableServicesState,
  { payload }: ActionType<typeof actions.getServiceTypes.failure>,
): AvailableServicesState => ({
  ...state,
  getServiceTypes: {
    error: payload.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const setSelectedDate = (
  state: AvailableServicesState,
  { payload }: ActionType<typeof actions.setDate>,
): AvailableServicesState => ({
  ...state,
  selectedDate: payload,
});

const imageIsLoaded = (
  state: AvailableServicesState,
  { payload }: ActionType<typeof actions.imageIsLoaded>,
): AvailableServicesState => ({
  ...state,
  imageIsLoaded: payload,
});

const setBookingFromClassPageHandler = (
  state: AvailableServicesState,
  { payload }: ActionType<typeof actions.setBookingFromClassPage>,
): AvailableServicesState => ({
  ...state,
  isBookingFromClassPage: payload,
});

const AvailableServicesReducer = createReducer(INITIAL_STATE)
  .handleAction(actions.getAvailableServices.request, getAvailableServicesRequestHandler)
  .handleAction(actions.getAvailableServices.success, getAvailableServicesSuccessHandler)
  .handleAction(actions.getAvailableServices.failure, getAvailableServicesFailureHandler)
  .handleAction(actions.getCurrentAvailableService.request, getCurrentAvailableServiceRequestHandler)
  .handleAction(actions.getCurrentAvailableService.success, getCurrentAvailableServiceSuccessHandler)
  .handleAction(actions.getCurrentAvailableService.failure, getCurrentAvailableServiceFailureHandler)
  .handleAction(actions.setCurrentAvailableService, setCurrentAvailableServiceHandler)
  .handleAction(actions.setSelectedAvailableService, setSelectedAvailableServiceHandler)
  .handleAction(actions.getServiceTypes.request, getServiceTypesRequestHandler)
  .handleAction(actions.getServiceTypes.success, getServiceTypesSuccessHandler)
  .handleAction(actions.getServiceTypes.failure, getServiceTypesFailureHandler)
  .handleAction(actions.setDate, setSelectedDate)
  .handleAction(actions.imageIsLoaded, imageIsLoaded)
  .handleAction(actions.setBookingFromClassPage, setBookingFromClassPageHandler);

export default AvailableServicesReducer;
