import { ACTION_STATUSES } from 'shared/consts';
import { Container, UpcomingBookingsContainer } from './styles';
import { Carousel } from '@hqo/react-components-library/dist/molecules/carousel';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';
import React, { useEffect, useMemo } from 'react';
import { sortUpcomingBookingsByDate } from 'utils';
import { useDispatch, useSelector } from 'react-redux';

import { orientationEnum } from './upcoming-bookings.interface';
import { getTransactions } from 'store/transactions/actions';
import { selectTransactions, selectGetTransactionsStatus } from 'store/transactions/selectors';
import { useIntl } from 'react-intl';
import { UpcomingBooking } from './components/upcoming-booking';

export const UpcomingBookings = () => {
  const intl = useIntl();
  const isMobileDevice = useIsSmallViewportWidth();
  const orientation = isMobileDevice ? orientationEnum.horizontal : orientationEnum.vertical;
  const transactions = useSelector(selectTransactions);
  const getTransactionsStatus = useSelector(selectGetTransactionsStatus);
  const sortedTransactions = useMemo(() => sortUpcomingBookingsByDate(transactions), [transactions]);
  const isUpcomingBookingsCarouselVisible = Boolean(sortedTransactions?.length);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTransactions.request());
  }, [dispatch]);

  return (
    <Container>
      {getTransactionsStatus === ACTION_STATUSES.FULFILLED && (
        <UpcomingBookingsContainer>
          {isUpcomingBookingsCarouselVisible && (
            <Carousel
              title={intl.formatMessage({ id: 'upcoming_bookings' })}
              height={750}
              width={267}
              orientation={orientation}
            >
              {sortedTransactions.map(transaction => (
                <UpcomingBooking key={transaction.id} transaction={transaction} />
              ))}
            </Carousel>
          )}
        </UpcomingBookingsContainer>
      )}
    </Container>
  );
};
