import { OwnerType } from 'shared/consts';
import { ServiceProvider, ServiceProviderV3 } from 'store/serviceProviders/types';

/**
 * Flattens the nested service provider object and massage the data
 * so that it matches the v3 service provider object interface.
 * The v2 nested service_provider object's ownerType is building_company
 */
export const modifyServiceProvider = (serviceProvider: ServiceProvider): ServiceProviderV3 => {
  const {
    uuid: owner_uuid,
    id: owner_id,
    created_at: owner_created_at,
    updated_at: owner_updated_at,
    deleted_at: owner_deleted_at,
    service_provider: nestedServiceProvider,
    ...remainingServiceProvider
  } = serviceProvider;
  delete nestedServiceProvider.directions;
  return {
    owner_uuid,
    owner_id,
    owner_type: OwnerType.BUILDING_COMPANY,
    owner_created_at,
    owner_updated_at,
    owner_deleted_at,
    app_instance_config_uuid: null,
    ...remainingServiceProvider,
    ...nestedServiceProvider,
  };
};

export const mapServiceProviders = (
  serviceProviders: Array<ServiceProvider> | Array<ServiceProviderV3>,
): Array<ServiceProviderV3> => {
  if (serviceProviders?.length) {
    return serviceProviders.map((serviceProvider: ServiceProvider | ServiceProviderV3) => {
      if ('service_provider' in serviceProvider) {
        return modifyServiceProvider(serviceProvider);
      }
      return serviceProvider;
    });
  }
  return [];
};
