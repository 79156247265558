import { DEFAULT_LOCALE, useLocaleData } from 'components/intl-provider/intl-provider.hooks';
import { useLocale } from 'hooks/use-locale.hook';

import React from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';

interface IntlProviderProps {
  children: React.ReactNode;
}

export const IntlProvider: React.FC<IntlProviderProps> = ({ children }: IntlProviderProps): JSX.Element => {
  const locale = useLocale();

  const { messages } = useLocaleData(locale);

  return (
    <ReactIntlProvider messages={messages} locale={locale} defaultLocale={DEFAULT_LOCALE}>
      {children}
    </ReactIntlProvider>
  );
};
