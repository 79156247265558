import { useMemo } from 'react';
import { DEFAULT_LINK_ATTRIBUTES } from 'shared/consts';
import {
  transformAllHtmlAnchorsLink,
  TransformAllHtmlAnchorsLinkOptions,
} from 'shared/utils/transformAllHtmlAnchorsLink';

export type UseFormatHtmlTextOptions = TransformAllHtmlAnchorsLinkOptions;

const useFormatHtmlText = (
  htmlInputText: string,
  { attributesToAdd = DEFAULT_LINK_ATTRIBUTES, openInOsBrowserExternalLink = true }: UseFormatHtmlTextOptions = {},
) => {
  return useMemo(() => {
    const formatedHtml = htmlInputText.replace(/\n(?=\n)/g, '<br/>\n');
    return transformAllHtmlAnchorsLink(formatedHtml, { openInOsBrowserExternalLink, attributesToAdd });
  }, [htmlInputText, openInOsBrowserExternalLink, attributesToAdd]);
};

export default useFormatHtmlText;
