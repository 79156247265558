import {
  hideErrorNotifications,
  hideGenericErrorNotifications,
  showErrorNotification,
  showGenericErrorNotification,
} from './actions';

import { ErrorsState } from './types';
import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { v4 } from 'uuid';

export const initialState: ErrorsState = {
  genericErrors: [],
  errors: [],
};

const errors = createReducer(initialState.errors)
  .handleAction(showErrorNotification, (state, action) => [...state, { uuid: v4(), ...action.payload }])
  .handleAction(hideErrorNotifications, () => []);

const genericErrors = createReducer(initialState.genericErrors)
  .handleAction(showGenericErrorNotification, (state, action) => [...state, { uuid: v4(), ...action.payload }])
  .handleAction(hideGenericErrorNotifications, () => []);

export default combineReducers({ errors, genericErrors });
