import { getBuildingTheme } from 'store/theme/actions';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectBrandTheme } from 'store/theme/selectors';
import { defaultTheme, DefaultThemeWithFonts } from '@hqo/react-components-library/dist/molecules/theme';
import { configSelector } from 'store/config/selectors';
import { hqoTheme } from '@hqo/react-components-library/dist/hqo-theme-provider';
import { lighten } from 'polished';
import { hasToken } from 'store/user/selectors';

// eslint-disable-next-line max-lines-per-function
export const useBuildingTheme = (): DefaultThemeWithFonts => {
  const dispatch = useDispatch();
  const brandTheme = useSelector(selectBrandTheme);
  const { buildingUuid } = useSelector(configSelector);
  const hasUserToken = useSelector(hasToken);
  const isFullTheme = !!brandTheme && brandTheme.font !== undefined && brandTheme.primary_font_color !== undefined;

  useEffect(() => {
    const getBuildingThemeTimer = setTimeout(() => {
      if (buildingUuid && !isFullTheme && hasUserToken) {
        dispatch(getBuildingTheme.request());
      }
    }, 1000);

    return () => {
      clearTimeout(getBuildingThemeTimer);
    };
  }, [buildingUuid, isFullTheme, hasUserToken, dispatch]);

  return useMemo(() => {
    if (brandTheme) {
      return {
        colors: {
          ...defaultTheme.colors,
          $primary: brandTheme.primary1 ?? brandTheme.primary_color,
          $secondary: brandTheme.primary2 ?? brandTheme.secondary_color,
          primary: brandTheme.primary1 ?? brandTheme.primary_color,
          secondary: brandTheme.primary2 ?? brandTheme.secondary_color,
          fontColor: brandTheme.primary_font_color ?? hqoTheme.colors.greys.adminBlack,
          primaryHover: lighten(0.1, brandTheme.primary1 ?? brandTheme.primary_color),
        },
        fonts: brandTheme.font?.body?.font_family ? [`${brandTheme.font?.body?.font_family}`, ...hqoTheme.fonts] : null,
      };
    }
    return defaultTheme;
  }, [brandTheme]);
};
