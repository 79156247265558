import styled from 'styled-components';
import { IMAGE_PLACEHOLDER_HEIGHT } from 'pages/event-details/constants';
import { SMALL_DEVICE_MAX_WIDTH } from '@hqo/react-components-library/dist/device';
import { skeletonAnimation } from '../service-skeleton/styles';

interface LoadingProps {
  hide?: boolean;
}

export const DivVisibilityContainer = styled.div<LoadingProps>`
  ${({ hide }) => hide && 'display: none;'}
`;

export const ImageSkeleton = styled.div`
  display: block;
  width: 100%;
  height: ${IMAGE_PLACEHOLDER_HEIGHT}px;
  background-color: ${({ theme }) => theme.colors.greys.sharedGreyBackground};
  ${skeletonAnimation};

  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    border-radius: 0;
    width: 100vw;
    margin-left: 50%;
    transform: translateX(-50%);
  }
`;
