/* eslint-disable max-lines-per-function */
import { GetJWTHandlerPayload } from '@hqo/hqo-miniapp-client-sdk';
import { getMiniappSdkClient } from 'hooks/use-miniapp-sdk.hook';
import { Dispatch, MiddlewareAPI } from 'redux';
import { NOT_FOUND_ERROR_CODE, PROMISE_TIMEOUT, UNAUTHORIZED_ERROR_CODE } from 'shared/consts';
import { RootAction } from 'store/actions';
import { getBuilding } from 'store/building/actions';
import { saveExternalConfig } from 'store/config/actions';
import { showGenericErrorNotification } from 'store/errors/actions';

import { RootState } from 'store/reducer';

import { getBuildingTheme } from 'store/theme/actions';
import { externalLogin, getCurrentUser, refreshToken } from 'store/user/actions';

import { isActionOf } from 'typesafe-actions';

const failedActionsTypes = [
  getBuildingTheme.failure,
  externalLogin.failure,
  getCurrentUser.failure,
  getBuilding.failure,
];

const failedActionsTypesWithErrorScreenRedirect = failedActionsTypes;

export const tokenExpiryMiddleware =
  (store: MiddlewareAPI<Dispatch<RootAction>, RootState>) =>
  (next: Dispatch<RootAction>) =>
  async (action: RootAction) => {
    if (
      isActionOf(failedActionsTypes)(action) &&
      'status' in action.payload.error &&
      (action.payload.error.status === UNAUTHORIZED_ERROR_CODE || action.payload.error.status === NOT_FOUND_ERROR_CODE)
    ) {
      const miniappSdkClient = getMiniappSdkClient();

      await new Promise<void>((resolve, reject) => {
        const timeout = setTimeout(() => {
          store.dispatch(
            showGenericErrorNotification({
              action: action.type,
              error: action.payload.error,
              errorCode: action.payload.errorCode,
            }),
          );
          reject();
        }, PROMISE_TIMEOUT);

        miniappSdkClient.identity.getJWT();

        miniappSdkClient.on('getJWTResponse', (data: GetJWTHandlerPayload) => {
          clearTimeout(timeout);
          resolve();

          store.dispatch(saveExternalConfig({ authToken: `Bearer ${data.token}` }));
        });
      });

      store.dispatch(refreshToken());
    } else if (isActionOf(failedActionsTypesWithErrorScreenRedirect)(action)) {
      store.dispatch(
        showGenericErrorNotification({
          action: action.type,
          error: action.payload.error,
          errorCode: action.payload.errorCode,
        }),
      );
    } else {
      next(action);
    }
  };
