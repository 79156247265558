import React from 'react';
import { Container, DoublePane, ButtonContainer, LeftPane, RightPane } from './styles';

export interface BookingOverviewProps {
  isButtonVisible: boolean;
  Button: React.ReactNode;
  LeftPaneContent: React.ReactNode;
  RightPaneContent: React.ReactNode;
}

export const DoublePaneWrapper = ({
  isButtonVisible,
  Button,
  LeftPaneContent,
  RightPaneContent,
}: BookingOverviewProps) => {
  return (
    <Container>
      <DoublePane>
        <LeftPane>{LeftPaneContent}</LeftPane>
        <RightPane>{RightPaneContent}</RightPane>
      </DoublePane>
      {isButtonVisible && <ButtonContainer>{Button}</ButtonContainer>}
    </Container>
  );
};
