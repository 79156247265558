import {
  CompleteCartDto,
  CreateCartDto,
  CreateCartResponse,
  Order,
  PromoCodeCartRequestParams,
  SubmitOrderDto,
  TransactionObject,
} from './types';
import { FailureActionPayload, ScreenshotErrorPayload } from 'store/errors/types';
import { createAction, createAsyncAction } from 'typesafe-actions';
import { PaymentError } from 'shared/consts';

export const createCart = createAsyncAction('CREATE_CART_REQUEST', 'CREATE_CART_SUCCESS', 'CREATE_CART_FAILURE')<
  CreateCartDto,
  CreateCartResponse,
  FailureActionPayload
>();

export const getCart = createAsyncAction('GET_CART_REQUEST', 'GET_CART_SUCCESS', 'GET_CART_FAILURE')<
  string,
  Order,
  FailureActionPayload
>();

export const submitCart = createAsyncAction('SUBMIT_CART_REQUEST', 'SUBMIT_CART_SUCCESS', 'SUBMIT_CART_FAILURE')<
  SubmitOrderDto,
  Order,
  PaymentError
>();

export const complete3DSCart = createAsyncAction(
  'COMPLETE_3DS_CART_REQUEST',
  'COMPLETE_3DS_CART_SUCCESS',
  'COMPLETE_3DS_CART_FAILURE',
)<CompleteCartDto, TransactionObject, FailureActionPayload>();

export const applyPromoCodeToCart = createAsyncAction(
  'APPLY_PROMO_CODE_TO_CART_REQUEST',
  'APPLY_PROMO_CODE_TO_CART_SUCCESS',
  'APPLY_PROMO_CODE_TO_CART_FAILURE',
)<PromoCodeCartRequestParams, Order, FailureActionPayload>();

export const removePromoCodeFromCart = createAsyncAction(
  'REMOVE_PROMO_CODE_FROM_CART_REQUEST',
  'REMOVE_PROMO_CODE_FROM_CART_SUCCESS',
  'REMOVE_PROMO_CODE_FROM_CART_FAILURE',
)<PromoCodeCartRequestParams, Order, FailureActionPayload>();

export const setScreenshotAction = createAsyncAction(
  'SET_SCREENSHOT_REQUEST',
  'SET_SCREENSHOT_SUCCESS',
  'SET_SCREENSHOT_FAILURE',
)<[undefined, undefined], [undefined, undefined], ScreenshotErrorPayload>();

export const resetComplete3DSCart = createAction('COMPLETE_3DS_CART_RESET')<void>();

export const resetCreateCartStatus = createAction('RESET_CREATE_CART_STATUS')<undefined>();

export const resetScreenshotStatus = createAction('RESET_SCREENSHOT_STATUS')<undefined>();

export const resetSubmitCartStatus = createAction('RESET_SUBMIT_CART_STATUS')<void>();

export const resetGetCartStatus = createAction('RESET_GET_CART_STATUS')<void>();

export const resetCart = createAction('RESET_CART')<void>();
