import { SMALL_DEVICE_MAX_WIDTH } from '@hqo/react-components-library/dist/device';
import styled from 'styled-components';
import HtmlText from 'shared/components/HtmlText/HtmlText';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 -4px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Title = styled.h1`
  margin: 0;
  font: 700 36px/36px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};

  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    color: ${({ theme }) => theme.colors.fontColor};
    font: 500 28px / normal ${({ theme }) => theme.fonts.join()};
  }
`;

export const Subtitle = styled.h2`
  font: 400 16px / normal ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.palette.systemGreyDark};
  margin: 0;
`;

export const EventInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0 0 5px;
  gap: 16px;

  .icon-block-title {
    font: 500 16px/24px ${({ theme }) => theme.fonts.join()};
  }

  .icon-block-icon {
    color: ${({ theme }) => theme.colors.palette.systemGreyDark};
    margin-top: 4px;
    width: 18px;
  }
`;

export const Section = styled.div`
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
  margin-top: 8px;
`;

export const Content = styled(HtmlText)`
  color: ${({ theme }) => theme.colors.fontColor};
  font-size: 14px;
  margin: 0;
`;

export const HostContainer = styled.span`
  font: 400 14px / normal ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.palette.systemGreyDark};
  margin-top: 24px;
`;
