import { PaymentState } from './types';
import { RootState } from 'store/reducer';
import { createSelector } from 'reselect';

export const paymentSelector = (state: RootState): PaymentState => state.payment;

export const selectPaymentMethods = createSelector([paymentSelector], payment => payment.paymentMethods);

export const selectGetPaymentMethodsStatus = createSelector(
  [paymentSelector],
  payment => payment.getPaymentsMethods.status,
);

export const selectGetPaymentMethodsError = createSelector(
  [paymentSelector],
  payment => payment.getPaymentsMethods.error,
);

export const selectDeletePaymentMethodsStatus = createSelector(
  [paymentSelector],
  payment => payment.deletePaymentMethod.status,
);

export const selectDeletePaymentMethodsError = createSelector(
  [paymentSelector],
  payment => payment.deletePaymentMethod.error,
);

export const selectSavePaymentMethodStatus = createSelector(
  [paymentSelector],
  payment => payment.savePaymentMethod.status,
);

export const selectSavePaymentMethodError = createSelector(
  [paymentSelector],
  payment => payment.savePaymentMethod.error,
);

export const selectCurrentPaymentMethodId = createSelector(
  [paymentSelector],
  payment => payment.currentPaymentMethodId,
);

export const selectUpdatePaymentMethodStatus = createSelector(
  [paymentSelector],
  payment => payment.updatePaymentMethod.status,
);
