import { SMALL_DEVICE_MAX_WIDTH } from '@hqo/react-components-library/dist/device';
import { AvailabilityDatePicker } from '@hqo/react-components-library/dist/molecules/availabilityDatePicker';
import styled from 'styled-components';

export const StyledAvailabilityDatePicker = styled(AvailabilityDatePicker)`
  width: 100%;
  .title {
    font: 700 16px/19px ${({ theme }) => theme.fonts.join()};
  }
  .week-date-container {
    padding: 10px;
  }
  .calendar-header {
    padding: 0 5px;
  }
  .availability-date-picker-wrapper {
    width: 100%;
  }
  @media (min-width: ${SMALL_DEVICE_MAX_WIDTH + 2}px) {
    background: ${({ theme }) => theme.colors.universal.white};
    color: ${({ theme }) => theme.colors.primary};
    .calendar-header {
      padding: 0;
    }
    .availability-date-picker-wrapper {
      width: 675px;
    }
    .week-date-container {
      background: ${({ theme }) => theme.colors.universal.white};
      padding: 10px 0;
    }
    .date-container {
      background: ${({ theme }) => theme.colors.universal.white};
      color: ${({ theme }) => theme.colors.primary};
    }
    .date-container.selected-date {
      background: ${({ theme }) => theme.colors.primary};
      color: ${({ theme }) => theme.colors.universal.white};
    }
    .previous-button,
    .next-button {
      color: ${({ theme }) => theme.colors.primary};
    }
    .title {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;
