import { BoldText } from 'hocs/bold-text';
import React from 'react';
import { IntlShape } from 'react-intl';
import { ORDER_TYPES } from 'shared/consts';
import { Order } from 'store/cart/types';

interface DateValues {
  eventWeekDay: string;
  eventDateMonthLong: string;
  eventDateDay: string;
  formatEventStartTime: string;
  eventDateYear: string;
  eventTimeRange: string;
}

export const getCheckoutSubtitleValues = (
  cart: Order,
  { eventWeekDay, eventDateMonthLong, eventDateDay, formatEventStartTime, eventDateYear, eventTimeRange }: DateValues,
  intl: IntlShape,
) => {
  const itemName = cart.items?.[0]?.display_info.title;

  switch (cart.type) {
    case ORDER_TYPES.SERVICE_BOOKING_MEMBERSHIP:
      return {
        serviceName: itemName,
      };
    case ORDER_TYPES.SERVICE_BOOKING_EVENT:
      return {
        eventName: <BoldText>{itemName}</BoldText>,
        date: <BoldText>{`${eventWeekDay}, ${eventDateMonthLong} ${eventDateDay}, ${eventDateYear}`}</BoldText>,
        timeRange: <BoldText>{eventTimeRange}</BoldText>,
      };
    default:
      return {
        instructorName: cart.items?.[0]?.display_info?.description1,
        serviceName: itemName,
        serviceTime: `${eventWeekDay}, ${eventDateMonthLong} ${eventDateDay}, ${intl.formatMessage({
          id: 'at',
        })} ${formatEventStartTime}`,
      };
  }
};
