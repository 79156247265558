import { DIMENSIONS } from '@hqo/react-components-library';
import styled from 'styled-components';

export const TitleWrapper = styled.div`
  padding: 16px 32px;
  @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    padding-left: 16px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const Title = styled.div`
  font: 700 28px/24px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};

  @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    line-height: 33px;
  }
`;

export const Subtitle = styled.div`
  font: 400 14px/16px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
  margin-top: 5px;
`;
