import styled from 'styled-components';
import { Chip } from '@hqo/react-components-library';

export const DiscountAppliedBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const DiscountAppliedContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const AmountOffText = styled.span`
  font: normal 16px/19px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
  flex-shrink: 0;
  margin-left: 5px;
`;

export const StyledError = styled.div`
  color: ${({ theme }) => theme.colors.admin.pink};
  font: italic 12px/22px ${({ theme }) => theme.fonts.join()};
  margin-bottom: -22px;
`;

export const StyledChip = styled(Chip)`
  padding: 8px 12px 9px 16px;
  gap: 8px;
  background: ${({ theme }) => theme.colors.greys.adminGreyMedium};
  border: none;
  max-width: 60vw;
  white-space: nowrap;
  min-width: 0;

  * {
    color: ${({ theme }) => theme.colors.greys.adminGreyDark};
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    min-width: 16px;
    min-height: 16px;
  }
`;

export const StyledLabel = styled.label`
  font: 14px/22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.greys.adminGreyDark};
  margin-bottom: 10px;
`;
