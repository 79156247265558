import { createAsyncAction, createAction } from 'typesafe-actions';
import { GetRegistrationFields, RegistrationFields, RegisterUser } from './types';
import { FailureActionPayload } from '../errors/types';

export const getRegistrationFields = createAsyncAction(
  'GET_REGISTRATION_FIELDS_REQUEST',
  'GET_REGISTRATION_FIELDS_SUCCESS',
  'GET_REGISTRATION_FIELDS_FAILURE',
)<GetRegistrationFields, RegistrationFields, FailureActionPayload>();

export const registerUser = createAsyncAction(
  'REGISTER_USER_REQUEST',
  'REGISTER_USER_SUCCESS',
  'REGISTER_USER_FAILURE',
)<RegisterUser, [undefined, undefined], FailureActionPayload>();

export const resetRegisterUserStatus = createAction('RESET_REGISTER_USER_STATUS')<undefined>();
