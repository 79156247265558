import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { goBack } from 'connected-react-router';
import { useMiniappSdk } from 'hooks/use-miniapp-sdk.hook';
import { useLocation } from 'react-router-dom';
import { selectInitialRoute } from 'store/routes/selectors';

interface UseEventDetailsNavigationReturnValues {
  handleBackButtonClick: VoidFunction;
}

export const useEventDetailsNavigation = (): UseEventDetailsNavigationReturnValues => {
  const dispatch = useDispatch();
  const client = useMiniappSdk();
  const { pathname } = useLocation();
  const initialRoute = useSelector(selectInitialRoute);

  const handleBackButtonClick = useCallback(() => {
    if (pathname === initialRoute) {
      client.close();
      return;
    }
    dispatch(goBack());
  }, [pathname, initialRoute, client, dispatch]);

  return {
    handleBackButtonClick,
  };
};
