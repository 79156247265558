import { RegistrationField } from 'store/serviceRegistration/types';
import { User } from 'store/user/types';
import { INPUT_LABEL } from 'shared/consts/';

export const formatUserInfo = (user: User, registrationInfo: Array<RegistrationField>) => {
  return [
    ...registrationInfo
      .filter(field => field.value && field.name !== INPUT_LABEL.EMAIL)
      .map(field =>
        field.name === INPUT_LABEL.BIRTH_DATE ? { ...field, value: field.value.replace(/\//g, '-') } : field,
      ),
    { name: INPUT_LABEL.LAST_NAME, value: user.last_name },
    { name: INPUT_LABEL.FIRST_NAME, value: user.first_name },
    { name: INPUT_LABEL.EMAIL, value: user.email },
  ];
};

export const getName = (user: User) => `${user.first_name} ${user.last_name}`;
