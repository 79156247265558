import { GetMemberships, Memberships, Membership } from './types';

import { FailureActionPayload } from 'store/errors/types';
import { createAsyncAction, createAction } from 'typesafe-actions';

export const getMemberships = createAsyncAction(
  'GET_MEMBERSHIPS_REQUEST',
  'GET_MEMBERSHIPS_SUCCESS',
  'GET_MEMBERSHIPS_FAILURE',
)<GetMemberships, Memberships, FailureActionPayload>();

export const setCurrentMembership = createAction('SET_CURRENT_MEMBERSHIP')<Membership>();
