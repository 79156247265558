import styled from 'styled-components';
import { FieldHint } from '@hqo/react-components-library/dist/atoms/field-hint';

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 14px;
  margin-bottom: 26px;
`;

export const CheckboxText = styled.div`
  display: flex;
  flex-direction: column;
  font: 14px/24px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
  margin-bottom: 6px;
`;

export const CheckboxLink = styled.a`
  color: ${({ theme }) => theme.colors.$secondary};
  font: 14px/40px ${({ theme }) => theme.fonts.join()};
  line-height: 0;

  &:hover {
    font-weight: 700;
  }

  &:focus {
    text-decoration: none;
  }
`;

export const StyledFieldHint = styled(FieldHint)`
  padding: 0;
`;
