import { track } from '@hqo/web-tracking';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { TRACK_EVENTS, TRACK_EVENT_TYPES } from 'shared/consts';
import { SelectButton } from './styles';
import { replace } from 'connected-react-router';
import { useSearchParams } from 'hooks/use-search-params.hook';
import qs from 'qs';

interface Props {
  text: string;
  isDisabled: boolean;
  isLoading: boolean;
  url: string;
  setIsModalWithoutSlideAnimation?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SelectPlanButton = ({ text, isDisabled, isLoading, url, setIsModalWithoutSlideAnimation }: Props) => {
  const dispatch = useDispatch();
  const restQueryParams = useSearchParams();

  const handleSelectPlanClick = useCallback(() => {
    if (!isDisabled) {
      setIsModalWithoutSlideAnimation?.(false);
      track(TRACK_EVENTS.CONTRACT_SELECTED_CLICK, { type: TRACK_EVENT_TYPES.ACTION }, { sendToHqoTracking: true });
      dispatch(replace(`${url}?${qs.stringify({ ...restQueryParams, step: 'agreement' })}`));
    }
  }, [dispatch, isDisabled, restQueryParams, url, setIsModalWithoutSlideAnimation]);

  return (
    <SelectButton
      onClick={handleSelectPlanClick}
      data-testid="select-button"
      text={text}
      disabled={isDisabled}
      loading={isLoading}
    />
  );
};
