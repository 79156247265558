import { DateTimeContainer, DateTimeWrapper } from './styles';
import React from 'react';

import { Price } from './components/price';
import { useSelectedDateTime } from './hooks/use-selected-date-time.hook';
import { DateTime } from './components/date-time';

export const SelectedDateTime = (): JSX.Element => {
  const { locale, price, currency, formattedDate, formattedTime } = useSelectedDateTime();

  return (
    <DateTimeWrapper data-testid="selected-date-time">
      <DateTimeContainer>
        <DateTime formattedDate={formattedDate} formattedTime={formattedTime} />
        <Price locale={locale} price={price} currency={currency} />
      </DateTimeContainer>
    </DateTimeWrapper>
  );
};
