import { useMemo } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';

export const useBackButtonVisibility = (): boolean => {
  const isMobileDevice = useIsSmallViewportWidth();
  const { showServiceBookingNavigation: isServiceBookingNavigationVisible } = useFlags();

  return useMemo<boolean>(() => {
    return isServiceBookingNavigationVisible || !isMobileDevice;
  }, [isMobileDevice, isServiceBookingNavigationVisible]);
};
