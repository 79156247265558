/* eslint-disable max-lines-per-function */
import { useEffect, useMemo } from 'react';

import { ACTION_STATUSES, RECEIPT_PATH } from 'shared/consts';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { Service } from 'store/availableServices/types';
import { useOwnerValues } from 'hooks/use-owner-values.hook';
import { ProviderParams } from 'shared/types';
import { selectCurrentAvailableService, selectCurrentAvailableServiceStatus } from 'store/availableServices/selectors';
import { getCurrentAvailableService } from 'store/availableServices/actions';
import { useMiniappSdk } from 'hooks/use-miniapp-sdk.hook';
import { selectGetTransactionsStatus, selectTransaction, selectTransactions } from 'store/transactions/selectors';
import { replace } from 'connected-react-router';
import { useSearchParams } from 'hooks/use-search-params.hook';
import qs from 'qs';
import { getTransactions } from 'store/transactions/actions';

interface UseEventDetailsReturnValues {
  isLoading: boolean;
  service: Service;
  isUserBooked: boolean;
  showCancelEventModal: boolean;
}

export const useEventDetails = (): UseEventDetailsReturnValues => {
  const dispatch = useDispatch();
  const { ownerType, ownerUuid } = useOwnerValues();
  const { companyUuid, serviceUuid } = useParams<ProviderParams>();
  const client = useMiniappSdk();
  const transaction = useSelector(selectTransaction);
  const transactions = useSelector(selectTransactions);
  const getTransactionsStatus = useSelector(selectGetTransactionsStatus);
  const { pathname } = useLocation();
  const params = useSearchParams();
  const service = useSelector(selectCurrentAvailableService);
  const getServiceStatus = useSelector(selectCurrentAvailableServiceStatus);
  const showCancelEventModal = Boolean(params?.confirmCancel) && !pathname.includes(RECEIPT_PATH);

  const isLoading = useMemo<boolean>(() => {
    return !(getServiceStatus === ACTION_STATUSES.FULFILLED && getTransactionsStatus === ACTION_STATUSES.FULFILLED);
  }, [getServiceStatus, getTransactionsStatus]);

  const isUserBooked = useMemo(() => {
    return (
      transaction?.details?.service_id === service?.id?.toString() ||
      transactions?.some(findTransaction => findTransaction.details?.service_id === service?.id?.toString())
    );
  }, [transaction, transactions, service]);

  useEffect(() => {
    if (!service && getServiceStatus !== ACTION_STATUSES.PENDING) {
      dispatch(getCurrentAvailableService.request({ ownerType, ownerUuid, companyUuid, serviceUuid }));
    }
  }, [ownerType, ownerUuid, companyUuid, serviceUuid, service, getServiceStatus, dispatch]);

  useEffect(() => {
    if (service && !transactions && getTransactionsStatus !== ACTION_STATUSES.PENDING) {
      dispatch(getTransactions.request());
    }
  }, [transactions, service, getTransactionsStatus]);

  useEffect(() => {
    if (service && transaction && !pathname.includes(RECEIPT_PATH)) {
      const queryParams = qs.stringify(params);
      dispatch(
        replace(`${pathname.replace('/checkout', '').replace('/quick-checkout', '')}${RECEIPT_PATH}?${queryParams}`),
      );
      dispatch(getTransactions.request());
    }
    // we don't want to listen for pathname changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction, service]);

  useEffect(() => {
    client.header.hideHeader();

    return () => {
      client.header.showHeader();
    };
  }, [client, pathname]);

  return {
    service,
    isLoading,
    isUserBooked,
    showCancelEventModal,
  };
};
