import React from 'react';
import { StyledFormField } from './styles';
import { useIntl } from 'react-intl';

interface FormProps {
  setName: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  hasError: boolean;
}

export const FieldName = ({ setName, name, hasError }: FormProps) => {
  const { formatMessage } = useIntl();
  return (
    <StyledFormField
      onChange={setName}
      value={name}
      type="text"
      label={formatMessage({ id: 'full_name' })}
      placeholder={formatMessage({ id: 'full_name_placeholder' })}
      required
      errorText={hasError && formatMessage({ id: 'please_enter_full_name' })}
      dataTestId="field-name"
    />
  );
};
