import * as actions from './actions';
import { ActionType, createReducer } from 'typesafe-actions';
import { RoutesState } from './types';

const initialState: RoutesState = {
  initialRoute: null,
};

const updateInitialRouteHandler = (
  state: RoutesState,
  { payload }: ActionType<typeof actions.updateInitialRoute>,
): RoutesState => ({
  ...state,
  initialRoute: payload,
});

const resetInitialRouteHandler = (state: RoutesState): RoutesState => ({
  ...state,
  initialRoute: null,
});

const routesReducer = createReducer(initialState)
  .handleAction(actions.updateInitialRoute, updateInitialRouteHandler)
  .handleAction(actions.resetInitialRoute, resetInitialRouteHandler);

export default routesReducer;
