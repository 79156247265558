import React from 'react';
import { ModalContentContainer, ModalContentTitle, ModalContentSubtitle } from './styles';
import { ExclamationMark } from '@hqo/react-components-library';
import { useIntl } from 'react-intl';

export const ContentModal = (): JSX.Element => {
  const intl = useIntl();
  return (
    <ModalContentContainer>
      <ExclamationMark size="lg" variant="pink" />
      <ModalContentTitle>{intl.formatMessage({ id: 'leave_checkout' })}</ModalContentTitle>
      <ModalContentSubtitle>{intl.formatMessage({ id: 'it_not_saved' })}</ModalContentSubtitle>
    </ModalContentContainer>
  );
};
