import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { resetCart, resetGetCartStatus, resetSubmitCartStatus } from 'store/cart/actions';
import { replace, push } from 'connected-react-router';
import { ACTION_STATUSES } from 'shared/consts';
import { selectCartId, selectCreateCartStatus } from 'store/cart/selectors';
import { useSearchParams } from '../use-search-params.hook';
import { searchParams } from 'components/payment/checkout/types';
import qs from 'qs';
import { selectInitialRoute } from 'store/routes/selectors';
import { configSelector } from 'store/config/selectors';
import { useLocale } from '../use-locale.hook';

interface UseCheckoutScreenReturnValues {
  onCloseCheckout: VoidFunction;
  openCheckout: VoidFunction;
}

// eslint-disable-next-line max-lines-per-function
export const useCheckoutScreen = (): UseCheckoutScreenReturnValues => {
  const dispatch = useDispatch();
  const { isOpenFromRedirect, ...queryParams } = useSearchParams<searchParams>();
  const { pathname, search } = useLocation();
  const { buildingUuid } = useSelector(configSelector);
  const locale = useLocale();
  const cartId = useSelector(selectCartId);
  const createCartStatus = useSelector(selectCreateCartStatus);
  const initialRoute = useSelector(selectInitialRoute);

  const onCloseCheckout = useCallback(() => {
    if (isOpenFromRedirect === 'true') {
      dispatch(replace(`${initialRoute}?locale=${locale}&buildingUuid=${buildingUuid}`));
    } else {
      dispatch(replace(`${pathname.replace('/checkout', '')}${search}`));
    }
    dispatch(resetSubmitCartStatus());
    dispatch(resetGetCartStatus());
    dispatch(resetCart());
  }, [dispatch, isOpenFromRedirect, initialRoute, locale, buildingUuid]);

  const openCheckout = useCallback(() => {
    dispatch(resetGetCartStatus());
    if (pathname.includes('quick-checkout')) {
      dispatch(replace(`${pathname.replace('quick-checkout', 'checkout')}${search}`));
    } else {
      dispatch(push(`${pathname}/checkout${search}`));
    }
  }, [dispatch, pathname]);

  useEffect(() => {
    const queryString = qs.stringify({ ...queryParams, cartId });
    if (createCartStatus === ACTION_STATUSES.FULFILLED && pathname.includes('register-contract')) {
      dispatch(resetGetCartStatus());
      dispatch(replace(`${pathname}/checkout?${queryString}`));
    }
  }, [createCartStatus, cartId]);

  return {
    onCloseCheckout,
    openCheckout,
  };
};
