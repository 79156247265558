import { PromoCodeInputContent } from './promo-code-input-content';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { PromoCodeInputWrapper, ApplyButton } from 'components/promo-code-input/styles';

interface PromoCodeInputProps {
  applyPromoCode: (promoCode: string) => void;
  hasError?: boolean;
  setHasError?: Dispatch<SetStateAction<boolean>>;
  isDisabled?: boolean;
}

export const PromoCodeInput = ({
  applyPromoCode,
  hasError,
  setHasError,
  isDisabled,
}: PromoCodeInputProps): JSX.Element => {
  const intl = useIntl();
  const [promoCode, setPromoCode] = useState<string>('');

  const handleOnClick = useCallback(() => {
    if (promoCode.trim().length) {
      applyPromoCode(promoCode.trim());
    }
  }, [applyPromoCode, promoCode]);

  useEffect(() => {
    if (hasError) {
      setHasError(false);
    }
  }, [promoCode]);

  return (
    <PromoCodeInputWrapper>
      <PromoCodeInputContent
        dataTestId="promo-code-input-content"
        hasError={hasError}
        updatePromoCode={setPromoCode}
        handleOnEnter={handleOnClick}
        promoCode={promoCode}
      />
      {!!promoCode.trim().length && (
        <ApplyButton
          data-testid="apply-button"
          onClick={handleOnClick}
          disabled={isDisabled}
          text={intl.formatMessage({ id: 'apply' })}
        />
      )}
    </PromoCodeInputWrapper>
  );
};
