import { ServiceTranslations } from 'store/availableServices/types';

export const getTranslationValueforCTALabel = (
  locale: string,
  translations: Record<string, ServiceTranslations>,
): string => {
  if (!translations) {
    return 'Book';
  }

  const [localePrefix] = locale.split('-');
  const translationKey = Object.keys(translations).find(key => key.startsWith(localePrefix));

  return translations[translationKey]?.cta_label || 'Book';
};
