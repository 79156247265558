import { TIMEKIT_BOOKING_ERROR_MESSAGE } from 'shared/consts/errors';
import { PaymentError, PaymentErrorsEnum } from 'shared/consts/payment-error-types';

export const getCartErrorMessage = (cartError: PaymentError): string => {
  if (cartError?.response?.message?.message?.includes(TIMEKIT_BOOKING_ERROR_MESSAGE)) {
    return 'problem_booking_appointment';
  }
  switch (cartError?.response?.message?.key) {
    case PaymentErrorsEnum.BOOKING_UNAVAILABLE_ERROR:
      return 'booking_unavailable_error';
    case PaymentErrorsEnum.CARD_DECLINED_ERROR:
      return 'card_declined_error';
    case PaymentErrorsEnum.CART_SUBMISSION_ERROR:
      return 'cart_submission_error';
    case PaymentErrorsEnum.PAYMENT_PROCESSING_ERROR:
      return 'payment_processing_error';
    default:
      return 'cart_submission_error';
  }
};
