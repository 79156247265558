/* eslint-disable no-empty-function */
import { AjaxResponse, ajax } from 'rxjs/ajax';
import {
  AvailableServiceResponse,
  AvailableServicesResponse,
  SERVICE_TYPE,
  ServiceTypesResponse,
} from 'store/availableServices/types';
import {
  ApplyPromoCodeToCartApiResponse,
  Complete3DSCartApiResponse,
  CompleteCartDto,
  CreateCartAPIResponse,
  CreateCartDto,
  GetCartApiResponse,
  PromoCodeCartRequestParams,
  RemovePromoCodeFromCartApiResponse,
  SubmitCartApiResponse,
  SubmitOrderDto,
} from 'store/cart/types';
import { RegisterUser, RegistrationFieldsResponse } from 'store/serviceRegistration/types';
import { ServiceProviderResponse, ServiceProvidersResponse } from 'store/serviceProviders/types';

import { API_PATHS } from 'shared/consts/paths';
import { BuildingResponse } from 'store/building/types';
import { BuildingTheme } from 'store/theme/types';
import { CurrentUserResponse } from 'store/user/types';
import { Memberships } from 'store/serviceMemberships/types';
import { Observable } from 'rxjs';
import qs, { stringify } from 'qs';
import {
  GetTransactionResponse,
  GetTransactionsAPIResponse,
  PatchTransactionDto,
  RedirectTransactionResponse,
} from 'store/transactions/types';
import {
  SavedPaymentMethod,
  SavePaymentMethodRequest,
  SavePaymentMethodResponse,
  UpdatePaymentMethodRequest,
} from 'store/payment/types';

export class ApiClient {
  constructor(
    private baseUrl: string,
    private authToken?: string | null,
    private buildingUuid?: string | null,
    private locale?: string | null,
  ) {}

  private get DefaultHeaders(): Record<string, string> {
    return {
      'Content-Type': 'application/json',
      Authorization: this.authToken,
      'Hqo-Building-UUID': this.buildingUuid,
    };
  }

  private get DefaultParams(): Record<string, string> {
    return {
      lang: this.locale,
    };
  }

  getCurrentUser(): Observable<AjaxResponse<CurrentUserResponse>> {
    return ajax.get(`${this.baseUrl}/api/users/current`, this.DefaultHeaders);
  }

  getBuildingTheme(buildingUuid: string): Observable<AjaxResponse<BuildingTheme>> {
    return ajax.get(`${this.baseUrl}/api/buildings/${buildingUuid}/theme`, this.DefaultHeaders);
  }

  getBuilding(buildingUuid: string): Observable<AjaxResponse<BuildingResponse>> {
    return ajax.get(`${this.baseUrl}/api/tenant/v1/buildings/${buildingUuid}`, this.DefaultHeaders);
  }

  getServiceProviders(ownerType: string, ownerUuid: string): Observable<AjaxResponse<ServiceProvidersResponse>> {
    return ajax.get(
      `${this.baseUrl}/api${API_PATHS.SERVICE_BOOKING}/${ownerType}/${ownerUuid}/providers`,
      this.DefaultHeaders,
    );
  }

  getServiceProvidersV3(ownerType: string, ownerUuid: string): Observable<AjaxResponse<ServiceProvidersResponse>> {
    return ajax.get(
      `${this.baseUrl}/api${API_PATHS.SERVICE_BOOKING_V3}/${ownerType}/${ownerUuid}/providers`,
      this.DefaultHeaders,
    );
  }

  getCurrentServiceProviderV3(
    ownerType: string,
    ownerUuid: string,
    companyUuid: string,
  ): Observable<AjaxResponse<ServiceProviderResponse>> {
    return ajax.get(
      `${this.baseUrl}/api${API_PATHS.SERVICE_BOOKING_V3}/${ownerType}/${ownerUuid}/providers/${companyUuid}`,
      this.DefaultHeaders,
    );
  }

  getRegistrationFields(ownerType: string, ownerUuid: string): Observable<AjaxResponse<RegistrationFieldsResponse>> {
    return ajax.get(
      `${this.baseUrl}/api/service-booking/v2/${ownerType}/${ownerUuid}/registration`,
      this.DefaultHeaders,
    );
  }

  getRegistrationFieldsV3(
    ownerType: string,
    ownerUuid: string,
    companyUuid: string,
  ): Observable<AjaxResponse<RegistrationFieldsResponse>> {
    return ajax.get(
      `${this.baseUrl}/api${API_PATHS.SERVICE_BOOKING_V3}/${ownerType}/${ownerUuid}/providers/${companyUuid}/registration`,
      this.DefaultHeaders,
    );
  }

  registerUser({ ownerType, ownerUuid, registerUserDto }: RegisterUser): Observable<AjaxResponse<unknown>> {
    return ajax.post(
      `${this.baseUrl}/api/service-booking/v2/${ownerType}/${ownerUuid}/registration`,
      { user_info: registerUserDto },
      this.DefaultHeaders,
    );
  }

  registerUserV3({
    ownerType,
    ownerUuid,
    companyUuid,
    registerUserDto,
  }: RegisterUser): Observable<AjaxResponse<unknown>> {
    return ajax.post(
      `${this.baseUrl}/api${API_PATHS.SERVICE_BOOKING_V3}/${ownerType}/${ownerUuid}/providers/${companyUuid}/registration`,
      { user_info: registerUserDto },
      this.DefaultHeaders,
    );
  }

  getMemberships(ownerType: string, ownerUuid: string): Observable<AjaxResponse<Memberships>> {
    return ajax.get(
      `${this.baseUrl}/api/service-booking/v2/${ownerType}/${ownerUuid}/memberships`,
      this.DefaultHeaders,
    );
  }

  getMembershipsV3(ownerType: string, ownerUuid: string, companyUuid: string): Observable<AjaxResponse<Memberships>> {
    return ajax.get(
      `${this.baseUrl}/api${API_PATHS.SERVICE_BOOKING_V3}/${ownerType}/${ownerUuid}/providers/${companyUuid}/memberships`,
      this.DefaultHeaders,
    );
  }

  getAvailableServices(
    ownerType: string,
    ownerUuid: string,
    availability_start?: string,
    availability_end?: string,
    service_type_id?: string,
  ): Observable<AjaxResponse<AvailableServicesResponse>> {
    const url = `${this.baseUrl}/api/service-booking/v2/${ownerType}/${ownerUuid}/services?${qs.stringify(
      { availability_start, availability_end, service_type_id },
      { skipNulls: true, encode: false },
    )}`;

    return ajax.get(url, this.DefaultHeaders);
  }

  getAvailableServicesV3(
    ownerType: string,
    ownerUuid: string,
    companyUuid: string,
    availability_start?: string,
    availability_end?: string,
    service_type_id?: string,
  ): Observable<AjaxResponse<AvailableServicesResponse>> {
    const url = `${this.baseUrl}/api${
      API_PATHS.SERVICE_BOOKING_V3
    }/${ownerType}/${ownerUuid}/providers/${companyUuid}/services?${qs.stringify(
      { availability_start, availability_end, service_type_id },
      { skipNulls: true, encode: false },
    )}`;
    return ajax.get(url, this.DefaultHeaders);
  }

  getCurrentAvailableService(
    ownerType: string,
    ownerUuid: string,
    serviceUuid: string,
    availability_start: string,
    availability_end: string,
    service_type_id?: string,
  ): Observable<AjaxResponse<AvailableServiceResponse>> {
    const url = `${
      this.baseUrl
    }/api/service-booking/v2/${ownerType}/${ownerUuid}/services/${serviceUuid}?${qs.stringify(
      { availability_start, availability_end, service_type_id },
      { skipNulls: true, encode: false },
    )}`;

    return ajax.get(url, this.DefaultHeaders);
  }

  getCurrentAvailableServiceV3(
    ownerType: string,
    ownerUuid: string,
    companyUuid: string,
    serviceUuid: string,
    availability_start: string,
    availability_end: string,
    service_type_id?: string,
  ): Observable<AjaxResponse<AvailableServiceResponse>> {
    const url = `${this.baseUrl}/api${
      API_PATHS.SERVICE_BOOKING_V3
    }/${ownerType}/${ownerUuid}/providers/${companyUuid}/services/${serviceUuid}?${qs.stringify(
      { availability_start, availability_end, service_type_id, include: 'translations' },
      { skipNulls: true, encode: false },
    )}`;
    return ajax.get(url, this.DefaultHeaders);
  }

  getServiceTypes(
    ownerType: string,
    ownerUuid: string,
    serviceType?: SERVICE_TYPE,
  ): Observable<AjaxResponse<ServiceTypesResponse>> {
    const url = `${this.baseUrl}/api/service-booking/v2/${ownerType}/${ownerUuid}/servicetypes?${qs.stringify(
      { serviceType },
      { skipNulls: true },
    )}`;
    return ajax.get(url, this.DefaultHeaders);
  }

  getServiceTypesV3(
    ownerType: string,
    ownerUuid: string,
    companyUuid: string,
  ): Observable<AjaxResponse<ServiceTypesResponse>> {
    const url = `${this.baseUrl}/api${API_PATHS.SERVICE_BOOKING_V3}/${ownerType}/${ownerUuid}/providers/${companyUuid}/servicetypes`;
    return ajax.get(url, this.DefaultHeaders);
  }

  createCart(createCartDto: CreateCartDto): Observable<AjaxResponse<CreateCartAPIResponse>> {
    const { app_instance_config_uuid, ...dto } = createCartDto;

    return ajax.post(
      `${this.baseUrl}/api/payments/v1/carts?${stringify({
        appInstanceConfigUuid: app_instance_config_uuid,
      })}`,
      dto,
      this.DefaultHeaders,
    );
  }

  getTransactions(): Observable<AjaxResponse<GetTransactionsAPIResponse>> {
    return ajax.get(
      `${
        this.baseUrl
      }/api/tenant/v1/users/current/transactions?types=services_booking&is_upcoming=true&statuses=waitlisted,confirmed,Booked&${stringify(
        {
          skipNulls: true,
        },
      )}`,
      this.DefaultHeaders,
    );
  }

  patchTransaction(patchTransactionDto: PatchTransactionDto) {
    const { transactionId, ...body } = patchTransactionDto;

    return ajax.patch(`${this.baseUrl}/api/transactions/${transactionId}`, body, this.DefaultHeaders);
  }

  getCart(cartId: string): Observable<AjaxResponse<GetCartApiResponse>> {
    return ajax.get(`${this.baseUrl}/api/payments/v1/carts/${cartId}`, this.DefaultHeaders);
  }

  submitCart(submitCartDto: SubmitOrderDto): Observable<AjaxResponse<SubmitCartApiResponse>> {
    const params = stringify(
      submitCartDto.locationId
        ? { locationId: submitCartDto.locationId, ...this.DefaultParams }
        : { ...this.DefaultParams },
    );

    return ajax.post(
      `${this.baseUrl}/api/payments/v1/carts/${submitCartDto.cart_id}?${params}`,
      { ...submitCartDto, cart_id: undefined, locationId: undefined },
      this.DefaultHeaders,
    );
  }

  applyPromoCodeToCart(payload: PromoCodeCartRequestParams): Observable<AjaxResponse<ApplyPromoCodeToCartApiResponse>> {
    return ajax.post(
      `${this.baseUrl}/api/payments/v1/carts/${payload.cartId}/promotions/${payload.promoCode}`,
      null,
      this.DefaultHeaders,
    );
  }

  removePromoCodeFromCart(
    payload: PromoCodeCartRequestParams,
  ): Observable<AjaxResponse<RemovePromoCodeFromCartApiResponse>> {
    return ajax.delete(
      `${this.baseUrl}/api/payments/v1/carts/${payload.cartId}/promotions/${payload.promoCode}`,
      this.DefaultHeaders,
    );
  }

  complete3DSCart(payload: CompleteCartDto): Observable<AjaxResponse<Complete3DSCartApiResponse>> {
    return ajax.post(`${this.baseUrl}/api/payments/complete-3ds`, payload, this.DefaultHeaders);
  }

  deletePaymentMethod(cardId: string): Observable<AjaxResponse<[undefined, undefined]>> {
    return ajax.delete(`${this.baseUrl}/api/payment-methods/${cardId}`, this.DefaultHeaders);
  }

  updatePaymentMethod(
    updatePaymentMethodRequest: UpdatePaymentMethodRequest,
  ): Observable<AjaxResponse<SavePaymentMethodResponse>> {
    return ajax.patch(
      `${this.baseUrl}/api/payment-methods/${updatePaymentMethodRequest.card_id}`,
      { default: updatePaymentMethodRequest.default },
      this.DefaultHeaders,
    );
  }

  getPaymentMethods(cartId: string): Observable<AjaxResponse<Array<SavedPaymentMethod>>> {
    const params = stringify({ cartId });

    return ajax.get(`${this.baseUrl}/api/payment-methods?${params}`, this.DefaultHeaders);
  }

  savePaymentMethod(
    savePaymentMethodRequest: SavePaymentMethodRequest,
  ): Observable<AjaxResponse<SavePaymentMethodResponse>> {
    return ajax.post(`${this.baseUrl}/api/payment-methods`, savePaymentMethodRequest, this.DefaultHeaders);
  }

  getRedirectTransaction(transactionUuid: string): Observable<AjaxResponse<RedirectTransactionResponse>> {
    return ajax.get(`${this.baseUrl}/api/payments/transaction/${transactionUuid}`, this.DefaultHeaders);
  }

  getTransaction(transactionUuid: string): Observable<AjaxResponse<GetTransactionResponse>> {
    return ajax.get(`${this.baseUrl}/api/tenant/v1/users/current/transactions/${transactionUuid}`, this.DefaultHeaders);
  }
}
