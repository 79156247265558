import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo } from 'react';
import { selectPatchTransactionStatus, selectTransaction, selectTransactions } from 'store/transactions/selectors';
import { getTransactions, patchTransaction, resetPatchTransaction, resetTransaction } from 'store/transactions/actions';
import { ACTION_STATUSES, PATCH_STATUSES } from 'shared/consts';
import { useLocation } from 'react-router';
import { useSearchParams } from 'hooks/use-search-params.hook';
import qs from 'qs';
import { replace } from 'connected-react-router';
import { useOwnerValues } from 'hooks/use-owner-values.hook';
import { useParams } from 'react-router-dom';
import { ProviderParams } from 'shared/types';
import { getCurrentAvailableService } from 'store/availableServices/actions';
import { selectCurrentAvailableService } from 'store/availableServices/selectors';

interface UseButtonsBlockReturnValues {
  handleCancelEvent: VoidFunction;
  handleCloseModal: VoidFunction;
  isLoadingState: boolean;
}

// eslint-disable-next-line max-lines-per-function
export const useButtonsBlock = (): UseButtonsBlockReturnValues => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { ownerType, ownerUuid } = useOwnerValues();
  const { companyUuid, serviceUuid } = useParams<ProviderParams>();
  const transaction = useSelector(selectTransaction);
  const transactions = useSelector(selectTransactions);
  const service = useSelector(selectCurrentAvailableService);
  const patchTransactionStatus = useSelector(selectPatchTransactionStatus);
  const { confirmCancel, ...restQueryParams } = useSearchParams();
  const queryString = qs.stringify({ ...restQueryParams });
  const isLoadingState = patchTransactionStatus === ACTION_STATUSES.PENDING;

  const selectedTransaction = useMemo(() => {
    return transaction?.details?.service_id === service?.id?.toString()
      ? transaction
      : transactions?.find(findTransaction => findTransaction.details?.service_id === service?.id?.toString());
  }, [transaction, transactions, service, patchTransactionStatus]);

  const { id } = selectedTransaction || {};

  const handleCloseModal = useCallback(() => {
    dispatch(replace(`${pathname}?${queryString}`));
  }, [dispatch, queryString, pathname, confirmCancel]);

  useEffect(() => {
    if (patchTransactionStatus === ACTION_STATUSES.FULFILLED) {
      dispatch(replace(`${pathname}?${queryString}`));
      dispatch(getCurrentAvailableService.request({ ownerType, ownerUuid, companyUuid, serviceUuid }));
      dispatch(getTransactions.request());
      dispatch(resetPatchTransaction());
      dispatch(resetTransaction());
    }
  }, [
    dispatch,
    patchTransactionStatus,
    pathname,
    queryString,
    ownerType,
    confirmCancel,
    ownerUuid,
    companyUuid,
    serviceUuid,
  ]);

  const handleCancelEvent = useCallback(() => {
    dispatch(
      patchTransaction.request({
        transactionId: id?.toString(),
        status: PATCH_STATUSES.CANCELLED,
      }),
    );
  }, [dispatch, id]);

  return {
    handleCancelEvent,
    handleCloseModal,
    isLoadingState,
  };
};
