import { setFeatureFlags } from './actions';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';
import { createReducer, ActionType } from 'typesafe-actions';

const initialState: LDFlagSet = null;

const setFeatureFlagsHandler = (state: LDFlagSet, { payload }: ActionType<typeof setFeatureFlags>): LDFlagSet => ({
  ...state,
  ...payload,
});

const featureFlagsReducer = createReducer(initialState).handleAction(setFeatureFlags, setFeatureFlagsHandler);

export default featureFlagsReducer;
