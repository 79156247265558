import { currentUser, hasToken as hasTokenSelector } from 'store/user/selectors';
import { useDispatch, useSelector } from 'react-redux';

import { User } from 'store/user/types';
import { getCurrentUser } from 'store/user/actions';
import { useEffect } from 'react';
import { USER_AGENT } from 'shared/consts';

// eslint-disable-next-line max-lines-per-function
export const useCurrentUser = (): [User | null, boolean] => {
  const user = useSelector(currentUser);
  const hasToken = useSelector(hasTokenSelector);
  const dispatch = useDispatch();
  const isDesktopApp =
    navigator.userAgent.toLowerCase().includes(USER_AGENT.CHROME) ||
    navigator.userAgent.toLowerCase().includes(USER_AGENT.SAFARI);

  useEffect(() => {
    let getUserTimeout: NodeJS.Timeout;

    if (!isDesktopApp) {
      if (!user && hasToken) {
        dispatch(getCurrentUser.request());
      }
    } else {
      getUserTimeout = setTimeout(() => {
        if (!user && hasToken) {
          dispatch(getCurrentUser.request());
        }
      }, 1000);
    }

    return () => {
      clearTimeout(getUserTimeout);
    };
  }, [user, hasToken, dispatch, isDesktopApp]);

  return [user, !user && hasToken];
};
