import * as actions from './actions';

import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { isActionOf } from 'typesafe-actions';
import { Observable, of, concat } from 'rxjs';
import { getCurrentServiceProvider } from 'store/serviceProviders/actions';
import { selectFeatureFlags } from 'store/featureFlags/selectors';
import { OwnerType } from 'shared/consts';
import { selectCurrentServiceProvider } from 'store/serviceProviders/selectors';
import { configSelector } from 'store/config/selectors';

export const getRegistrationFieldsEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getRegistrationFields.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      const { ownerType, ownerUuid, companyUuid } = payload;
      const { serviceBookingUsingV3Endpoints } = selectFeatureFlags(state);
      const { owner_uuid: buildingCompanyOwnerUuid } = selectCurrentServiceProvider(state);
      let ajaxResponse;
      if (serviceBookingUsingV3Endpoints) {
        ajaxResponse = apiClient(state).getRegistrationFieldsV3(ownerType, ownerUuid, companyUuid);
      } else {
        ajaxResponse = apiClient(state).getRegistrationFields(OwnerType.BUILDING_COMPANY, buildingCompanyOwnerUuid);
      }
      return ajaxResponse.pipe(
        map(xhrPayload => {
          return actions.getRegistrationFields.success(xhrPayload.response.data);
        }),
        catchError((error: Error) => of(actions.getRegistrationFields.failure({ error }))),
      );
    }),
  );

// eslint-disable-next-line max-lines-per-function
export const registerUserEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.registerUser.request)),
    withLatestFrom(state$),
    // eslint-disable-next-line max-lines-per-function
    switchMap(([{ payload }, state]): Observable<RootAction> => {
      const { ownerUuid, ownerType, companyUuid, registerUserDto } = payload;
      const { buildingUuid } = configSelector(state);
      const { serviceBookingUsingV3Endpoints } = selectFeatureFlags(state);
      const { owner_uuid: buildingCompanyOwnerUuid } = selectCurrentServiceProvider(state);
      let ajaxResponse;
      if (serviceBookingUsingV3Endpoints) {
        ajaxResponse = apiClient(state).registerUserV3({ ownerUuid, ownerType, companyUuid, registerUserDto });
      } else {
        ajaxResponse = apiClient(state).registerUser({
          ownerUuid: buildingCompanyOwnerUuid,
          ownerType: OwnerType.BUILDING_COMPANY,
          registerUserDto,
        });
      }
      return ajaxResponse.pipe(
        switchMap(() =>
          concat(
            of(actions.registerUser.success()),
            of(
              getCurrentServiceProvider.request({
                ownerUuid: buildingUuid,
                ownerType: OwnerType.BUILDING,
                companyUuid,
              }),
            ),
          ),
        ),
        catchError((error: Error) => of(actions.registerUser.failure({ error }))),
      );
    }),
  );
