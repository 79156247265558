import * as actions from './actions';

import { catchError, filter, map, switchMap, withLatestFrom, mergeMap } from 'rxjs/operators';

import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { isActionOf } from 'typesafe-actions';
import { of, concat } from 'rxjs';
import { PATCH_STATUSES } from 'shared/consts';

export const getTransactionsEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getTransactions.request)),
    withLatestFrom(state$),
    switchMap(([, state]) => {
      return apiClient(state)
        .getTransactions()
        .pipe(
          map(xhrPayload => {
            return actions.getTransactions.success({
              transactions: xhrPayload.response.data.transactions,
            });
          }),
          catchError((error: Error) => of(actions.getTransactions.failure({ error }))),
        );
    }),
  );

export const getRedirectTransactionEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getRedirectTransaction.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      return apiClient(state)
        .getRedirectTransaction(payload)
        .pipe(
          map(xhrPayload => {
            return actions.getRedirectTransaction.success(xhrPayload.response.data.transaction);
          }),
          catchError((error: Error) => of(actions.getRedirectTransaction.failure({ error }))),
        );
    }),
  );

export const getTransactionEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getTransaction.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      return apiClient(state)
        .getTransaction(payload)
        .pipe(
          map(xhrPayload => {
            return actions.getTransaction.success(xhrPayload.response.data);
          }),
          catchError((error: Error) => of(actions.getTransaction.failure({ error }))),
        );
    }),
  );

export const patchTransactionEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.patchTransaction.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      return apiClient(state)
        .patchTransaction(payload)
        .pipe(
          mergeMap(() => {
            return payload.status === PATCH_STATUSES.CANCELLED
              ? of(actions.patchTransaction.success())
              : concat(of(actions.patchTransaction.success()), of(actions.getTransactions.request()));
          }),
          catchError((error: Error) => of(actions.patchTransaction.failure({ error }))),
        );
    }),
  );
