import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { selectInitialRoute } from 'store/routes/selectors';
import { updateInitialRoute } from 'store/routes/actions';
import { DEFAULT_PATH, ROOT_PATH, SERVICE_BOOKING_PATH } from 'shared/consts';
import { formatInitialPath } from 'utils/formatPath';

export const useInitialRoute = (): string => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const initialRoute = useSelector(selectInitialRoute);
  const currentRoute = useMemo<string>(
    () => (pathname === DEFAULT_PATH || pathname === SERVICE_BOOKING_PATH ? ROOT_PATH : formatInitialPath(pathname)),
    [pathname],
  );

  useEffect(() => {
    if (!initialRoute) {
      dispatch(updateInitialRoute(currentRoute));
    }
  }, [dispatch, initialRoute]);

  return currentRoute;
};
