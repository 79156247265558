import React from 'react';
import { ButtonsContainer, StyledButton } from './styles';
import { useIntl } from 'react-intl';
import { useButtonsBlock } from './hooks/use-buttons-block.hook';

interface ButtonsBlockProps {
  ctaLabel: string;
}

export const ButtonsBlock = ({ ctaLabel }: ButtonsBlockProps) => {
  const intl = useIntl();
  const cancelButtonText = intl.formatMessage({ id: `cancel_${ctaLabel.toLowerCase()}` });
  const { handleCancelEvent, handleCloseModal, isLoadingState } = useButtonsBlock();

  return (
    <ButtonsContainer>
      <StyledButton
        data-testid="go-back-button"
        text={intl.formatMessage({ id: 'nevermind' })}
        onClick={handleCloseModal}
        disabled={isLoadingState}
        variant="Outline"
      />
      <StyledButton
        data-testid="cancel-button"
        text={cancelButtonText}
        onClick={handleCancelEvent}
        loading={isLoadingState}
        disabled={isLoadingState}
        variant="Destructive"
      />
    </ButtonsContainer>
  );
};
