export interface GetRegistrationFields {
  ownerUuid: string;
  ownerType: string;
  companyUuid: string;
}

// TODO: companyUuid will be changed to required during cleanup in this ticket https://ventureapp.atlassian.net/browse/MAIN-29607
export interface RegisterUser {
  ownerUuid: string;
  ownerType: string;
  companyUuid?: string;
  registerUserDto: Array<RegistrationField>;
}

export enum DATATYPE_REGISTRATION_FIELD {
  STRING = 'String',
  NUMBER = 'Number',
  BOOLEAN = 'Boolean',
}

export interface RegistrationFields {
  required_fields: Array<RegistrationField>;
  other_fields: Array<RegistrationField>;
  waiver_url: string;
}

export interface RegistrationFieldsResponse {
  data: {
    required_fields: Array<RegistrationField>;
    other_fields: Array<RegistrationField>;
    waiver_url: string;
  };
}

export interface RegistrationField {
  id?: string;
  name: string;
  datatype?: DATATYPE_REGISTRATION_FIELD;
  required?: boolean;
  suggested_text?: string;
  value?: string;
}

export interface ServiceRegistrationState {
  registrationFields: RegistrationFields;
  getRegistrationFields: AsyncActionTypes;
  registerUser: AsyncActionTypes;
}
