import { FooterContainer, CtaButton, ButtonContainer, ButtonWrapper, DateTimeContainer } from './styles';
import React from 'react';
import { SelectedDateTime } from '../selected-date-time/selected-date-time';
import { Service } from 'store/availableServices/types';
import { useCtaButton } from './hooks/useCtaButton.hook';
import { Registration } from 'components/footer/registration';

interface FooterProps {
  service: Service;
  ctaLabel: string;
}

export const Footer = ({ service, ctaLabel }: FooterProps): JSX.Element => {
  const { handleClick, isButtonLoadingAndDisabled, processedCtaLabel, ctaVariant } = useCtaButton(service, ctaLabel);
  const isRegistrationOpen = new Date(service.bookable_start) > new Date();

  return (
    <FooterContainer data-testid="event-details-footer">
      <ButtonContainer>
        <ButtonWrapper>
          <DateTimeContainer>
            {!!service.price && <SelectedDateTime />}
            <CtaButton
              disabled={isButtonLoadingAndDisabled || isRegistrationOpen}
              loading={isButtonLoadingAndDisabled}
              text={processedCtaLabel}
              onClick={handleClick}
              data-testid="book-button"
              variant={ctaVariant}
            />
            {isRegistrationOpen && <Registration bookableStart={service.bookable_start} />}
          </DateTimeContainer>
        </ButtonWrapper>
      </ButtonContainer>
    </FooterContainer>
  );
};
