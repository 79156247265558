import { createAction, createAsyncAction } from 'typesafe-actions';
import {
  SpreedlyCreditCardConfig,
  SpreedlyError,
  SpreedlyInitConfig,
  SpreedlyPaymentMethodData,
  SpreedlyValidationInputProperties,
  IFrameErrorSpreedly,
} from './types';

export const initializeSpreedly = createAsyncAction(
  'INITIALIZE_SPREEDLY_REQUEST',
  'INITIALIZE_SPREEDLY_SUCCESS',
  'INITIALIZE_SPREEDLY_FAILURE',
)<SpreedlyInitConfig, void, IFrameErrorSpreedly>();

export const reloadSpreedly = createAsyncAction(
  'RELOAD_SPREEDLY_REQUEST',
  'RELOAD_SPREEDLY_SUCCESS',
  'RELOAD_SPREEDLY_FAILURE',
)<void, void, IFrameErrorSpreedly>();

export const setReadySpreedly = createAction('SET_READY_SPREEDLY')<void>();

export const noop = createAction('NOOP')<void>();

export const setCreditCardConfigSpreedly = createAction('SET_SPREEDLY_CREDIT_CARD_CONFIG')<SpreedlyCreditCardConfig>();

export const setValidationSpreedly = createAction('SET_VALIDATED_SPREEDLY')<SpreedlyValidationInputProperties>();

export const tokenizeCreditCardSpreedly = createAsyncAction(
  'SET_SPREEDLY_TOKENIZE_CREDIT_CARD_REQUEST',
  'SET_SPREEDLY_TOKENIZE_CREDIT_CARD_SUCCESS',
  'SET_SPREEDLY_TOKENIZE_CREDIT_CARD_FAILURE',
)<SpreedlyCreditCardConfig, SpreedlyPaymentMethodData, Array<SpreedlyError>>();

export const statusUpdates3dSecureSpreedly = createAsyncAction(
  'STATUS_UPDATES_3D_SECURE_REQUEST',
  'STATUS_UPDATES_3D_SECURE_SUCCESS',
  'STATUS_UPDATES_3D_SECURE_FAILURE',
)<(event: { action: string; finalize: (arg0: unknown) => void }) => void, void, unknown>();
