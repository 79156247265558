import { INPUT_LABEL } from './input-labels';

export const INPUT_PLACEHOLDER: Record<string, string> = {
  [INPUT_LABEL.ADDRESS_LINE_1]: 'address_line1_placeholder',
  [INPUT_LABEL.ADDRESS_LINE_2]: 'address_line2_placeholder',
  [INPUT_LABEL.BIRTH_DATE]: 'birth_date_placeholder',
  [INPUT_LABEL.FIRST_NAME]: 'first_name_placeholder',
  [INPUT_LABEL.LAST_NAME]: 'last_name_placeholder',
  [INPUT_LABEL.MIDDLE_NAME]: 'middle_name_paceholder',
  [INPUT_LABEL.EMAIL]: 'email_placeholder',
  [INPUT_LABEL.CITY]: 'city_placeholder',
  [INPUT_LABEL.COUNTRY]: 'country_placeholder',
  [INPUT_LABEL.EMERGENCY_CONTACT_INFO_EMAIL]: 'emergency_contact_info_email_placeholder',
  [INPUT_LABEL.EMERGENCY_CONTACT_INFO_NAME]: 'emergency_contact_info_name_placeholder',
  [INPUT_LABEL.EMERGENCY_CONTACT_INFO_PHONE]: 'emergency_contact_info_phone_placeholder',
  [INPUT_LABEL.EMERGENCY_CONTACT_INFO_RELATIONSHIP]: 'emergency_contact_info_relationship_placeholder',
  [INPUT_LABEL.GENDER]: 'gender_placeholder',
  [INPUT_LABEL.HOME_PHONE]: 'home_phone_placeholder',
  [INPUT_LABEL.MOBILE_PHONE]: 'mobile_phone_placeholder',
  [INPUT_LABEL.POSTAL_CODE]: 'postal_code_placeholder',
  [INPUT_LABEL.STATE]: 'state_placeholder',
  [INPUT_LABEL.WORK_PHONE]: 'work_phone_placeholder',
  [INPUT_LABEL.WORK_EXTENSION]: 'work_extension_placeholder',
  [INPUT_LABEL.IS_MALE]: 'is_male_placeholder',
  [INPUT_LABEL.REFERRED_BY]: 'referred_by_placeholder',
};
