import React from 'react';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { useSelector } from 'react-redux';
import { selectTransaction } from 'store/transactions/selectors';
import { BookingStatus } from 'shared/consts';
import { selectCart } from 'store/cart/selectors';
import { CustomIconCancel, CustomIconConfirmed } from '../styles';

interface UseIconReturnValues {
  icon: JSX.Element;
  hideIcon: boolean;
}

export const useIcon = (): UseIconReturnValues => {
  const { transactionUuid } = useSearchParams();
  const transaction = useSelector(selectTransaction);
  const cart = useSelector(selectCart);

  const icon =
    transaction?.details?.status === BookingStatus.CANCELLED ? <CustomIconCancel /> : <CustomIconConfirmed />;

  const hideIcon = transactionUuid
    ? transaction?.details?.status === BookingStatus.PENDING
    : cart?.items[0]?.status?.toLowerCase() === BookingStatus.PENDING;

  return { icon, hideIcon };
};
