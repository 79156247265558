import * as actions from './actions';

import { ActionType, createReducer } from 'typesafe-actions';

import { ACTION_STATUSES } from 'shared/consts/consts';
import { ServiceProvidersState } from 'store/serviceProviders/types';

export const INITIAL_STATE: ServiceProvidersState = {
  serviceProviders: null,
  currentServiceProvider: null,
  getServiceProviders: {
    status: null,
    error: null,
  },
  getCurrentServiceProvider: {
    status: null,
    error: null,
  },
};

const getServiceProvidersRequestHandler = (state: ServiceProvidersState): ServiceProvidersState => ({
  ...state,
  getServiceProviders: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getServiceProvidersSuccessHandler = (
  state: ServiceProvidersState,
  { payload }: ActionType<typeof actions.getServiceProviders.success>,
): ServiceProvidersState => ({
  ...state,
  serviceProviders: payload.filter(provider => !provider.is_landlord),
  getServiceProviders: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const getServiceProvidersFailureHandler = (
  state: ServiceProvidersState,
  { payload }: ActionType<typeof actions.getServiceProviders.failure>,
): ServiceProvidersState => ({
  ...state,
  getServiceProviders: {
    error: payload.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const getCurrentServiceProviderRequestHandler = (state: ServiceProvidersState): ServiceProvidersState => ({
  ...state,
  getCurrentServiceProvider: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getCurrentServiceProviderSuccessHandler = (
  state: ServiceProvidersState,
  { payload }: ActionType<typeof actions.getCurrentServiceProvider.success>,
): ServiceProvidersState => ({
  ...state,
  currentServiceProvider: payload,
  getCurrentServiceProvider: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const getCurrentServiceProviderFailureHandler = (
  state: ServiceProvidersState,
  { payload }: ActionType<typeof actions.getCurrentServiceProvider.failure>,
): ServiceProvidersState => ({
  ...state,
  getCurrentServiceProvider: {
    error: payload.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const resetCurrentServiceProviderStatusHandler = (state: ServiceProvidersState): ServiceProvidersState => ({
  ...state,
  getCurrentServiceProvider: {
    ...INITIAL_STATE.getCurrentServiceProvider,
  },
});

const ServiceProvidersReducer = createReducer(INITIAL_STATE)
  .handleAction(actions.getServiceProviders.request, getServiceProvidersRequestHandler)
  .handleAction(actions.getServiceProviders.success, getServiceProvidersSuccessHandler)
  .handleAction(actions.getServiceProviders.failure, getServiceProvidersFailureHandler)
  .handleAction(actions.getCurrentServiceProvider.request, getCurrentServiceProviderRequestHandler)
  .handleAction(actions.getCurrentServiceProvider.success, getCurrentServiceProviderSuccessHandler)
  .handleAction(actions.getCurrentServiceProvider.failure, getCurrentServiceProviderFailureHandler)
  .handleAction(actions.resetCurrentServiceProviderStatus, resetCurrentServiceProviderStatusHandler);

export default ServiceProvidersReducer;
