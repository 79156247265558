import React, { useState, useCallback, useRef, useEffect } from 'react';
import { AgreementContent } from './components';
import { useIntl } from 'react-intl';
import { createCart, setScreenshotAction } from 'store/cart/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentMembership } from 'store/serviceMemberships/selectors';
import { OrderType, ItemType } from 'store/cart/types';
import { useScreenshot } from 'hooks/use-screenshot';
import { selectScreenshotStatus } from 'store/cart/selectors';
import { ACTION_STATUSES } from 'shared/consts/consts';
import { SERVICE_TYPE } from 'store/availableServices/types';
import { SinglePaneModal } from 'components/modals/singlePaneModal/singlePaneModal';
import { StyledButton } from 'pages/agreement-page/styles';
import { configSelector } from 'store/config/selectors';
import { selectCurrentServiceProvider } from 'store/serviceProviders/selectors';

interface AgreementPageProps {
  setCurrentOrderType: React.Dispatch<React.SetStateAction<OrderType>>;
}

export const AgreementPage = ({ setCurrentOrderType }: AgreementPageProps) => {
  const modalRef = useRef(null);
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { buildingUuid } = useSelector(configSelector);
  const currentMembership = useSelector(selectCurrentMembership);
  const screenshotStatus = useSelector(selectScreenshotStatus);
  const { screenshot, takeScreenshot } = useScreenshot();
  const currentServiceProvider = useSelector(selectCurrentServiceProvider);

  const [name, setName] = useState<string>(null);
  const [hasError, setHasError] = useState<boolean>(false);

  const onAgreeClick = useCallback(() => {
    if (name && name.length > 0) {
      dispatch(setScreenshotAction.request());
      takeScreenshot(modalRef.current);
    } else {
      setHasError(true);
    }
  }, [name, dispatch, takeScreenshot]);

  useEffect(() => {
    if (screenshot) {
      dispatch(
        createCart.request({
          type: OrderType.SERVICE_BOOKING_MEMBERSHIP,
          owner_type: currentServiceProvider?.owner_type,
          owner_id: currentServiceProvider?.owner_uuid,
          building_uuid: buildingUuid,
          app_instance_config_uuid: currentServiceProvider?.app_instance_config_uuid,
          items: [
            {
              id: currentMembership.id,
              type: ItemType.SERVICE_BOOKING_MEMBERSHIP,
              quantity: 1,
              price: currentMembership.subtotal,
              tax: currentMembership.tax,
              discount: currentMembership.discount,
              display_info: {
                title: currentMembership.name,
              },
              membership: {
                client_signature: screenshot,
              },
            },
          ],
          booking_type: SERVICE_TYPE.GROUP,
        }),
      );
      setCurrentOrderType(OrderType.SERVICE_BOOKING_MEMBERSHIP);
    }
  }, [screenshot, dispatch, currentServiceProvider, buildingUuid, currentMembership, setCurrentOrderType]);

  const button = (
    <StyledButton
      data-testid="agree-button"
      text={formatMessage({ id: 'agree' })}
      loading={screenshotStatus === ACTION_STATUSES.PENDING || screenshotStatus === ACTION_STATUSES.FULFILLED}
      onClick={onAgreeClick}
    />
  );

  const handleName = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    setHasError(false);
  }, []);

  return (
    <SinglePaneModal
      contentStatus={ACTION_STATUSES.FULFILLED}
      PaneContent={<AgreementContent setName={handleName} name={name} modalRef={modalRef} hasError={hasError} />}
      Button={button}
    />
  );
};
