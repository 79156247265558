import styled from 'styled-components';

export const DIMENSIONS = {
  SMALL: '568px',
  MEDIUM: '675px',
  LARGE: '812px',
};

export const TitleWrapper = styled.div`
  padding: 16px;

  @media only screen and (max-height: ${DIMENSIONS.MEDIUM}) {
    padding: 24px 16px 8px 16px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const Title = styled.div`
  font: 700 28px/24px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
  user-select: none;
`;

export const ClientInfoLabel = styled.div`
  font: 400 16px ${({ theme }) => theme.fonts.join()};
  padding-top: 16px;
  color: ${({ theme }) => theme.colors.$sharedGreyDark};
`;

export const ClientInfo = styled.div`
  font: 700 16px ${({ theme }) => theme.fonts.join()};
  margin-top: 5px;
`;

export const QRContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  max-height: 266px;

  @media only screen and (max-height: ${DIMENSIONS.MEDIUM}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    margin-top: 0px;
    min-width: 210px;
  }
`;

export const ContactUsButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  width: 100%;
  margin-top: 60px;
  margin-bottom: 28px;
  user-select: none;

  @media only screen and (max-height: ${DIMENSIONS.MEDIUM}) {
    margin-top: 20px;
  }
`;

export const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.fontColor};
  font: 400 16px/24px ${({ theme }) => theme.fonts.join()};
  margin-top: 10px;
`;
