import { DIMENSIONS, PageHeader } from '@hqo/react-components-library';

import styled from 'styled-components';

export const Container = styled.div`
  padding: 50px 60px 95px 60px;

  @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    padding: 20px 20px 75px 20px;
  }
`;

export const PageHeaderStyled = styled(PageHeader)`
  padding: 0;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    min-height: 100px;
  }
`;
