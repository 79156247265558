import { useCallback, useEffect, useMemo } from 'react';

import { goBack, push } from 'connected-react-router';
import qs from 'qs';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { ACTION_STATUSES } from 'shared/consts';
import { resetCart, resetGetCartStatus, resetSubmitCartStatus } from 'store/cart/actions';
import { selectCartId, selectCreateCartStatus } from 'store/cart/selectors';

import { useSearchParams } from '../use-search-params.hook';

interface UseQuickCheckoutReturnValues {
  closeQuickCheckoutHandler: VoidFunction;
  openQuickCheckoutHandler: VoidFunction;
}

// eslint-disable-next-line max-lines-per-function
export const useQuickCheckout = (): UseQuickCheckoutReturnValues => {
  const dispatch = useDispatch();
  const { ...queryParams } = useSearchParams();
  const { pathname } = useLocation();
  const cartId = useSelector(selectCartId);
  const createCartStatus = useSelector(selectCreateCartStatus);
  const isPaymentModalOpen = useMemo<boolean>(
    () => pathname.includes('quick-checkout') || pathname.includes('checkout') || pathname.includes('receipt'),
    [pathname],
  );

  const closeQuickCheckoutHandler = useCallback(() => {
    dispatch(resetSubmitCartStatus());
    dispatch(resetGetCartStatus());
    dispatch(resetCart());
    dispatch(goBack());
  }, [dispatch]);

  const openQuickCheckoutHandler = useCallback(() => {
    dispatch(resetGetCartStatus());
    const queryString = qs.stringify({ ...queryParams, cartId });
    dispatch(push(`${pathname}/quick-checkout?${queryString}`));
  }, [dispatch, queryParams, cartId, pathname]);

  useEffect(() => {
    if (
      createCartStatus === ACTION_STATUSES.FULFILLED &&
      !pathname.includes('register-contract') &&
      !pathname.includes('/events') &&
      !isPaymentModalOpen
    ) {
      openQuickCheckoutHandler();
    }
  }, [createCartStatus, openQuickCheckoutHandler, pathname, isPaymentModalOpen]);

  return {
    closeQuickCheckoutHandler,
    openQuickCheckoutHandler,
  };
};
