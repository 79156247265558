import styled from 'styled-components';
import { DIMENSIONS } from '@hqo/react-components-library/dist/utils';

export const DateTimeWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 5px;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    margin-bottom: 13px;
  }
`;

export const DateTimeContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column-reverse;
  align-self: flex-start;
  user-select: none;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
`;
