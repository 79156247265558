import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { colors } from '@hqo/react-components-library/dist/hqo-theme-provider';
import { Spinner } from '@hqo/react-components-library/dist/atoms/spinner';
import { Container } from 'hocs/shared-styles';
import { Notification } from './styles';
import { LOAD_DELAY_NOTIFICATION_TIME_THRESHOLD } from 'components/loader/loader.constants';

export const Loader = ({ hasNotificationText = false }): JSX.Element => {
  const intl = useIntl();
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    setTimeout(() => setShowNotification(true), LOAD_DELAY_NOTIFICATION_TIME_THRESHOLD);
  }, []);

  return (
    <Container isLoading isModal>
      <Spinner size="50px" color={colors.palette.modifiedMalibuBluePressed} />
      {hasNotificationText && showNotification && (
        <Notification>{intl.formatMessage({ id: 'load_time_notification' })}</Notification>
      )}
    </Container>
  );
};
