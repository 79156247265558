import { ACTION_STATUSES, PAYMENT_TYPE } from 'shared/consts';
import { getPaymentMethods, setCurrentPaymentMethodId } from 'store/payment/actions';
import {
  selectCurrentPaymentMethodId,
  selectGetPaymentMethodsStatus,
  selectPaymentMethods,
} from 'store/payment/selectors';
import { useDispatch, useSelector } from 'react-redux';

import { SavedPaymentMethod } from 'store/payment/types';
import { findCurrentPaymentMethod } from 'utils/findCurrentPaymentMethod';
import { useEffect, useMemo } from 'react';

export interface UsePaymentMethodsInterface {
  isPaymentMethodsLoading: boolean;
  paymentMethods: Array<SavedPaymentMethod>;
  isPaymentMethodsFullfiled: boolean;
  currentPaymentMethodId: string;
}

// eslint-disable-next-line max-lines-per-function
export const usePaymentMethods = (cartId: string): UsePaymentMethodsInterface => {
  const currentPaymentMethodId = useSelector(selectCurrentPaymentMethodId);
  const paymentMethodsStatus = useSelector(selectGetPaymentMethodsStatus);
  const paymentMethods = useSelector(selectPaymentMethods);
  const dispatch = useDispatch();
  useEffect(() => {
    if (cartId) {
      dispatch(getPaymentMethods.request(cartId));
    }
  }, [dispatch]);
  const isExternalPaymentMethod =
    currentPaymentMethodId === PAYMENT_TYPE.APPLE_PAY ||
    currentPaymentMethodId === PAYMENT_TYPE.GOOGLE_PAY ||
    currentPaymentMethodId === PAYMENT_TYPE.EXTERNAL;
  const isPaymentMethodsExist = useMemo<boolean>(() => paymentMethods && paymentMethods.length > 0, [paymentMethods]);
  const isCurrentPaymentMethodNotExist = useMemo<boolean>(
    () =>
      !currentPaymentMethodId ||
      (!findCurrentPaymentMethod(paymentMethods, currentPaymentMethodId) && !isExternalPaymentMethod),
    [currentPaymentMethodId, isExternalPaymentMethod, findCurrentPaymentMethod],
  );

  useEffect(() => {
    if (isPaymentMethodsExist && isCurrentPaymentMethodNotExist) {
      dispatch(setCurrentPaymentMethodId(paymentMethods[paymentMethods.length - 1]?.id?.toString()));
    }
  }, [isCurrentPaymentMethodNotExist, isPaymentMethodsExist, dispatch]);

  const isPaymentMethodsLoading = paymentMethodsStatus === ACTION_STATUSES.PENDING;
  const isPaymentMethodsFullfiled = paymentMethodsStatus === ACTION_STATUSES.FULFILLED;

  return { isPaymentMethodsLoading, paymentMethods, isPaymentMethodsFullfiled, currentPaymentMethodId };
};
