import { ProviderParams } from 'shared/types';
import React, { useCallback, useEffect } from 'react';
import { ScheduleContent } from './schedule-content';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentServiceProvider } from 'store/serviceProviders/selectors';
import { getCurrentServiceProvider } from 'store/serviceProviders/actions';
import { ACTION_STATUSES, OwnerType } from 'shared/consts';
import { Container } from 'hocs/shared-styles';
import { defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';
import { Spinner } from '@hqo/react-components-library/dist/atoms/spinner';
import { PaymentIFrame } from 'components/paymentIFrame';
import { ScheduleTitle } from 'pages/schedule/schedule-title';
import { NavBackLink } from '@hqo/react-components-library/dist/molecules/navBackLink';
import { goBack, replace } from 'connected-react-router';
import { BackLinkWrapper, HeaderWrapper, SchedulePageWrapper } from 'pages/schedule/styles';
import { useBackButtonVisibility } from 'hooks/use-back-button-visibility.hook';
import { useIntl } from 'react-intl';
import { OrderType } from 'store/cart/types';
import { useBooleanState } from '@hqo/react-components-library';
import { resetCreateCartStatus } from 'store/cart/actions';
import { selectCartId, selectCreateCartStatus } from 'store/cart/selectors';
import { SwipePaymentIFrame } from 'components/swipePaymentIFrame';
import { PaymentRouter } from 'components/payment-router';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { configSelector } from 'store/config/selectors';

export const SchedulePage = (): JSX.Element => {
  const { companyUuid } = useParams<ProviderParams>();
  const providerDetails = useSelector(selectCurrentServiceProvider);
  const { path } = useRouteMatch();
  const isBackButtonVisible = useBackButtonVisibility();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { enableServiceBookingPaymentsMigration } = useFlags();
  const { value: reverseAnimation, toggle: toggleReverseAnimation } = useBooleanState(false);
  const createCartStatus = useSelector(selectCreateCartStatus);
  const cartId = useSelector(selectCartId);
  const { buildingUuid } = useSelector(configSelector);

  const onPressBack = useCallback(() => {
    dispatch(goBack());
  }, [dispatch]);

  useEffect(() => {
    if (!providerDetails) {
      dispatch(
        getCurrentServiceProvider.request({
          ownerUuid: buildingUuid,
          ownerType: OwnerType.BUILDING,
          companyUuid,
        }),
      );
    }
  }, [dispatch, providerDetails, buildingUuid, companyUuid]);

  useEffect(() => {
    if (createCartStatus === ACTION_STATUSES.REJECTED) {
      dispatch(resetCreateCartStatus());
    }
    if (createCartStatus === ACTION_STATUSES.FULFILLED && !enableServiceBookingPaymentsMigration) {
      const internalPath = `${location.pathname}/payment/${cartId}?quickCheckout=true`;
      dispatch(resetCreateCartStatus());
      dispatch(replace(internalPath));
    }
  }, [dispatch, createCartStatus, cartId, enableServiceBookingPaymentsMigration]);

  return providerDetails ? (
    <SchedulePageWrapper data-testid="schedule-page-wrapper">
      <HeaderWrapper>
        {isBackButtonVisible && (
          <BackLinkWrapper>
            <NavBackLink
              dataTestId="schedule-page-back-link"
              onClick={onPressBack}
              backLinkText={intl.formatMessage({ id: 'back' })}
            />
          </BackLinkWrapper>
        )}
        <ScheduleTitle />
      </HeaderWrapper>
      <ScheduleContent isSupportsServiceTypes={providerDetails.config?.supports_service_types} />
      {enableServiceBookingPaymentsMigration && <PaymentRouter />}
      <Switch>
        <Route path={`${path}/swipe-payment/:cartId`}>
          <SwipePaymentIFrame reverseAnimation={reverseAnimation} currentOrderType={OrderType.SERVICE_BOOKING_CLASS} />
        </Route>

        <Route path={`${path}/payment/:cartId`}>
          <PaymentIFrame
            currentOrderType={OrderType.SERVICE_BOOKING_CLASS}
            reverseAnimation={reverseAnimation}
            toggleReverseAnimation={toggleReverseAnimation}
          />
        </Route>
      </Switch>
    </SchedulePageWrapper>
  ) : (
    <Container isLoading>
      <Spinner color={defaultTheme.colors.$greyLight} size="10em" />
    </Container>
  );
};
