import React, { useEffect } from 'react';
import { ReceiptContent } from './components/receipt-content';
import { useReceipt } from './use-receipt.hook';
import { SwipeReceiptContent } from './components/swipe-receipt-content';
import { track } from '@hqo/web-tracking';
import { TRACK_EVENTS, TRACK_EVENT_TYPES } from 'shared/consts';

interface ReceiptProps {
  transactionId?: number | string;
  onCloseReceipt: VoidFunction;
}

export const Receipt = ({ transactionId, onCloseReceipt }: ReceiptProps) => {
  const { isSwipeModalContent, transaction } = useReceipt(transactionId);

  useEffect(() => {
    if (transaction) {
      track(
        TRACK_EVENTS.RECEIPT_IMPRESSION,
        {
          type: TRACK_EVENT_TYPES.IMPRESSION,
          cart_total: transaction.total_price,
          currency_code: transaction.currency_type,
          promo_code_applied: !!transaction.total_discount,
          transaction_type: transaction.type,
          transaction_history_id: transaction.id,
          items: [transaction.details.service_id ?? transaction.details.membership_id],
        },
        { sendToHqoTracking: true },
      );
    }
  }, [transaction]);

  return isSwipeModalContent ? (
    <SwipeReceiptContent
      transaction={transaction}
      isSwipeModalContent={isSwipeModalContent}
      onCloseClick={onCloseReceipt}
    />
  ) : (
    <ReceiptContent transaction={transaction} onCloseClick={onCloseReceipt} />
  );
};
