import { IconBlock } from '@hqo/react-components-library/dist/molecules/iconBlock';
import { IntlShape } from 'react-intl';
import React from 'react';
import { Service } from 'store/availableServices/types';

interface ServiceMapProps {
  selectedService: Service;
  intl: IntlShape;
}

export const ServiceMap = ({ selectedService, intl }: ServiceMapProps) => {
  const addressName = selectedService.location?.name ?? selectedService.address;
  const subtitle = selectedService.location?.address ?? selectedService.directions;
  const linkAddress = selectedService.location?.address ?? selectedService.address;
  return (
    <IconBlock
      icon="map-marker-alt"
      iconType="fal"
      title={addressName}
      subtitle={subtitle}
      linkText={intl.formatMessage({ id: 'open_in_maps' })}
      linkRef={`https://www.google.com/maps?q=${encodeURI(linkAddress)}`}
      shouldOpenInNewTab={!/mobi/i.test(navigator.userAgent)}
    />
  );
};
