export enum TRACK_EVENT_TYPES {
  IMPRESSION = 'impression',
  ACTION = 'action',
}

export enum TRACK_EVENTS {
  VENDOR_DETAIL_IMPRESSION = 'VENDOR_DETAIL_IMPRESSION',
  VIEW_SCHEDULE_CLICK = 'VIEW_SCHEDULE_CLICK',
  SERVICE_DETAIL_IMPRESSION = 'SERVICE_DETAIL_IMPRESSION',
  SCHEDULE_IMPRESSION = 'SCHEDULE_IMPRESSION',
  BOOK_CLICK = 'BOOK_CLICK',
  APPOINTMENT_TILE_CLICK = 'APPOINTMENT_TILE_CLICK',
  REGISTRATION_SUBMITTED_CLICK = 'REGISTRATION_SUBMITTED_CLICK',
  CONTRACT_LIST_IMPRESSION = 'CONTRACT_LIST_IMPRESSION',
  CONTRACT_LIST_EXIT_CLICK = 'CONTRACT_LIST_EXIT_CLICK',
  CONTRACT_SELECTED_CLICK = 'CONTRACT_SELECTED_CLICK',
  CHECK_IN_CLICK = 'CHECK_IN_CLICK',
  NEW_CONTRACT_CLICK = 'NEW_CONTRACT_CLICK',
  UPCOMING_BOOKING_CLICK = 'UPCOMING_BOOKING_CLICK',
  QUICK_CHECKOUT_IMPRESSION = 'QUICK_CHECKOUT_IMPRESSION',
  FULL_CHECKOUT_IMPRESSION = 'FULL_CHECKOUT_IMPRESSION',
  CHECKOUT_CLICK = 'CHECKOUT_CLICK',
  RECEIPT_IMPRESSION = 'RECEIPT_IMPRESSION',
  HISTORICAL_RECEIPT_IMPRESSION = 'HISTORICAL_RECEIPT_IMPRESSION',
  SERVICE_BOOKING_CANCEL_CLICK = 'SB_CANCEL_CLICK',
  SB_CANCEL_CONFIRMATION_CLICK = 'SB_CANCEL_CONFIRMATION_CLICK',
}
