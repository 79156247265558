import { StateType } from 'typesafe-actions';
import availableServicesReducer from 'store/availableServices/reducer';
import buildingReducer from 'store/building/reducer';
import cartReducer from 'store/cart/reducer';
import { combineReducers } from 'redux';
import configReducer from 'store/config/reducer';
import errorsReducer from 'store/errors/reducer';
import routerReducer from 'store/router/reducer';
import serviceMembershipsReducer from 'store/serviceMemberships/reducer';
import serviceProvidersReducer from 'store/serviceProviders/reducer';
import serviceRegistrationReducer from 'store/serviceRegistration/reducer';
import transactionsReducer from 'store/transactions/reducer';
import themeReducer from 'store/theme/reducer';
import userReducer from 'store/user/reducer';
import featureFlagsReducer from 'store/featureFlags/reducer';
import routesReducer from 'store/routes/reducer';
import spreedlyReducer from 'store/add-card/reducer';
import paymentReducer from 'store/payment/reducer';

const rootReducer = combineReducers({
  config: configReducer,
  router: routerReducer,
  featureFlags: featureFlagsReducer,
  user: userReducer,
  building: buildingReducer,
  theme: themeReducer,
  serviceProviders: serviceProvidersReducer,
  errors: errorsReducer,
  serviceRegistration: serviceRegistrationReducer,
  serviceMemberships: serviceMembershipsReducer,
  transactions: transactionsReducer,
  availableServices: availableServicesReducer,
  cart: cartReducer,
  routes: routesReducer,
  spreedly: spreedlyReducer,
  payment: paymentReducer,
});

export type RootState = StateType<typeof rootReducer>;

export default rootReducer;
