import * as actions from './actions';

import { ActionType, createReducer } from 'typesafe-actions';

import { ACTION_STATUSES } from 'shared/consts';
import { CartState } from './types';

export const initialState: CartState = {
  cart_id: null,
  cart: null,
  transaction: null,
  createCart: {
    status: null,
    error: null,
  },
  getCart: {
    status: null,
    error: null,
  },
  submitCart: {
    status: null,
    error: null,
  },
  complete3DSCart: {
    status: null,
    error: null,
  },
  applyPromoCodeToCart: {
    status: null,
    error: null,
  },
  removePromoCodeFromCart: {
    status: null,
    error: null,
  },
  screenshot: {
    status: null,
    error: null,
  },
};

const getCartRequestHandler = (state: CartState): CartState => ({
  ...state,
  getCart: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getCartSuccessHandler = (
  state: CartState,
  { payload }: ActionType<typeof actions.getCart.success>,
): CartState => ({
  ...state,
  cart: payload,
  getCart: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const getCartFailureHandler = (
  state: CartState,
  { payload: error }: ActionType<typeof actions.getCart.failure>,
): CartState => ({
  ...state,
  getCart: {
    error: error.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const complete3DSCartRequestHandler = (state: CartState): CartState => ({
  ...state,
  complete3DSCart: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const complete3DSCartSuccessHandler = (
  state: CartState,
  { payload }: ActionType<typeof actions.complete3DSCart.success>,
): CartState => ({
  ...state,
  transaction: payload,
  complete3DSCart: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const complete3DSCartFailureHandler = (
  state: CartState,
  { payload: error }: ActionType<typeof actions.complete3DSCart.failure>,
): CartState => ({
  ...state,
  complete3DSCart: {
    error: error.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const complete3DSCartResetHandler = (state: CartState): CartState => ({
  ...state,
  transaction: null,
  complete3DSCart: {
    error: null,
    status: null,
  },
});

const submitCartRequestHandler = (state: CartState): CartState => ({
  ...state,
  submitCart: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const submitCartSuccessHandler = (
  state: CartState,
  { payload }: ActionType<typeof actions.submitCart.success>,
): CartState => ({
  ...state,
  cart: payload,
  submitCart: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const submitCartFailureHandler = (
  state: CartState,
  { payload: error }: ActionType<typeof actions.submitCart.failure>,
): CartState => ({
  ...state,
  submitCart: {
    error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const applyPromoCodeToCartRequestHandler = (state: CartState): CartState => ({
  ...state,
  applyPromoCodeToCart: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const applyPromoCodeToCartSuccessHandler = (
  state: CartState,
  { payload }: ActionType<typeof actions.applyPromoCodeToCart.success>,
): CartState => ({
  ...state,
  cart: payload,
  applyPromoCodeToCart: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const applyPromoCodeToCartFailureHandler = (
  state: CartState,
  { payload: error }: ActionType<typeof actions.applyPromoCodeToCart.failure>,
): CartState => ({
  ...state,
  applyPromoCodeToCart: {
    error: error.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const removePromoCodeFromCartRequestHandler = (state: CartState): CartState => ({
  ...state,
  removePromoCodeFromCart: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const removePromoCodeFromCartSuccessHandler = (
  state: CartState,
  { payload }: ActionType<typeof actions.removePromoCodeFromCart.success>,
): CartState => ({
  ...state,
  cart: payload,
  removePromoCodeFromCart: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const removePromoCodeFromCartFailureHandler = (
  state: CartState,
  { payload: error }: ActionType<typeof actions.removePromoCodeFromCart.failure>,
): CartState => ({
  ...state,
  removePromoCodeFromCart: {
    error: error.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const createCartRequestHandler = (state: CartState): CartState => ({
  ...state,
  createCart: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const createCartSuccessHandler = (
  state: CartState,
  { payload }: ActionType<typeof actions.createCart.success>,
): CartState => ({
  ...state,
  cart_id: payload.cart_id,
  createCart: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const createCartFailureHandler = (
  state: CartState,
  { payload: error }: ActionType<typeof actions.createCart.failure>,
): CartState => ({
  ...state,
  createCart: {
    error: error.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const resetCreateCartHandler = (state: CartState): CartState => ({
  ...state,
  createCart: {
    ...initialState.createCart,
  },
});

const setScreenshotRequestHandler = (state: CartState): CartState => ({
  ...state,
  screenshot: {
    status: ACTION_STATUSES.PENDING,
    error: null,
  },
});

const setScreenshotSuccessHandler = (state: CartState): CartState => ({
  ...state,
  screenshot: {
    status: ACTION_STATUSES.FULFILLED,
    error: null,
  },
});

const setScreenshotErrorHandler = (
  state: CartState,
  { payload: error }: ActionType<typeof actions.setScreenshotAction.failure>,
): CartState => ({
  ...state,
  screenshot: {
    status: ACTION_STATUSES.REJECTED,
    error: error as unknown as Error,
  },
});

const resetScreenshotHandler = (state: CartState): CartState => ({
  ...state,
  screenshot: {
    status: null,
    error: null,
  },
});

const resetCartHandler = (state: CartState): CartState => ({
  ...state,
  cart_id: null,
  cart: null,
  getCart: {
    error: null,
    status: null,
  },
  createCart: {
    error: null,
    status: null,
  },
});

const resetSubmitCartStatusHandler = (state: CartState): CartState => ({
  ...state,
  submitCart: {
    error: null,
    status: null,
  },
});

const resetGetCartStatusHandler = (state: CartState): CartState => ({
  ...state,
  getCart: {
    error: null,
    status: null,
  },
});

const cartReducer = createReducer(initialState)
  .handleAction(actions.createCart.request, createCartRequestHandler)
  .handleAction(actions.createCart.success, createCartSuccessHandler)
  .handleAction(actions.createCart.failure, createCartFailureHandler)
  .handleAction(actions.resetCreateCartStatus, resetCreateCartHandler)
  .handleAction(actions.setScreenshotAction.request, setScreenshotRequestHandler)
  .handleAction(actions.setScreenshotAction.success, setScreenshotSuccessHandler)
  .handleAction(actions.setScreenshotAction.failure, setScreenshotErrorHandler)
  .handleAction(actions.resetScreenshotStatus, resetScreenshotHandler)
  .handleAction(actions.getCart.request, getCartRequestHandler)
  .handleAction(actions.getCart.success, getCartSuccessHandler)
  .handleAction(actions.getCart.failure, getCartFailureHandler)
  .handleAction(actions.complete3DSCart.request, complete3DSCartRequestHandler)
  .handleAction(actions.complete3DSCart.success, complete3DSCartSuccessHandler)
  .handleAction(actions.complete3DSCart.failure, complete3DSCartFailureHandler)
  .handleAction(actions.resetComplete3DSCart, complete3DSCartResetHandler)
  .handleAction(actions.submitCart.request, submitCartRequestHandler)
  .handleAction(actions.submitCart.success, submitCartSuccessHandler)
  .handleAction(actions.submitCart.failure, submitCartFailureHandler)
  .handleAction(actions.applyPromoCodeToCart.request, applyPromoCodeToCartRequestHandler)
  .handleAction(actions.applyPromoCodeToCart.success, applyPromoCodeToCartSuccessHandler)
  .handleAction(actions.applyPromoCodeToCart.failure, applyPromoCodeToCartFailureHandler)
  .handleAction(actions.removePromoCodeFromCart.request, removePromoCodeFromCartRequestHandler)
  .handleAction(actions.removePromoCodeFromCart.success, removePromoCodeFromCartSuccessHandler)
  .handleAction(actions.removePromoCodeFromCart.failure, removePromoCodeFromCartFailureHandler)
  .handleAction(actions.resetGetCartStatus, resetGetCartStatusHandler)
  .handleAction(actions.resetCart, resetCartHandler)
  .handleAction(actions.resetSubmitCartStatus, resetSubmitCartStatusHandler);

export default cartReducer;
