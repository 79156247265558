import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const StyledText = styled.div`
  color: ${({ theme }) => theme.colors.fontColor};
  font: 400 ${({ theme }) => `14px ${theme.fonts.join()}`};
`;

export const StyledDot = styled.div`
  margin: 10px 4px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.palette.systemBlack};
`;
