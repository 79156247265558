import React from 'react';
import { FlexContainer, SkeletonBlock } from '../styles';

export const ServiceHost = (): JSX.Element => {
  return (
    <FlexContainer gap="23" isColumnDirection top="15" left={16}>
      <SkeletonBlock width="281" />
    </FlexContainer>
  );
};
