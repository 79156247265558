import { DIMENSIONS } from '@hqo/react-components-library';
import styled from 'styled-components';

export const AvailabilityCalendarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  box-sizing: border-box;

  @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    width: 100%;
    height: 100%;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const BackLinkWrapper = styled.div`
  padding-left: 20px;
  @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    padding-left: 0;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
`;

export const AvailabilityListWrapper = styled.div<{ isBackButtonVisible: boolean }>`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 232px);

  @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    padding: 0 16px;
    height: ${({ isBackButtonVisible }) => (isBackButtonVisible ? 'calc(100vh - 242px)' : 'calc(100vh - 210px)')};
  }
`;
