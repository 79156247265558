import { useSelector } from 'react-redux';
import { OwnerType } from '../shared/consts';
import { OwnerParams, ProviderParams } from 'shared/types';
import { parseQueryValue } from '../shared/utils/parse-query-value';
import { configSelector } from 'store/config/selectors';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useParams } from 'react-router-dom';

export const useOwnerValues = (): OwnerParams => {
  const { companyUuid } = useParams<ProviderParams>();
  const { serviceBookingUsingV3Endpoints: isServiceBookingUsingV3EndpointsLD } = useFlags();
  const { buildingUuid } = useSelector(configSelector);

  return {
    ownerType: isServiceBookingUsingV3EndpointsLD ? OwnerType.BUILDING : OwnerType.BUILDING_COMPANY,
    ownerUuid: isServiceBookingUsingV3EndpointsLD || !companyUuid ? buildingUuid : parseQueryValue(companyUuid),
  };
};
