import { Availability, Service } from 'store/availableServices/types';
import { IntlShape, useIntl } from 'react-intl';
import { ListWrapper, StyledEmptyContainer } from './styles';
import {
  formatCurrency,
  formatTime,
  isBeforeCurrentDateTime,
  isDurationBeforeCurrentDateTime,
  resolveMembershipPermission,
} from 'utils';

import { BookingTile } from './components/bookingTile';
import { Building } from 'store/building/types';
import { ButtonProps } from '@hqo/react-components-library';
import { EmptyServicesPlaceholder } from 'components/empty-services-placeholder';
import React from 'react';
import { ServiceProviderV3 } from 'store/serviceProviders/types';
import { getCurrentCurrency } from 'utils/getCurrentCurrency';
import { isConfirmedBooking } from 'utils/isConfirmedBooking';
import { selectBuilding } from 'store/building/selectors';
import { selectCurrentAvailableService } from 'store/availableServices/selectors';
import { selectCurrentServiceProvider } from 'store/serviceProviders/selectors';
import { useSelector } from 'react-redux';
import { useTimeZone } from 'hooks/use-timezone.hook';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useLocale } from 'hooks/use-locale.hook';
import { TransactionDetails } from 'store/transactions/types';
import { selectTransactions } from 'store/transactions/selectors';

export interface AvailabilityListProps {
  isEmpty?: boolean;
  onClick?: (service: Availability) => void;
  onActionButtonClick?: (service: Availability) => void;
}

export interface BookingTileProps extends AvailabilityListProps {
  service: Availability;
  formattedPrice: string;
  formattedDate: string;
  formattedTimeSlot: string;
  displayConfirmedIcon: boolean;
  title: string;
  subtitle: string;
  status: string;
  disabled: boolean;
  actionButton: ButtonProps;
}

const waitlistDisabled = (
  time: string,
  minutesBefore: number,
  waitlistCount: number,
  capacity?: number,
  timeZone?: string,
) =>
  isDurationBeforeCurrentDateTime(time, minutesBefore, 'minutes', timeZone) || (capacity && capacity <= waitlistCount);

const isServiceDisabled = (service: Availability, timeZone?: string) => {
  if (service?.seats_available <= 0) {
    return (
      !service.waitlist?.is_available || waitlistDisabled(service.start_at, 30, service.waitlist?.count, null, timeZone)
    );
  }
  return false;
};

const renderServiceStatus = (service: Availability, intl: IntlShape, timeZone?: string) => {
  if (service?.waitlist?.is_user_waitlisted) {
    return intl.formatMessage({ id: 'added_waitlist' });
  }
  if (service.seats_available <= 0 && service.waitlist && !service.waitlist.is_available) {
    return intl.formatMessage({ id: 'full_class' });
  }
  if (
    service.seats_available <= 0 &&
    (waitlistDisabled(service.start_at, 30, service.waitlist?.count, null, timeZone) || !service.user_booked)
  ) {
    return intl.formatMessage({ id: 'not_available' });
  }
  return undefined;
};

const getActionButton = (
  service: Availability,
  intl: IntlShape,
  currentAvailableService: Service,
  serviceProvider: ServiceProviderV3,
  timeZone?: string,
  inLocalTimeLD?: boolean,
) => {
  const isMembershipRequired = resolveMembershipPermission(serviceProvider, currentAvailableService?.can_access);
  const serviceStartAt = inLocalTimeLD ? service.start_at.replace('Z', '') : service.start_at;

  if (service?.seats_available <= 0) {
    return service?.waitlist &&
      service?.waitlist?.is_available &&
      !waitlistDisabled(service?.start_at, 30, service?.waitlist?.count, null, timeZone)
      ? {
          text: intl.formatMessage({ id: 'waitlist' }),
          variant: 'secondary' as const,
        }
      : undefined;
  }

  if (service.booking_window) {
    const isHidden =
      isBeforeCurrentDateTime(new Date(service.booking_window.bookable_end), timeZone, inLocalTimeLD) ||
      isMembershipRequired;

    return isHidden
      ? undefined
      : {
          text: intl.formatMessage({ id: 'book' }),
          variant: 'secondary' as const,
        };
  }

  if (isBeforeCurrentDateTime(new Date(serviceStartAt), timeZone, inLocalTimeLD)) {
    return undefined;
  }

  return isMembershipRequired
    ? undefined
    : {
        text: intl.formatMessage({ id: 'book' }),
        variant: 'secondary' as const,
      };
};

const getBookingTileProps = (
  service: Availability,
  currentAvailableService: Service,
  intl: IntlShape,
  timeZone: string,
  locale: string,
  onClick: (service: Availability) => void,
  onActionButtonClick: (service: Availability) => void,
  serviceProvider: ServiceProviderV3,
  building: Building,
  transactions: Array<TransactionDetails>,
  inLocalTimeLD: boolean,
) => {
  const userBooked = service?.user_booked;

  const userWaitlisted = service?.waitlist?.is_user_waitlisted;

  const actionButton =
    userBooked || userWaitlisted
      ? undefined
      : getActionButton(service, intl, currentAvailableService, serviceProvider, timeZone, inLocalTimeLD);

  const status = renderServiceStatus(service, intl);

  const formattedPrice = currentAvailableService.price
    ? formatCurrency(currentAvailableService.price / 100, getCurrentCurrency(currentAvailableService, building))
    : intl.formatMessage({ id: 'free' });

  const formattedDate = service?.start_at
    ? formatTime(service?.start_at, building.timezone, locale, inLocalTimeLD)
    : 'TBD';

  const formattedTimeSlot = `${currentAvailableService.duration ?? service.duration} ${intl.formatMessage({
    id: 'min',
  })}`;

  const subtitle = service?.resource?.name && `${intl.formatMessage({ id: 'with' })} ${service?.resource?.name}`;

  const disabled = !userBooked && isServiceDisabled(service, timeZone);

  return {
    service,
    formattedPrice: !userBooked && status && formattedPrice,
    formattedDate,
    formattedTimeSlot,
    title: currentAvailableService.admin_name,
    subtitle,
    displayConfirmedIcon: userBooked && isConfirmedBooking(service, currentAvailableService, transactions),
    status: !userBooked && status,
    disabled,
    actionButton,
    onClick,
    onActionButtonClick,
  } as BookingTileProps;
};

const renderEmptyState = (isEmpty: boolean) =>
  isEmpty && (
    <StyledEmptyContainer data-testid="empty-services-container">
      <EmptyServicesPlaceholder />
    </StyledEmptyContainer>
  );

export const AvailabilityList = ({ isEmpty, onClick, onActionButtonClick }: AvailabilityListProps) => {
  const intl = useIntl();
  const timeZone = useTimeZone();
  const currentAvailableService = useSelector(selectCurrentAvailableService);
  const serviceProvider = useSelector(selectCurrentServiceProvider);
  const building = useSelector(selectBuilding);
  const transactions = useSelector(selectTransactions);
  const locale = useLocale();
  const { serviceBookingLocalTimeUpdate: inLocalTimeLD } = useFlags();

  return (
    <>
      {renderEmptyState(isEmpty)}
      {!isEmpty && currentAvailableService?.availabilities?.length > 0 && (
        <ListWrapper>
          {currentAvailableService.availabilities.map(service => (
            <BookingTile
              {...getBookingTileProps(
                service,
                currentAvailableService,
                intl,
                timeZone,
                locale,
                onClick,
                onActionButtonClick,
                serviceProvider,
                building,
                transactions,
                inLocalTimeLD,
              )}
              key={`${service?.resource_uuid}-${service?.start_at}-${service?.end_at}`}
            />
          ))}
        </ListWrapper>
      )}
    </>
  );
};
