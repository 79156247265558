import React from 'react';
import { ListContainer, StyledTile } from './styles';
import { Service, ServiceType } from 'store/availableServices/types';
import { useServicePrice } from '../schedule.hooks';
import { EmptyServicesPlaceholder } from 'components/empty-services-placeholder';
import { StyledEmptyContainer } from 'components/schedule-list/styles';
import { useSelector } from 'react-redux';
import { selectBuilding } from 'store/building/selectors';
import { getCurrentCurrency } from 'utils/getCurrentCurrency';

interface AppointmentsListProps {
  items: ServiceType[] | Service[];
  selectItem: (index: number) => void;
  isServices: boolean;
}

const renderEmptyState = (isEmpty: boolean) =>
  isEmpty && (
    <StyledEmptyContainer data-testid="empty-services-container">
      <EmptyServicesPlaceholder />
    </StyledEmptyContainer>
  );

type AppointmentsItemProps = Service & ServiceType;

export const AppointmentsList = ({ items, selectItem, isServices }: AppointmentsListProps): JSX.Element => {
  const servicePrice = useServicePrice();
  const building = useSelector(selectBuilding);

  return (
    <>
      {renderEmptyState(!items?.length)}
      {!!items?.length && (
        <ListContainer>
          {items?.map((service: AppointmentsItemProps, index: number) => (
            <StyledTile
              key={service.id}
              title={service.name}
              onClick={selectItem}
              price={isServices ? servicePrice(service.price, getCurrentCurrency(service, building)) : ''}
              isSelected={false}
              index={index}
              className="appointment-item"
            />
          ))}
        </ListContainer>
      )}
    </>
  );
};
