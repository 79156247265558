import { createSelector } from 'reselect';
import { RootState } from 'store/reducer';
import { ServiceRegistrationState } from './types';

export const serviceRegistrationSelector = (state: RootState): ServiceRegistrationState => state.serviceRegistration;

export const selectRegistrationFields = createSelector(
  [serviceRegistrationSelector],
  serviceRegistration => serviceRegistration.registrationFields,
);

export const selectRegistrationFieldsLoadingStatus = createSelector(
  [serviceRegistrationSelector],
  serviceRegistration => serviceRegistration.getRegistrationFields.status,
);

export const selectRegistrationFieldsError = createSelector(
  [serviceRegistrationSelector],
  serviceRegistration => serviceRegistration.getRegistrationFields.error,
);

export const selectRegisterUserStatus = createSelector(
  [serviceRegistrationSelector],
  serviceRegistration => serviceRegistration.registerUser.status,
);

export const selectRegisterUserError = createSelector(
  [serviceRegistrationSelector],
  serviceRegistration => serviceRegistration.registerUser.error,
);
