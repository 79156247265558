import { useCallback, useEffect, useState } from 'react';
import { useBooleanState } from '@hqo/react-components-library';
import { searchParams, UseCheckoutReturnValues } from '../types';
import { formatCartItemsIds } from 'utils/formatTrackEventProps';
import {
  ACTION_STATUSES,
  CREDIT_PAYMENT_TYPE_ID,
  INVOICE_PAYMENT_TYPE_ID,
  TRACK_EVENT_TYPES,
  TRACK_EVENTS,
} from 'shared/consts';
import { deletePaymentMethod, getPaymentMethods } from 'store/payment/actions';
import { track } from '@hqo/web-tracking';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurrentPaymentMethodId,
  selectDeletePaymentMethodsStatus,
  selectGetPaymentMethodsStatus,
  selectSavePaymentMethodStatus,
} from 'store/payment/selectors';
import { useSearchParams } from 'hooks/use-search-params.hook';
import {
  select3DSecure,
  selectCartDiscountSummary,
  selectCartId,
  selectSubmitCartStatus,
  shouldSubmit3DSecure,
} from 'store/cart/selectors';
import { useCart } from 'hooks/use-cart.hook';
import { usePaymentMethods } from 'hooks/use-payment-methods.hook';
import { formatSummaryValues } from 'utils/formatSummaryValues';
import { resetComplete3DSCart, submitCart } from 'store/cart/actions';
import { getTransaction } from 'store/transactions/actions';
import qs from 'qs';
import { selectInitialRoute } from 'store/routes/selectors';
import { useLocation } from 'react-router-dom';

// eslint-disable-next-line max-lines-per-function
export const useCheckout = (): UseCheckoutReturnValues => {
  const { pathname } = useLocation();
  const [submittedPaymentMethodId, setSubmittedPaymentMethodId] = useState<number>(undefined);
  const paymentId = useSelector(selectCurrentPaymentMethodId);
  const { expandAddCard, error, cartId: currentCartId, ...queryParams } = useSearchParams<searchParams>();
  const cartId = useSelector(selectCartId) || currentCartId;
  const [paymentError, setPaymentError] = useState<boolean>(false);
  const { is3DSecure } = useSelector(select3DSecure);

  const dispatch = useDispatch();
  const { isCartFulfilled, cart } = useCart(cartId);
  const { paymentMethods } = usePaymentMethods(cartId);
  const { total, currencyType } = formatSummaryValues(cart?.total_summary);

  const getPaymentMethodStatus = useSelector(selectGetPaymentMethodsStatus);
  const savePaymentMethodStatus = useSelector(selectSavePaymentMethodStatus);
  const deletePaymentMethodsStatus = useSelector(selectDeletePaymentMethodsStatus);

  const discountSummary = useSelector(selectCartDiscountSummary);
  const submitCartStatus = useSelector(selectSubmitCartStatus);
  const initialRoute = useSelector(selectInitialRoute);
  const submit3DSecure = useSelector(shouldSubmit3DSecure);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<string>(null);
  const { value: showAddCardForm, toggle: toggleAddCardForm } = useBooleanState(!!expandAddCard);
  const [show3DS, setShow3DS] = useState<boolean>(false);
  const cartItemsIds = formatCartItemsIds(cart);

  const findCurrentPaymentMethod = useCallback(
    () => paymentMethods?.find(paymentMethod => paymentMethod.default),
    [getPaymentMethodStatus, savePaymentMethodStatus, paymentMethods],
  );

  useEffect(() => {
    if (isCartFulfilled) {
      track(
        TRACK_EVENTS.FULL_CHECKOUT_IMPRESSION,
        {
          type: TRACK_EVENT_TYPES.IMPRESSION,
          cart_total: total,
          currency_code: currencyType,
          promo_code_applied: !!discountSummary,
          cart_type: cart.type,
          items: cartItemsIds,
          adapter: cart.config?.config?.adapter,
        },
        { sendToHqoTracking: true },
      );
    }
  }, [isCartFulfilled]);

  useEffect(() => {
    if (paymentId) {
      setSubmittedPaymentMethodId(Number(paymentId));
    }
  }, [paymentId]);

  useEffect(() => {
    setDefaultPaymentMethod(findCurrentPaymentMethod()?.id.toString());
  }, [paymentMethods, findCurrentPaymentMethod]);

  useEffect(() => {
    if (error === 'true') {
      setPaymentError(true);
    }
  }, [error]);

  useEffect(() => {
    if (showAddCardForm && savePaymentMethodStatus === ACTION_STATUSES.FULFILLED) {
      toggleAddCardForm();
    }
  }, [savePaymentMethodStatus]);

  const onRemovePaymentMethod = useCallback(() => {
    dispatch(
      deletePaymentMethod.request({
        card_id: findCurrentPaymentMethod()?.id.toString(),
        paymentMethods,
      }),
    );
  }, [dispatch, findCurrentPaymentMethod, paymentMethods]);

  useEffect(() => {
    if (deletePaymentMethodsStatus === ACTION_STATUSES.FULFILLED) {
      dispatch(getPaymentMethods.request(cartId));
    }
  }, [dispatch, cartId, deletePaymentMethodsStatus]);

  const onAddPaymentMethodClick = useCallback(() => {
    toggleAddCardForm();
  }, [toggleAddCardForm]);

  const onCancelAddCardClick = useCallback(() => {
    toggleAddCardForm();
  }, [toggleAddCardForm]);

  const trackCheckoutClick = useCallback(() => {
    track(
      TRACK_EVENTS.CHECKOUT_CLICK,
      {
        type: TRACK_EVENT_TYPES.ACTION,
        payment_method: findCurrentPaymentMethod().payment_method_type,
        cart_total: total,
        currency_code: currencyType,
        promo_code_applied: !!discountSummary,
        cart_type: cart.type,
        items: cartItemsIds,
        adapter: cart.config?.config?.adapter,
      },
      { sendToHqoTracking: true },
    );
  }, [findCurrentPaymentMethod, total, currencyType, discountSummary, cart, cartItemsIds]);

  // eslint-disable-next-line max-lines-per-function
  const onPayClick = useCallback(() => {
    trackCheckoutClick();
    setPaymentError(false);
    setSubmittedPaymentMethodId(findCurrentPaymentMethod().id);
    dispatch(resetComplete3DSCart());

    const paymentInfo = {
      cart_id: cart?.id,
      payment_method_id: defaultPaymentMethod,
    };
    const searchString = qs.stringify({
      ...queryParams,
      cartId: cart?.id,
      paymentId: paymentInfo.payment_method_id,
      initialRoute,
      redirectRoute: pathname,
    });

    dispatch(
      submitCart.request(
        submit3DSecure &&
          defaultPaymentMethod !== CREDIT_PAYMENT_TYPE_ID &&
          defaultPaymentMethod !== INVOICE_PAYMENT_TYPE_ID
          ? {
              ...paymentInfo,
              browser_info: window.Spreedly?.ThreeDS?.serialize('01'),
              attempt_3dsecure: true,
              three_ds_version: '2',
              callback_url: `${window.location.origin}${initialRoute}/redirect?${searchString}`,
              redirect_url: `${window.location.origin}${initialRoute}/redirect?${searchString}`,
            }
          : paymentInfo,
      ),
    );
  }, [
    findCurrentPaymentMethod,
    defaultPaymentMethod,
    trackCheckoutClick,
    dispatch,
    cart,
    initialRoute,
    submit3DSecure,
    pathname,
  ]);

  useEffect(() => {
    if (submitCartStatus === ACTION_STATUSES.FULFILLED) {
      dispatch(getTransaction.request(cart.transaction_uuid));
    }
  }, [cart?.transaction_uuid, dispatch, submitCartStatus]);

  return {
    submittedPaymentMethodId,
    paymentError,
    defaultPaymentMethod,
    onRemovePaymentMethod,
    onAddPaymentMethodClick,
    onCancelAddCardClick,
    paymentMethods,
    isCartFulfilled,
    cart,
    cartId,
    showAddCardForm,
    onPayClick,
    show3DS,
    setShow3DS,
    is3DSecure,
    setPaymentError,
  };
};
