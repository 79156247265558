import { useState, useCallback } from 'react';
import html2canvas from 'html2canvas';
import { setScreenshotAction, resetScreenshotStatus } from 'store/cart/actions';
import { useDispatch } from 'react-redux';

interface UseScreenshot {
  screenshot: string;
  takeScreenshot: (node: HTMLElement) => Promise<void>;
}

export const useScreenshot = (): UseScreenshot => {
  const [screenshot, setScreenshot] = useState<string>(null);
  const dispatch = useDispatch();

  const takeScreenshot = useCallback(
    async (node: HTMLElement) => {
      try {
        if (!node) {
          throw new Error('You should provide correct html node.');
        }

        const canvas = await html2canvas(node);
        const base64Image = canvas.toDataURL();

        setScreenshot(base64Image);
      } catch (error) {
        dispatch(setScreenshotAction.failure(error));
        dispatch(resetScreenshotStatus());
      }
    },
    [setScreenshot, resetScreenshotStatus],
  );

  return { screenshot, takeScreenshot };
};
