import React from 'react';
import { PriceBlockContainer, Title, Price } from './styles';

interface Props {
  title: string;
  price: string;
  className?: string;
}

export const PriceBlock = ({ title, price, className }: Props): JSX.Element => {
  return (
    <PriceBlockContainer className={className}>
      <Title className="title">{title}</Title>
      <Price className="price">{price}</Price>
    </PriceBlockContainer>
  );
};
