import { ROOT_PATH } from 'shared/consts';

interface Props {
  path: string;
  companyUuid: string;
  serviceUuid?: string;
}

export const formatPath = ({ path, companyUuid, serviceUuid = '' }: Props): string => {
  return path.replace(':companyUuid', `${companyUuid}`).replace(':serviceUuid', `${serviceUuid}`);
};

export const formatInitialPath = (pathname: string): string => {
  const pathnameArray = pathname.split('/');
  const buildingIndex = pathnameArray.indexOf('building');
  const formattedPathname =
    buildingIndex > 0
      ? `/${pathnameArray.slice(buildingIndex + 2).join('/')}`
      : pathname.replace('/service-booking', '');

  return formattedPathname.includes('providers') ? formattedPathname : ROOT_PATH;
};
