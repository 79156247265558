import { AjaxError } from 'rxjs/ajax';
import { ERROR_DEFAULT } from 'shared/consts';

export function getErrorCode(error: Error): string {
  if (error instanceof AjaxError) {
    return error.response?.data?.code ?? error.response?.errorCode ?? ERROR_DEFAULT;
  }

  return ERROR_DEFAULT;
}
