import { DIMENSIONS, ModalWrapper } from '@hqo/react-components-library';

import styled from 'styled-components';

export const StyledModalWrapper = styled(ModalWrapper)`
  padding: 32px 0;
  width: 984px;

  @media screen and (max-width: ${DIMENSIONS.LARGE}) {
    width: 100%;
    height: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const BackLinkWrapper = styled.div`
  margin-top: 10px;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 210px;
  padding: 28px 75px;

  @media only screen and (max-height: ${DIMENSIONS.MEDIUM}) {
    padding: 0px 75px;
  }
`;

export const DescriptionContainer = styled.div`
  align-self: center;
  padding: 0px 18px;

  @media only screen and (max-width: ${DIMENSIONS.LARGE}) {
    align-self: unset;
  }
`;

export const ClientName = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  font: 500 18px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
  margin-bottom: 9px;

  @media only screen and (max-height: ${DIMENSIONS.MEDIUM}) {
    padding-top: 12px;
  }
`;

export const ContactUsButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  width: 100%;
  margin-top: 60px;
  margin-bottom: 28px;
  user-select: none;

  @media only screen and (max-height: ${DIMENSIONS.MEDIUM}) {
    margin-top: 56px;
  }
`;
