import React from 'react';
import { Container } from 'hocs/shared-styles';
import { Spinner } from '@hqo/react-components-library/dist/atoms/spinner';
import { defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';
import { useIFrame } from 'hooks/payment-hook/use-iFrame.hook';
import { PaymentIFrameProps } from 'components/paymentIFrame/paymentIFrame.interface';
import { ModalContainer } from 'components/paymentIFrame/styles';
import { SwipeModal } from '@hqo/react-components-library/dist/molecules/modals/swipe-modal';

export const SwipePaymentIFrame = ({ reverseAnimation, currentOrderType }: PaymentIFrameProps) => {
  const {
    isContentLoading,
    handleIframeLoading,
    handleGoBack,
    iFrameUrl,
    isCheckoutDesktop,
    isQuickCheckout,
    quickCheckout,
    isSwipeModalContent,
    handleClose,
  } = useIFrame(currentOrderType);

  const buildContent = (): JSX.Element => (
    <ModalContainer
      quickCheckout={quickCheckout}
      isCheckoutDesktop={isCheckoutDesktop}
      isQuickCheckout={isQuickCheckout}
      isSwipeModalContent={isSwipeModalContent}
    >
      {isContentLoading ? (
        <Container isLoading isIframeLoading>
          <Spinner color={defaultTheme.colors.$greyLight} size="10em" />
        </Container>
      ) : null}
      <iframe
        frameBorder="0"
        src={iFrameUrl}
        id="payment-iframe"
        title="Iframe SAML"
        height="100%"
        width={isContentLoading ? '0%' : '100%'}
        data-testid="iframe_testid"
        sandbox="allow-scripts allow-same-origin allow-presentation allow-forms allow-popups allow-popups-to-escape-sandbox allow-top-navigation"
        onLoad={handleIframeLoading}
      />
    </ModalContainer>
  );

  return (
    <SwipeModal
      data-testid="payment-swipe-modal"
      reverseAnimation={reverseAnimation}
      content={buildContent()}
      onPressBack={handleGoBack}
      onClose={handleClose}
      isScrollableContent
    />
  );
};
