import { ContactInformation, RegisterUserButton, UserInformation } from './components';
import React from 'react';
import { BookingErrorModal } from 'components/modals';
import { DoublePaneModal } from 'components/modals/doublePaneModal/doublePaneModal';
import { useIntl } from 'react-intl';
import { useRegisterUser } from 'pages/register-user/hooks/use-register-user.hook';

export const RegisterUserPage = () => {
  const intl = useIntl();
  const {
    onClick,
    showBookingErrorModal,
    onCloseError,
    isLoading,
    hasErrors,
    setRegistrationInfo,
    setTermAccepted,
    isTermAccepted,
    getRegistrationFieldsLoadingStatus,
    registrationFields,
  } = useRegisterUser();

  return (
    <>
      <BookingErrorModal isVisible={showBookingErrorModal} onClose={onCloseError} onButtonClick={onCloseError} />
      <DoublePaneModal
        contentStatus={getRegistrationFieldsLoadingStatus}
        Button={
          <RegisterUserButton
            text={intl.formatMessage({ id: 'continue' })}
            onClick={onClick}
            isDisabled={isLoading}
            isLoading={isLoading}
          />
        }
        LeftPaneContent={<ContactInformation />}
        RightPaneContent={
          <UserInformation
            registrationFields={
              registrationFields && registrationFields.required_fields.concat(registrationFields.other_fields)
            }
            setRegistrationInfo={setRegistrationInfo}
            hasWaiver={!!registrationFields?.waiver_url}
            handleAgreeTerms={setTermAccepted}
            isTermAccepted={isTermAccepted}
            hasErrors={hasErrors}
          />
        }
      />
    </>
  );
};
