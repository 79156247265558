import { AvailableServices } from 'store/availableServices/types';

export interface UpcomingBookingsProps {
  availableServices: AvailableServices;
}

export interface CarouselContentProps {
  orientation?: string;
  height?: number;
  width?: number;
}
export enum orientationEnum {
  vertical = 'vertical',
  horizontal = 'horizontal',
}
